export default {
  "auth": {
    "zendesk_sso": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie Ihre Zendesk-Subdomäne ein"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelden bei Zendesk"])}
    },
    "account_exists": {
      "company_has_account_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Unternehmen hat bereits ein Konto."])},
      "join_company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einem Unternehmen beitreten"])},
      "to_join_ask_for_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn Sie einem vorhandenen Unternehmen beitreten möchten, bitten Sie Ihren Administrator, Ihnen eine Einladung zu senden."])},
      "ask_for_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitten Sie den Administrator, Sie einzuladen"])},
      "cancel_flow_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich habe es mir anders überlegt."])},
      "cancel_signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrierung abbrechen"])},
      "company_has_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Unternehmen hat bereits ein Konto. Möchten Sie dem Konto Ihres Unternehmens beitreten oder ein neues Konto erstellen?"])},
      "copy_instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anweisungen kopieren"])},
      "create_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Konto erstellen"])},
      "existing_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es ist bereits ein Konto vorhanden"])}
    },
    "blocked": {
      "disabled_access_to_your_account_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können erst dann wieder auf Ihr Zendesk QA-Konto zugreifen, wenn Ihre ausstehenden Abonnementrechnungen bezahlt sind."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Abonnement wurde pausiert"])}
    },
    "errors": {
      "account_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto nicht gefunden. Versuchen Sie es erneut."])},
      "bad_credentials_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fordern Sie ein neues Kennwort an."])},
      "bad_credentials_try_again": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Versuchen Sie es erneut oder ", _interpolate(_named("link"))])},
      "bad_credentials_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falsche Kombination aus E-Mail-Adresse und Kennwort."])},
      "expired_invite_request_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fordern Sie von Ihrem Administrator eine neue an."])},
      "expired_invite_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Einladungslink ist ungültig oder wurde bereits verwendet."])},
      "disabled_auth_method": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Die Anmeldemethode ", _interpolate(_named("method")), " ist in Ihrem Konto deaktiviert."])},
      "expired_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Einladung ist ungültig oder wurde bereits verwendet. Fordern Sie von Ihrem Administrator eine neue an."])},
      "failed_to_load_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler beim Laden der Einladungsinformationen."])},
      "invalid_signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler bei der Registrierung. Ein Benutzer mit dieser E-Mail-Adresse ist möglicherweise bereits vorhanden."])},
      "max_attempts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Konto wurde nach mehreren aufeinanderfolgenden Anmeldeversuchen gesperrt. In Ihrem Posteingang finden Sie eine E-Mail mit Anweisungen zum Aufheben der Sperre."])},
      "unverified_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifizieren Sie zuerst Ihre E-Mail-Adresse"])}
    },
    "form": {
      "fields": {
        "email": {
          "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie ein gültiges E-Mail-Konto ein"])},
          "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieses Feld ist erforderlich"])}
        },
        "password": {
          "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieses Feld ist erforderlich"])}
        }
      },
      "send_magic_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelden"])},
      "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurücksetzen"])},
      "log_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelden"])},
      "login_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code für die Anmeldung"])},
      "send_magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magischen Link senden"])},
      "sign_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrieren"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E‑Mail"])},
      "work_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail geschäftlich"])},
      "your_work_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre geschäftliche E-Mail-Adresse"])}
    },
    "invite": {
      "main_service_agreement": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.zendesk.de/company/agreements-and-terms/main-services-agreement/"])}
      },
      "main_services_agreement": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hauptdienstleistungsvertrag"])}
      },
      "password": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kennwort entspricht nicht den Anforderungen"])}
      },
      "privacy_notice": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datenschutzerklärung"])},
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.zendesk.de/company/agreements-and-terms/privacy-notice/"])}
      },
      "hold_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warten Sie"])},
      "howdy": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hallo ", _interpolate(_named("invitee_name"))])},
      "join_organization": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Treten Sie der ", _interpolate(_named("organization")), "-Organisation bei"])}
    },
    "login": {
      "email_verified_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre E-Mail-Adresse wurde verifiziert. Sie können sich jetzt unten bei Ihrem neuen Konto anmelden."])},
      "google_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit Google fortfahren"])},
      "invalid_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültiger Einladungslink"])},
      "magic_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelden über magischen Link"])},
      "return_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück zur Anmeldung"])},
      "slack_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter mit Slack"])},
      "zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter mit Zendesk"])},
      "email_verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre E-Mail-Adresse wurde verifiziert. Sie können sich jetzt unten bei Ihrem neuen Konto anmelden:"])},
      "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelden mit Google"])},
      "magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelden über Magic Link"])},
      "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelden mit Slack"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelden bei Zendesk QA"])},
      "account_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Konto wurde gelöscht"])},
      "dont_have_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben noch kein Konto?"])}
    },
    "magic_link": {
      "code": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie einen gültigen Code ein"])},
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieses Feld ist erforderlich"])}
      },
      "email": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie ein gültiges E-Mail-Konto ein"])},
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieses Feld ist erforderlich"])}
      },
      "login_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code für die Anmeldung"])},
      "login_traditionally": {
        "link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Anmeldeoptionen anzeigen"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("link")), " über herkömmliche Methoden"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelden"])}
      },
      "title_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelden über magischen Link"])},
      "email_with_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben eine E-Mail mit Ihrem Code für die Anmeldung erhalten."])},
      "email_without_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben eine E-Mail erhalten, die Sie für die Anmeldung nutzen können."])},
      "sending_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler beim Senden des Magic Links. Versuchen Sie es erneut."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per E-Mail anmelden"])}
    },
    "password_field": {
      "create_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kennwort erstellen"])},
      "one_lower_case_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 Kleinbuchstabe"])},
      "one_upper_case_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 Großbuchstabe"])},
      "rules_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Kennwort muss enthalten"])},
      "max_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Kennwort darf maximal 128 Zeichen lang sein."])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leicht zu merken, aber schwer zu erraten"])},
      "8_characters_minimum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mindestens 8 Zeichen"])},
      "create_your_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kennwort erstellen"])},
      "forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kennwort vergessen?"])},
      "one_lower_case": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Kleinbuchstabe"])},
      "one_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine Ziffer"])},
      "one_upper_case": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Großbuchstabe"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kennwort"])}
    },
    "password_reset": {
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um Ihr Kennwort zurückzusetzen, geben Sie Ihre E-Mail-Adresse ein. Wir senden Ihnen dann eine E-Mail mit Anweisungen."])},
      "email": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie ein gültiges E-Mail-Konto ein"])},
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieses Feld ist erforderlich"])}
      },
      "reset_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kennwort zurücksetzen"])},
      "success": {
        "description_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Eine E-Mail wurde an ", _interpolate(_named("email")), " gesendet"])},
        "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn die E-Mail nicht in Ihrem Posteingang oder Spamordner erscheint, überprüfen Sie die eingegebene E-Mail-Adresse."])},
        "return_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück zur Anmeldung"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sehen Sie in Ihrem Posteingang nach"])}
      },
      "title_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kennwort zurücksetzen"])},
      "existing_password": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("bold")), ", erhalten Sie eine E-Mail zum Zurücksetzen des Kennworts."])},
        "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn Sie für Ihr Konto bereits ein Kennwort festgelegt haben"])}
      },
      "reset_link": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wenn die ", _interpolate(_named("bold")), ", dann haben wir Ihnen einen Link zum Zurücksetzen des Kennworts gesendet."])},
        "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail-Adresse ist mit einem Konto verknüpft"])}
      },
      "remembered_your_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haben Sie Ihr Kennwort vergessen?"])},
      "reset_link_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler beim Senden des Links zum Zurücksetzen. Versuchen Sie es erneut."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kennwort zurücksetzen"])}
    },
    "signup": {
      "magic_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrieren Sie sich über einen magischen Link"])},
      "agreement": {
        "privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datenschutzrichtlinie"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Mit der Registrierung akzeptieren Sie die ", _interpolate(_named("terms_of_service")), " und ", _interpolate(_named("privacy_policy")), " von Zendesk QA"])},
        "terms_of_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allgemeine Geschäftsbedingungen (AGB)"])}
      },
      "have_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haben Sie ein Konto?"])},
      "magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrieren Sie sich über Magic Link"])},
      "signup_with_work_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrieren Sie sich mit Ihrer geschäftlichen E-Mail-Adresse"])},
      "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrieren Sie sich mit Slack"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrieren Sie sich bei Zendesk QA"])},
      "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit Google anmelden"])},
      "zendesk_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich habe ein aktives Zendesk-Konto"])}
    },
    "complete_setup": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Registrieren Sie sich oder melden Sie sich an, um Ihre ", _interpolate(_named("direct_install_name")), "-Einrichtung abzuschließen"])},
    "confirm_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sind Sie es wirklich? Prüfen Sie, ob sich in Ihrem Posteingang eine Bestätigungs-E-Mail befindet."])},
    "continue": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie sind startklar"])},
      "works_best_on_desktop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA funktioniert am besten auf dem Desktop. Melden Sie sich dort an, um mit der Überprüfung von Konversationen zu beginnen."])},
      "book_demo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine Demo buchen"])},
      "or_book_demo_with_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können auch eine Demo bei uns buchen, damit wir Ihnen zeigen können, was Sie in Zendesk QA machen können."])}
    },
    "invite_existing": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Anwesenheit wird angefragt"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
      "cancel_flow_prompt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ich habe es mir anders überlegt und möchte ", _interpolate(_named("cancel"))])},
      "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("account")), " lädt Sie ein, seinem Konto beizutreten."])},
      "primary_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dem Konto beitreten"])},
      "secondary_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Konto erstellen"])}
    },
    "login_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versuchen Sie erneut, sich anzumelden"])}
  },
  "activity": {
    "reactions": {
      "emoji_alt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Die Reaktion, die Sie auf Ihr Feedback erhalten haben: ", _interpolate(_named("shortcode"))])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erhaltene Reaktionen"])},
      "agents_reacted_to": {
        "comment": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("agentName")), " hat auf Ihren Kommentar reagiert"]), _normalize(["Mehrere Personen haben auf Ihren Kommentar reagiert"])])},
        "reply": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("agentName")), " hat auf Ihre Antwort reagiert"]), _normalize(["Mehrere Personen haben auf Ihre Antwort reagiert"])])},
        "review": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("agentName")), " hat auf Ihre Bewertung reagiert"]), _normalize(["Mehrere Personen haben auf Ihre Bewertung reagiert"])])}
      },
      "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar"])},
      "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antwort"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überprüfung"])}
    },
    "subnav": {
      "disputes_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["STREITFÄLLE"])},
      "given_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ABGEGEBEN"])},
      "received_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ERHALTEN"])},
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle"])},
      "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentare"])},
      "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umfragefeedback"])},
      "reactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reaktionen"])},
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überprüfungen"])},
      "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestartet"])}
    },
    "mark_all_read": {
      "prompt_title": {
        "comments": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Kommentar als gelesen markieren?"]), _normalize(["Alle Kommentare als gelesen markieren?"])])},
        "disputes": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Streitfall als gelesen markieren?"]), _normalize(["Alle Streitfälle als gelesen markieren?"])])},
        "feedback": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Umfragefeedback als gelesen markieren?"]), _normalize(["Gesamtes Umfragefeedback als gelesen markieren?"])])},
        "reviews": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Bewertung als gelesen markieren?"]), _normalize(["Alle Bewertungen als gelesen markieren?"])])}
      },
      "success": {
        "comments": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Kommentar als gelesen markiert"]), _normalize(["Alle Kommentare als gelesen markiert"])])},
        "disputes": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Streitfall als gelesen markiert"]), _normalize(["Alle Streitfälle als gelesen markiert"])])},
        "feedback": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Umfragefeedback als gelesen markiert"]), _normalize(["Das gesamte Umfragefeedback als gelesen markiert"])])},
        "reviews": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Bewertung als gelesen markiert"]), _normalize(["Alle Bewertungen als gelesen markiert"])])}
      },
      "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle als gelesen markieren"])},
      "prompt_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als gelesen markieren"])},
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewertungen"])}
    },
    "empty_states": {
      "disputes_all": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn es wegen einer Überprüfung zu einem Streitfall kommt, wird dies hier angezeigt."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Streitfälle"])}
      },
      "disputes_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn Sie mit der Beilegung eines Streitfall beauftragt sind, wird dies hier angezeigt."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine erhaltenen Streitfälle"])}
      },
      "disputes_started": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn Sie wegen einer Überprüfung einen Streitfall eröffnet haben, wird dies hier angezeigt."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine gestarteten Streitfälle"])}
      },
      "feedback_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das gesamte Feedback, das Sie erhalten, wird hier angezeigt. Aktuell sind noch keine Inhalte vorhanden."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kein erhaltenes Feedback"])}
      },
      "reactions_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Reaktionen auf Ihre Überprüfungen und Antworten werden hier angezeigt. Aktuell sind noch keine Inhalte vorhanden."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine erhaltenen Reaktionen"])}
      },
      "reviews_given": {
        "open_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öffnen Sie eine Konversation"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das gesamte Feedback von Ihnen wird hier angezeigt. Um zu beginnen, hinterlassen Sie in der Konversationsansicht eine Überprüfung."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine abgegebenen Überprüfungen"])}
      },
      "reviews_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das gesamte Feedback, das Sie erhalten, wird hier angezeigt. Aktuell sind noch keine Inhalte vorhanden."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine erhaltenen Überprüfungen"])}
      },
      "comments_given": {
        "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Kommentare gefunden"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier wird das gesamte Feedback angezeigt, das Sie in Form von Kommentaren abgeben."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine verfassten Kommentare"])}
      },
      "comments_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Kommentare gefunden"])},
      "comments_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Kommentare von einer Ihrer Überprüfungen, ", "@", "Erwähnungen oder Antworten auf Ihre Kommentare werden hier angezeigt."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine erhaltenen Kommentare"])}
      },
      "disputes_filter": {
        "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine akzeptierten Streitfälle"])},
        "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine offenen Streitfälle"])},
        "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine abgelehnten Streitfälle"])},
        "resolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine beigelegten Streitfälle"])}
      },
      "filter_suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versuchen Sie es erneut, indem Sie im Filter oben eine andere Option auswählen."])},
      "user_suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versuchen Sie es erneut, indem Sie im Filter oben einen anderen Benutzer auswählen."])}
    },
    "comments": {
      "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verfasste Kommentare"])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erhaltene Kommentare"])}
    },
    "disputes": {
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Streitfälle"])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erhaltene Streitfälle"])},
      "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestartete Streitfälle"])},
      "type_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überprüfen"])},
      "type_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geprüfte Person"])}
    },
    "feedback": {
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erhaltenes Umfragefeedback"])}
    },
    "reviews": {
      "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abgegebene Überprüfungen"])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erhaltene Überprüfungen"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivität"])},
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivitätenliste umschalten"])}
  },
  "assignments": {
    "filters": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])}
    },
    "counts": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_list(0)), " / ", _interpolate(_list(1)), " überprüft"]), _normalize([_interpolate(_list(0)), " / ", _interpolate(_list(1)), " überprüft"]), _normalize([_interpolate(_list(0)), " / ", _interpolate(_list(1)), " überprüft"])])},
    "restarts_in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Neustart in ", _interpolate(_named("time"))])},
    "time_left": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Noch ", _interpolate(_named("time"))])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine Zuweisung einrichten"])},
    "ticket_list": {
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zuweisungen"])}
    },
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zuweisungsliste umschalten"])}
  },
  "coaching": {
    "sessions": {
      "landing_page": {
        "coachee_score_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])},
        "error": {
          "workspace_intersection": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Es ist nicht möglich, eine Coaching-Sitzung zwischen ", _interpolate(_list(0)), " und ", _interpolate(_list(1)), " zu erstellen, da sie sich momentan nicht im selben Arbeitsbereich befinden."])},
          "sessions_exist": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Coaching-Sitzungen zwischen ", _interpolate(_list(0)), " und ", _interpolate(_list(1)), " sind bereits vorhanden. Kontaktieren Sie ", _interpolate(_list(0)), ", um Zugang zu dieser Sitzung zu erhalten."])}
        },
        "suggested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorgeschlagen"])},
        "toast_created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Eine neue Sitzung zwischen ", _interpolate(_list(0)), " und ", _interpolate(_list(1)), " wurde erstellt."])},
        "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coach"])},
        "coach_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coach auswählen"])},
        "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gecoachte Person"])},
        "coachee_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gecoachte Person auswählen"])},
        "create_session_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sitzung"])},
        "create_session_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sitzung erstellen"])},
        "create_session_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellen Sie eine Sitzung"])},
        "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
        "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
        "new_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sitzung hinzufügen"])},
        "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
        "session_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
        "session_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sitzungsname"])},
        "suggested_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorgeschlagen"])}
      },
      "sessions_list": {
        "overdue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überfällig"])},
        "past": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ehemalig"])},
        "upcoming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anstehend"])},
        "create_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sitzung erstellen"])},
        "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Sitzungen gefunden"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sitzungen"])}
      },
      "sidebar": {
        "metrics": {
          "csat_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])},
          "duration_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])},
          "iqs_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])},
          "average_review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durchschnittliche Überprüfungszeit"])},
          "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorien"])},
          "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ändern"])},
          "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
          "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Daten für den ausgewählten Zeitraum zum Anzeigen verfügbar"])},
          "quizzes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz"])},
          "rating_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewertungskategorie"])}
        },
        "pins": {
          "add_to_session": {
            "success_with_cta": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Notiz zur Sitzung hinzugefügt. ", _interpolate(_named("buttonStart")), "Ich möchte es selbst sehen", _interpolate(_named("buttonEnd")), "."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinzufügen zu..."])},
            "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinzugefügt"])}
          },
          "end_of_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben das Ende der Notizen aus dem ausgewählten Zeitraum erreicht"])},
          "empty_state": {
            "hint": {
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sehen Sie sich in diesem ", _interpolate(_named("link")), " an, wie Notizen funktionieren."])},
              "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beitrag"])}
            },
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Sie haben unter ", _interpolate(_list(0)), " noch keine Notiz angeheftet. Sie können Notizen verwenden, um sich auf 1:1-Sitzungen vorzubereiten oder etwas für eine spätere Gelegenheit zu notieren."])},
            "coachee_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["die gecoachte Person"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noch keine Notizen hinzugefügt"])}
          },
          "info": {
            "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hier sind alle Notizen, die Sie unter ", _interpolate(_named("name")), " erstellt haben. Zu Sitzungen hinzugefügte Notizen werden mit dem anderen Teilnehmer ", _interpolate(_named("bold")), "."])},
            "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sofort geteilt"])}
          }
        },
        "feedback": {
          "empty_state": {
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Offenbar hat ", _interpolate(_list(0)), " im ausgewählten Zeitraum kein Feedback zur Konversationsüberprüfung oder zur Kundenzufriedenheit erhalten."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kein Feedback verfügbar"])}
          },
          "end_of_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben das Ende der Kommentare aus dem ausgewählten Zeitraum erreicht"])}
        },
        "tabs": {
          "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback"])},
          "metrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metriken"])},
          "pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notizen"])}
        },
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Nummern von ", _interpolate(_list(0))])}
      },
      "thread": {
        "new_action_item": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Aktionselement"])}
        },
        "actions": {
          "completed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Abgeschlossen von ", _interpolate(_named("user")), " am ", _interpolate(_named("date"))])},
          "created_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["von ", _interpolate(_list(0))])},
          "current_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihnen"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text eingeben, um eine neue Maßnahme hinzuzufügen"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Was ist bei diesem Meeting herausgekommen? Was sind Ihre nächsten Schritte?"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maßnahmen"])}
        },
        "created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Erstellt von ", _interpolate(_named("name")), " am ", _interpolate(_named("createdDate"))])},
        "delete_body": {
          "delete_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sitzung löschen"])},
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dadurch können Sie dauerhaft die ", _interpolate(_named("deleteSession")), ". Die Teilnehmer verlieren den Zugriff auf die Sitzung, einschließlich der Inhalte und privaten Notizen."])}
        },
        "session_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sitzung erstellt am ", _interpolate(_named("date"))])},
        "updated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Zuletzt bearbeitet von ", _interpolate(_named("name")), " am ", _interpolate(_named("updatedDate"))])},
        "notes": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesprächsthemen und Notizen"])},
          "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notiz aktualisiert"])}
        },
        "share": {
          "leave_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sitzung verlassen"])},
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teilen"])},
          "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ab jetzt können die ausgewählten Benutzer auf Sitzungsdetails zugreifen."])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer auswählen"])},
          "shared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geteilt"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sitzung teilen"])}
        },
        "toast": {
          "leave_session": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Sie sind nicht länger Teil der ", _interpolate(_list(0)), " – ", _interpolate(_list(1)), "-Sitzung."])},
          "participant_removed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " wird nicht mehr an dieser Sitzung teilnehmen."])},
          "participants_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("name")), " hat jetzt Zugriff auf diese Sitzung."]), _normalize([_interpolate(_named("n")), " neue Benutzer haben jetzt Zugriff auf diese Sitzung"])])}
        },
        "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sitzung löschen"])},
        "delete_thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Sitzungen löschen"])},
        "delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sitzung löschen"])},
        "note_author_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["der Coach"])},
        "note_placeholder": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Von ", _interpolate(_list(0)), " hinzugefügte Notizen werden hier angezeigt."])},
        "pins": {
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fügen Sie angeheftete Elemente über das rechte Seitenfenster als Diskussionspunkte hinzu"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notizen"])}
        },
        "private_notes": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notizen, die nur Sie sehen können ..."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persönliche Notizen"])},
          "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Private Notiz aktualisiert"])}
        },
        "stats": {
          "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30 Tage"])}
        }
      },
      "insights": {
        "overview_card": {
          "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fertig"])},
          "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coach"])},
          "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gecoachte Person"])},
          "action_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maßnahmen"])},
          "last_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letzte Sitzung"])},
          "statuses": {
            "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fertig"])},
            "ongoing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laufend"])},
            "overdue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überfällig"])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sitzungsübersicht"])},
          "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamt"])},
          "view_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle anzeigen"])}
        },
        "action_items": {
          "filters": {
            "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich: Coach"])},
            "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich: Gecoachte Person"])},
            "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Sitzungen"])},
            "shared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geteilte Sitzungen"])}
          },
          "empty": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versuchen Sie, den Filter zu wechseln, um Maßnahmen zu sehen."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Maßnahmen"])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maßnahmen"])}
        },
        "progress_card": {
          "legend": {
            "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
            "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erhaltene und gelesene Überprüfungen"])},
            "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])}
          },
          "tooltip": {
            "iqs_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
            "reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erhalten · Gelesen"])},
            "csat_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fortschritt nach Sitzungen"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sitzungen"])}
      },
      "leaderboard": {
        "empty_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Agenten scheinen kein Feedback erhalten zu haben."])},
        "empty_search_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prüfen Sie, ob Sie den richtigen Benutzernamen haben"])},
        "empty_search_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Benutzer konnte nicht gefunden werden"])},
        "empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Daten verfügbar"])},
        "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS der letzten 30 Tage"])}
      },
      "list": {
        "delete_all_prompt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dadurch werden <b>alle Sitzungen dauerhaft gelöscht</b> (2), die <b>", _interpolate(_named("name")), "</b> enthalten. Die Teilnehmer verlieren den Zugriff auf alle Sitzungen, einschließlich deren Inhalte und privaten Notizen."])},
        "end_of_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben das Ende aller Sitzungen erreicht"])},
        "empty": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine Coaching-Sitzung ist eine Gruppenaktivität, bei der ein Mentor und eine gecoachte Person über Leistung, Geschäftschancen sowie kurz- und langfristige Ziele sprechen."])},
          "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können sowohl über den Namen des Coachs als auch der gecoachten Person nach Sitzungen suchen."])},
          "search_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Sitzungen gefunden"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noch keine Sitzungen erstellt"])}
        },
        "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coaching-Verlauf löschen"])},
        "delete_thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coaching-Verlauf löschen "])},
        "heading": {
          "last_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letzte Sitzung"])},
          "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sitzung"])},
          "sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sitzungen"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Sitzungen"])}
      },
      "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coach"])},
      "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gecoachte Person"])},
      "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fertig"])},
      "mark_as_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als abgeschlossen markieren"])},
      "mark_as_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als nicht abgeschlossen markieren"])},
      "new_thread": {
        "user_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nach Namen suchen"])}
      },
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entfernen"])},
      "rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umbenennen"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coaching"])},
      "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sitzungsliste umschalten"])},
      "unscheduled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungeplant"])}
    },
    "sidebar": {
      "reply_placeholder_thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auf diese Nachricht antworten"])}
    },
    "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coaching"])}
  },
  "conversations": {
    "calibration_sidebar": {
      "review_disabled_expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abgelaufene Kalibrierungssitzung. Es können keine weiteren Überprüfungen hinterlassen werden."])},
      "review_disabled_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben bereits eine Bewertung zu dieser Konversation hinterlassen."])},
      "empty_state": {
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Konversation kann nicht mehr überprüft werden, da das Fälligkeitsdatum der Sitzung überschritten wurde."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Für diese Konversation gibt es keine Überprüfungen"])}
      },
      "close_sidebar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fenster schließen"])},
      "feedback_form_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine Überprüfung der Kalibrierung hinterlassen"])}
    },
    "call_transcript": {
      "details_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DETAILS"])},
      "summary_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ZUSAMMENFASSUNG"])},
      "transcript_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PROTOKOLL"])},
      "speaker_alias": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Sprecher ", _interpolate(_list(0))])},
      "agent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent"])},
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunde"])},
      "dead_air": {
        "seconds": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " Sekunden"])},
        "speaker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesprächspause"])}
      },
      "edit_speaker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprecher bearbeiten"])},
      "message_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachricht überprüfen"])},
      "return_to_playing_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück zur abgespielten Nachricht"])},
      "speakers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprecher"])},
      "transcript": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Protokoll"])},
      "ws_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeitsbereich-Benutzer"])}
    },
    "filters": {
      "add_connection_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fügen Sie zunächst eine Verbindung hinzu, um Filter zu erstellen."])},
      "auto_qa": {
        "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])}
      },
      "form": {
        "options": {
          "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bots"])},
          "bots_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Bots"])},
          "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppen"])},
          "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer"])}
        },
        "filter_created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Erstellt um ", _interpolate(_named("time")), " von ", _interpolate(_named("agentName"))])},
        "filter_updated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Zuletzt aktualisiert am ", _interpolate(_named("time")), " von ", _interpolate(_named("agentName"))])},
        "no_filters_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sie haben scheinbar keine Filter. Um auf diese Seite zuzugreifen, müssen Sie zuerst einen Filter erstellen. Beginnen Sie mit ", _interpolate(_named("recommendedFilter")), ", um Ihre eigenen Konversationen zu sehen. Erstellen Sie diesen Filter unten, um fortzufahren."])},
        "over_last_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["in den letzten 30 Tagen"])},
        "timeframe_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Bedingung „In den letzten 30 Tagen erstellt“ wird zu Filtern hinzugefügt, die ohne Datumsbereich erstellt wurden."])},
        "downgraded_filter_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In diesem Plan ist der Filter nicht verfügbar. Er wird bei der Ergebnisanzeige nicht berücksichtigt"])},
        "loading_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeitüberschreitung beim Abrufen der Filteroptionen. Versuchen Sie es erneut."])},
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Infos zum Filtern"])},
        "recommended_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bearbeiter = Angemeldete Person"])},
        "ticket_count_label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Gefundene Konversationen: ", _interpolate(_named("count"))]), _normalize(["Gefundene Konversation: ", _interpolate(_named("count"))]), _normalize(["Gefundene Konversationen: ", _interpolate(_named("count"))])])},
        "ticket_count_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])},
        "value_outdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Ihrem aktuellen Plan nicht verfügbar. Führen Sie ein Upgrade durch, um diese Funktion zu verwenden."])},
        "add_condition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedingung hinzufügen"])},
        "ai_fields_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Von Zendesk QA-KI "])},
        "condition_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedingung"])},
        "convert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Zuweisung umwandeln"])},
        "create_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter erstellen"])},
        "create_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter erstellen"])},
        "custom_fields_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angepasste Felder"])},
        "filters_field_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedingungen"])},
        "loading_options_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir laden die Filteroptionen entsprechend Ihrer Konfiguration. Dies kann beim erstmaligen Laden einige Minuten dauern."])},
        "name_field_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie dem Filter einen aussagekräftigen Namen"])},
        "name_field_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtername"])},
        "option_picker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie eine Option aus"])},
        "option_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedingung"])},
        "private_option_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privat"])},
        "public_option_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öffentlich"])},
        "update_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter bearbeiten"])},
        "update_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Änderungen speichern"])},
        "value_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Werte verfügbar"])},
        "value_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wird geladen..."])},
        "value_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht gefunden"])},
        "value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wert auswählen"])},
        "visibility_toggle_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sichtbarkeit"])}
      },
      "calibrations": {
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nur aktive Sitzungen ↑"])},
        "older": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ältere Sitzungen anzeigen ↓"])},
        "create_modal": {
          "date_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fälligkeitsdatum"])},
          "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrierungssitzung bearbeiten"])},
          "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
          "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sitzung erstellen"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrierungssitzung erstellen"])}
        },
        "delete_modal": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle bei der Sitzung abgegebenen Überprüfungen der Kalibrierung werden endgültig gelöscht."])},
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sitzung löschen"])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("sessionName")), " Kalibrierungssitzung löschen"])}
        },
        "empty_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es wurden keine Sitzungen erstellt"])},
        "no_active_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine aktiven Sitzungen"])},
        "no_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Sitzungen erstellt"])},
        "no_visible_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine aktiven Sitzungen"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrierung"])}
      },
      "add_filter_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter hinzufügen"])},
      "add_inline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinzufügen"])},
      "add_private_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellen Sie einen privaten Filter"])},
      "add_public_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellen Sie einen öffentlichen Filter"])},
      "add_success_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter hinzugefügt"])},
      "clone_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplizieren"])},
      "delete_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])},
      "delete_modal": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Filter wird endgültig gelöscht."])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter löschen"])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("filterName")), "-Filter löschen"])}
      },
      "disputes": {
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Streitfälle"])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Streitfälle für mich"])},
        "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Streitfälle von mir"])},
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Streitfälle"])},
        "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offene Streitfälle"])}
      },
      "drafts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entwürfe"])},
      "edit_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bearbeiten"])},
      "empty_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Filter hinzugefügt"])},
      "empty_private_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine privaten Filter erstellt"])},
      "empty_public_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine öffentlichen Filter erstellt"])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])},
      "private_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Private Filter"])},
      "public_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öffentliche Filter"])},
      "temporary_filter_message": {
        "action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Möchten Sie ", _interpolate(_named("cta")), "?"])},
        "action_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dies speichern"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dies ist eine temporäre Filteransicht."])},
        "loading_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter wird erstellt ..."])}
      },
      "update_success_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter aktualisiert"])},
      "visual_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insights"])}
    },
    "sidebar": {
      "auto_qa": {
        "custom_category_popup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angepasste AutoQA-Kategorie"])},
        "feedback_updated_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Zuletzt aktualisiert am ", _interpolate(_named("updatedDate"))])},
        "complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komplexe Wörter"])},
        "feedback_updated_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatische Überprüfungen werden bei Ticketänderungen dynamisch aktualisiert"])},
        "highlight_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Konversation hervorheben"])},
        "long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lange Sätze"])},
        "na_popup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kein automatischer Wert vorhergesagt"])},
        "no_mistakes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es konnten keine Fehler erkannt werden"])},
        "show_complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komplexe Wörter anzeigen"])},
        "show_long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lange Sätze anzeigen"])}
      },
      "next_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nächste Konversation"])},
      "reviewee_dropdown_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bots"])},
      "spotlight": {
        "undetermined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA konnte diese Kategorie aufgrund des unzureichenden Kontexts nicht bewerten. Es wird eine manuelle Überprüfung empfohlen."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spotlight"])},
        "tooltip_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entdecken Sie automatische Konversations-Insights, mit denen Sie den Überprüfungsprozess optimieren können. Heben Sie wichtige Konversationen und herausragende positive Beispiele für Ihr Team hervor."])}
      },
      "call_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[Anruf]"])},
      "corrected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korrigiert"])},
      "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gelöscht"])},
      "disputes": {
        "approved_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentName")), " hat den Streitfall angenommen"])},
        "assigned_to": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Streitfall gesendet an ", _interpolate(_named("agentName"))])},
        "comment_placeholder": {
          "dispute_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erklären Sie, warum diese geprüfte Person Ihrer Meinung nach der falsche Empfänger für dieses Feedback ist."])},
          "approve_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar hinzufügen und eine neue geprüfte Person festlegen"])},
          "approve_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar hinzufügen"])},
          "dispute_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erklären Sie, warum diese Bewertung Ihrer Meinung nach ungerecht erfolgt ist (erforderlich)"])},
          "edit_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fügen Sie einen Kommentar hinzu (erforderlich)"])},
          "reject_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erklären Sie, warum Sie diesen Streitfall ablehnen"])},
          "reject_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar hinzufügen"])}
        },
        "labels": {
          "dispute_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Streitfall senden an"])},
          "dispute_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Streitfall senden an"])},
          "edit_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Streitfall senden an"])},
          "approve_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue geprüfte Person auswählen"])},
          "approve_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback zum Streitfall"])},
          "reject_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback zum Streitfall"])},
          "reject_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geprüfte Person ändern"])}
        },
        "modal": {
          "user_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Streitfall senden an"])},
          "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Streitfall"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Überprüfung anfechten"])},
          "type_ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich bin mit den Werten nicht einverstanden"])},
          "type_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Überprüfung war nicht für mich gedacht"])},
          "user_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie einen Benutzer aus"])}
        },
        "partially_accepted_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentName")), " hat den Streitfall teilweise angenommen"])},
        "rejected_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentName")), " hat den Streitfall abgelehnt"])},
        "status": {
          "approved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angenommen"])},
          "partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teilweise angenommen"])},
          "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abgelehnt"])},
          "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offen"])}
        },
        "started_review_dispute": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("userName")), " hat einen Streitfall eröffnet: Überprüfung"])},
        "started_reviewee_disputed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("userName")), " hat einen Streitfall eröffnet: falsche geprüfte Person"])},
        "actions_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein offener Streitfall sollte beigelegt werden, damit Sie andere Aktionen für die Überprüfung durchführen können."])},
        "approve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annehmen"])},
        "approve_scores_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Werte auswählen"])},
        "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Streitfall löschen"])},
        "delete_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie diesen Streitfall wirklich verwerfen?"])},
        "delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Streitfall löschen"])},
        "dispute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Streitfall"])},
        "dispute_resolution_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beilegungszeit für den Streitfall"])},
        "reject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ablehnen"])},
        "review_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie neue gerechte Werte aus (optional)"])},
        "review_trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überprüfung anfechten"])},
        "reviewee_trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falsche geprüfte Person"])},
        "titles": {
          "approve_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Streitfall akzeptieren"])},
          "approve_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Streitfall akzeptieren"])},
          "dispute_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback zum Streitfall"])},
          "dispute_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falsche geprüfte Person"])},
          "edit_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback zum Streitfall"])},
          "reject_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Streitfall ablehnen"])},
          "reject_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Streitfall ablehnen"])}
        },
        "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Streitfall anzeigen"])}
      },
      "edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bearbeitet"])},
      "review_modified_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("action")), " von ", _interpolate(_named("author")), " ", _interpolate(_named("date"))])},
      "review_recipient": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["an ", _interpolate(_list(0))])},
      "reviewee_dropdown_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überprüft"])},
      "action_finish_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fertig"])},
      "assigned_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zugewiesen an:"])},
      "comment_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinterlassen Sie auch einen Kommentar"])},
      "conditional_scorecard_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Infos zu bedingten Scorecards"])},
      "formerly_assigned_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vormals zugewiesen zu:"])},
      "gif_loader_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einfach auswählen"])},
      "gif_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine GIFs mit diesem Namen gefunden"])},
      "no_feedback_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie als Erste/r eine Überprüfung ab."])},
      "paused_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Abonnement ist momentan angehalten. Aktivieren Sie sie erneut, damit Sie Feedback geben können."])},
      "posted_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gepostet von:"])},
      "review_time_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeigt an, wie viel Zeit für die Überprüfung dieser Konversation aufgewendet wurde"])},
      "reviewee_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie einen Benutzer aus"])},
      "root_cause_missing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Fehlende Ursache für Kategorie: „", _interpolate(_list(0)), "“"])},
      "scorecard_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard:"])},
      "tooltips": {
        "no_ratings_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine Kategorie bewerten oder einen Kommentar hinzufügen"])},
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinzufügen ..."])},
        "add_emoji": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emoji hinzufügen"])},
        "collapse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reduzieren"])},
        "comment_maximize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentarfeld erweitern"])},
        "comment_minimize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentarfeld reduzieren"])},
        "hide_formatting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formatierung ausblenden"])},
        "hide_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fenster ausblenden"])},
        "show_formatting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formatierung anzeigen"])},
        "show_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bereich anzeigen"])},
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überprüfung einreichen"])}
      },
      "unrated_categories_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Kategorien bewerten"])},
      "unrated_category_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mindestens eine Kategorie bewerten"])},
      "action_highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Konversation für später speichern"])},
      "action_highlight_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Für später speichern"])},
      "action_next_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nächste Zuweisung"])},
      "action_skip_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversation in dieser Zuweisungsperiode ersetzen"])},
      "back_to_ticket_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversation überprüfen"])},
      "baseline_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baseline-Überprüfung"])},
      "comment_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])},
      "comment_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar löschen"])},
      "comment_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar gelöscht"])},
      "comment_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bearbeiten"])},
      "comment_edit_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diesen Kommentar löschen?"])},
      "comment_list": {
        "comment_author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autor des Kommentars"])},
        "hashtag_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Hashtag wurde gelöscht oder umbenannt."])}
      },
      "comment_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teilen Sie mit, was Ihnen gefallen hat oder was hätte besser sein können ..."])},
      "comment_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar"])},
      "conditional_scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konditional"])},
      "confirm_comment_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dadurch werden alle Kommentare endgültig gelöscht."])},
      "confirm_comment_delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar löschen"])},
      "empty_message_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[Message]"])},
      "feedback_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewertungen"])},
      "feedback_not_seen": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " hat dieses Feedback noch nicht gesehen"])},
      "feedback_seen": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " hat dieses Feedback gesehen"])},
      "former_member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ehemaliges Mitglied"])},
      "gif_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GIFs durchsuchen ..."])},
      "hashtag_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drücken Sie die Eingabetaste, um einen neuen Hashtag zu erstellen."])},
      "hashtag_locked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angepasste Hashtags werden vom Administrator gesperrt."])},
      "highlight_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiermit können Sie Fälle als Lesezeichen hinzufügen und später herausfiltern"])},
      "mark_as_baseline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als Baseline markieren"])},
      "message_feedback_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback zur Nachricht"])},
      "next_ticket_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nächste Konversation"])},
      "no_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kein Feedback"])},
      "no_feedback_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noch keine Überprüfungen abgeschlossen"])},
      "no_feedback_subtitle_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinterlassen Sie unten eine Überprüfung, um zu beginnen."])},
      "no_other_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine weiteren Überprüfungen hinterlassen"])},
      "not_rated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht bewertet"])},
      "previous_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorheriger Mitarbeiter"])},
      "previous_ticket_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorherige Konversation"])},
      "remove_as_baseline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als Baseline entfernen"])},
      "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antworten"])},
      "reply_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auf diese Überprüfung antworten"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überprüfen"])},
      "review_cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
      "review_collapse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seitenleiste für Überprüfungen ausblenden"])},
      "review_delete_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dadurch werden die Überprüfung und alle hinzugefügten Kommentare endgültig gelöscht."])},
      "review_delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überprüfung löschen"])},
      "review_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überprüfung löschen"])},
      "review_delete_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überprüfung gelöscht"])},
      "review_deleted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Überprüfung von ", _interpolate(_list(0)), " gelöscht"])},
      "review_disabled_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fertig."])},
      "review_disabled_reassign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weisen Sie sich diese Aufgabe selbst zu, um eine Überprüfung zu hinterlassen"])},
      "review_disabled_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben bereits eine Überprüfung für diesen Benutzer hinterlassen."])},
      "review_disabled_reviewee": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Sie haben eine Überprüfung für ", _interpolate(_list(0)), " hinterlassen"])},
      "review_disabled_scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Für diese Konversation können keine Kategorien angezeigt werden."])},
      "review_disabled_self_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selbstüberprüfungen sind für diesen Arbeitsbereich nicht verfügbar."])},
      "review_draft_dialog": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
        "deny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht speichern"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Überprüfung als Entwurf speichern?"])}
      },
      "review_draft_discard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diesen Entwurf verwerfen"])},
      "review_draft_discard_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])},
      "review_expand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seitenleiste für Überprüfungen anzeigen"])},
      "review_status": {
        "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht bestanden"])},
        "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonstiges"])},
        "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestanden"])},
        "pass_with_minors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit geringen Fehlern bestanden"])}
      },
      "review_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senden"])},
      "review_time_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berechnung läuft noch ..."])},
      "review_title_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar hinterlassen"])},
      "review_title_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Nachricht überprüfen"])},
      "review_title_self": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überprüfen Sie sich selbst"])},
      "review_title_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Konversation überprüfen"])},
      "reviewee_dropdown_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitarbeiter"])},
      "reviewee_dropdown_author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autor"])},
      "reviewee_dropdown_depleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Benutzer zum Überprüfen vorhanden"])},
      "reviewee_dropdown_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selbstüberprüfungen deaktiviert"])},
      "reviewee_dropdown_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kein Benutzer gefunden"])},
      "reviewee_dropdown_participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teilnehmer"])},
      "reviewee_dropdown_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeitsbereich-Benutzer"])},
      "reviewee_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wird geladen..."])},
      "reviewee_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer auswählen"])},
      "reviews_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 Überprüfung"]), _normalize([_interpolate(_named("count")), " Überprüfungen"])])},
      "select_new_ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Bewertungen auswählen"])},
      "self_reviews_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Arbeitsbereichsmanager hat Selbstüberprüfungen nicht zugelassen."])},
      "tag_this_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drücken Sie die Eingabetaste, um dieser Notiz ein Stichwort hinzuzufügen"])},
      "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamt"])}
    },
    "sub_nav": {
      "calibrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrierungen"])},
      "drafts_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letzte 30 Tage"])},
      "starred": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Markiert"])},
      "add_calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine Kalibrierungssitzung erstellen"])},
      "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrierung"])},
      "drafts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entwürfe"])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversationen"])},
      "visual_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insights"])},
      "visual_filters_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insights bieten eine visuelle Darstellung Ihrer Help Desk-Daten. Anhand der interaktiven Diagramme können Sie erkennen, worauf Sie Ihre QS-Bemühungen konzentrieren sollten. Außerdem können Sie blinde Flecken ermitteln und die richtigen Mitarbeiter finden, die Sie anhand ihrer Leistung überprüfen sollten."])}
    },
    "ticket": {
      "ID": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("ticketSource")), " ID"])},
      "URL": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("ticketSource")), " URL"])},
      "translate_to_language": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Zielsprache ", _interpolate(_named("language"))])},
      "calibration_add_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinzufügen zu"])},
      "unassigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht zugewiesen"])},
      "user_activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuell angezeigt"])},
      "user_reviewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wird überprüft"])},
      "deleted_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Konversation wurde entfernt und ist nicht mehr verfügbar."])},
      "not_found_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die gesuchte Konversation ist in Zendesk QA nicht vorhanden."])},
      "all_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Notizen zu dieser Konversation"])},
      "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitarbeiter"])},
      "calibration_in_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In der Sitzung"])},
      "calibration_modal": {
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversation entfernen"])},
        "remove_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn Sie eine Konversation aus einer Sitzung entfernen, werden alle zugehörigen Überprüfungen gelöscht."])},
        "remove_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Konversation aus ", _interpolate(_named("sessionName")), " entfernen"])}
      },
      "calibration_no_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noch keine Kalibrierungssitzungen erstellt."])},
      "calibration_toast_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Konversation wurde zur Sitzung hinzugefügt"])},
      "calibration_toast_removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Konversation wurde aus der Sitzung entfernt"])},
      "calibration_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zu einer Kalibrierungssitzung hinzufügen/aus einer Kalibrierungssitzung entfernen"])},
      "copy_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link zu dieser Konversation kopieren"])},
      "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Konversation zum Anzeigen vorhanden"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibung"])},
      "details_brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marke"])},
      "details_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanal"])},
      "details_client_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail-Adresse des Benutzers."])},
      "details_client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID des Kunden"])},
      "details_client_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name des Benutzers."])},
      "details_form_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Externer Formularname"])},
      "details_frt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeit bis zur ersten Antwort"])},
      "details_frt_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeit bis zur ersten Antwort"])},
      "details_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppe"])},
      "details_priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Priorität"])},
      "details_satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umfragekommentar"])},
      "details_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zufriedenheitswert"])},
      "details_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "details_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stichwörter"])},
      "details_ttfr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TTFR"])},
      "details_ttfr_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeit bis zur ersten Lösung"])},
      "details_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ"])},
      "external_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Original"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wird geladen..."])},
      "message_404_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Nachricht ist in dieser Konversation nicht vorhanden oder wurde gelöscht"])},
      "no_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kein Betreff"])},
      "no_ticket_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Konversation zum Anzeigen vorhanden"])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Konversation gefunden"])},
      "original_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ursprüngliche Konversation öffnen"])},
      "original_conversation_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link zur Konversation nicht möglich – URL fehlt"])},
      "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teilnehmer"])},
      "participants_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Teilnehmer"])},
      "pin_it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversation anheften"])},
      "read_review_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klicken, um Überprüfungen zu lesen"])},
      "review_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zum Überprüfen klicken"])},
      "reviewed_via_extension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Über Browsererweiterung erstellte Konversation"])},
      "satisfaction_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundenzufriedenheitsumfrage anzeigen"])},
      "see_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversation anzeigen"])},
      "sentiment_negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachricht mit negativer Stimmung"])},
      "sentiment_positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachricht mit positiver Stimmung"])},
      "translate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ins Englische übersetzen"])},
      "translate_undo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Original anzeigen"])}
    },
    "ticket_deletion": {
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversation löschen"])},
      "confirmation_modal_body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das Löschen einer Konversation ist dauerhaft. Außerdem werden alle zugehörigen Daten wie Umfragen, Transkriptionen und Überprüfungen aus Zendesk QA, einschließlich Dashboards, gelöscht.", _interpolate(_named("lineBreak")), "Sie können weiterhin in Zendesk Support oder in einer anderen Help Desk-Software, die Sie nutzen, auf diese Konversation zugreifen."])},
      "confirmation_modal_body_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das Löschen einer Konversation ist dauerhaft. Außerdem werden alle zugehörigen Daten wie Umfragen, Transkripte und Überprüfungen aus Zendesk QA gelöscht, einschließlich Dashboards. Wenn die Konversation mehrere Arbeitsbereiche betrifft, wird sie aus allen gelöscht.", _interpolate(_named("lineBreak")), "Sie können in Zendesk Support oder in einer anderen Help-Desk -Software, die Sie nutzen, weiterhin auf diese Konversation zugreifen."])},
      "confirmation_modal_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversation löschen"])},
      "confirmation_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversation aus Zendesk QA löschen"])}
    },
    "ticket_event": {
      "incomplete": {
        "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Diese Konversation ist aufgrund einer Eskalation unvollständig. Gehen Sie zu ", _interpolate(_named("optionsUrl")), " und validieren Sie die Stichwörter. ", _interpolate(_named("tagsUrl"))])},
        "description_no_edit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Diese Konversation ist aufgrund einer Eskalation unvollständig. Sprechen Sie mit Ihrem Administrator über die Validierung Ihrer Stichwörter. ", _interpolate(_named("tagsUrl"))])},
        "options_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbindungen > Erweiterte Optionen"])},
        "tags_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Infos zu Stichwörtern"])}
      },
      "statuses": {
        "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschlossen"])},
        "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neu"])},
        "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offen"])},
        "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wartend"])},
        "solved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gelöst"])}
      },
      "assignee_change": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Mitarbeiter geändert zu ", _interpolate(_named("target"))])},
      "assignee_removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht zugewiesen"])},
      "macro_used": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("target")), "-Makro ausgeführt von ", _interpolate(_named("author"))])},
      "rating_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Konversation hat eine Bewertung erhalten: ", _interpolate(_named("target"))])},
      "redaction": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Benutzer ", _interpolate(_named("target")), " hat die Konversation geschwärzt"])},
      "status_change": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Status geändert zu ", _interpolate(_named("target"))])},
      "tag_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Mit Stichwort ", _interpolate(_named("target"))])},
      "team_change": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Team geändert zu ", _interpolate(_named("target"))])},
      "snoozed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("author")), " hat die Konversation um ", _interpolate(_named("target")), " verschoben"])},
      "unsnoozed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("author")), " hat das Verschieben der Konversation beendet"])}
    },
    "ticket_insights": {
      "types": {
        "bot_repetition": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Bot befindet sich in einer Schleife und wiederholt eine Nachricht nach der anderen."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot-Wiederholung"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot-Wiederholung, bei der dieselbe Nachricht ein- oder mehrmals nacheinander wiederholt wird"])}
        },
        "custom_spotlight": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passende Phrasen gefunden"])}
        },
        "negative_bot_communication_efficiency": {
          "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ein durchschnittlicher Agent bearbeitet das Gespräch ", _interpolate(_named("percent")), " effizienter als der Bot."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effizienz der Bot-Kommunikation"])}
        },
        "positive_bot_communication_efficiency": {
          "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Der Bot bearbeitet das Gespräch ", _interpolate(_named("percent")), " effizienter arbeiten als ein durchschnittlicher Agent."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effizienz der Bot-Kommunikation"])}
        },
        "recording_disclosure_missing": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In der Konversation fehlt die erforderliche Erklärung, in der die Teilnehmer über die Aufzeichnung informiert werden, was für die Compliance und Transparenz erforderlich ist"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offenlegung der Aufzeichnung fehlt"])}
        },
        "sla_breached": {
          "description_with_examples": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Service-Level-Vereinbarung (SLA) ", _interpolate(_list(0)), " wurde verletzt"])},
          "title_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLA verletzt"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Metrik für die SLA-Regel hat die Zielzeit überschritten"])}
        },
        "churn_risk": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzeichen für Kundenabwanderung. Der Kunde hat einen Wechsel in Betracht gezogen oder seinen Weggang angekündigt."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifiziertes Abwanderungsrisiko"])}
        },
        "deadair_exceeded": {
          "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["In einer Konversation gibt es Gesprächspausen, die länger dauern als der festgelegte Schwellenwert von ", _interpolate(_list(0)), " Sek."])},
          "instances": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 Instanz"]), _normalize([_interpolate(_named("count")), " Instanzen"])])},
          "longest": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " s längstens"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesprächspause"])}
        },
        "escalation": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Kunde hat um Unterstützung auf höherer Ebene gebeten"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eskalation angefordert"])}
        },
        "extra_mile": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Kunde hat sich sehr bedankt oder war mit dem Support sehr zufrieden"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herausragender Service"])}
        },
        "follow_up": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Kunde oder der Mitarbeiter hat ausdrücklich um eine Folgeanfrage gebeten"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folgeanfrage"])}
        },
        "negative_sentiment": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Kunde zeigte sich unzufrieden"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negative Stimmung"])}
        },
        "outlier": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Konversation war ungewöhnlich oder atypisch und es dauerte länger, bis eine Lösung gefunden wurde"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausreißer erkannt"])}
        },
        "positive_sentiment": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Kunde zeigte sich zufrieden"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positive Stimmung"])}
        }
      },
      "feedback": {
        "applicable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zutreffend"])},
        "feedback_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auf „Nicht zutreffend“ setzen"])},
        "last_edited_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Zuletzt bearbeitet von ", _interpolate(_named("author")), " am ", _interpolate(_named("date"))])},
        "not_applicable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht zutreffend"])},
        "set_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Von ", _interpolate(_named("author")), " am ", _interpolate(_named("date"))])},
        "feedback_textarea_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grund hinzufügen (optional)"])}
      },
      "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Insights erkannt"])}
    },
    "ticket_list": {
      "date_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
      "no_connections_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbindung hinzufügen"])},
      "no_connections_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbinden Sie Ihren Help Desk mit Ihrem Konto und lassen Sie mindestens einen Filter erstellen, um die Liste der Konversationen anzuzeigen."])},
      "no_connections_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Konversationen zum Anzeigen vorhanden"])},
      "no_tickets_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeitsbereichseinstellungen anzeigen"])},
      "reviewee_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geprüfte Person"])},
      "details": {
        "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellt"])},
        "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zuletzt aktualisiert"])},
        "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanal"])},
        "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quelle"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ"])}
      },
      "filter_limit_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Festlegen"])},
      "review_period_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(In 1 Tag)"])},
      "review_period_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Diesen Monat)"])},
      "review_period_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Diese Woche)"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nach ID suchen"])},
      "search_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Suchergebnisse zu ", _interpolate(_named("searchQuery"))])},
      "someone_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Von anderen überprüft"])},
      "sort_commented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentiert"])},
      "sort_newest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neueste"])},
      "sort_oldest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Älteste"])},
      "sort_random": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nach dem Zufallsprinzip"])},
      "sort_recent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zuletzt aktualisiert"])},
      "sort_recent_reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kürzlich geantwortet"])},
      "sort_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sortieren nach"])},
      "sort_unread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungelesen"])},
      "you_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Von Ihnen überprüft"])},
      "importing_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Importvorgang kann einige Zeit dauern. Wir benachrichtigen Sie per E-Mail, sobald der Vorgang abgeschlossen ist."])},
      "importing_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA importiert Ihre Konversationen"])},
      "limit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Limit: ", _interpolate(_list(0))])},
      "no_calibration_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klicken Sie zum Hinzufügen auf das Kalibrierungssymbol in der Kopfzeile der Konversationsansicht."])},
      "no_complex_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier gibt es nichts zu berichten. Versuchen Sie es mit einem anderen Filter oder einer anderen Sortierung."])},
      "no_complex_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Ausreißer für die Anzeige vorhanden."])},
      "no_starred_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suchen Sie nach einer relevanten Konversation und klicken Sie auf das Sternsymbol in der Kopfzeile, um sie hier als Lesezeichen zu speichern."])},
      "no_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versuchen Sie, die Filter zu ändern. Oder bitten Sie andere Help-Desk-Agenten, weitere Konversationen hinzuzufügen."])},
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunde"])},
      "filter_limit_no_limit_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kein Limit"])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversationen"])},
      "internal_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interne Notiz"])},
      "loading_tickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir laden gerade die Konversationen. Einige Inhalte fehlen möglicherweise noch."])},
      "member_filter_next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nächster Benutzer"])},
      "member_filter_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kein Benutzer gefunden"])},
      "member_filter_option_prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erhaltene Überprüfungen"])},
      "member_filter_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeitsbereichsmitglieder"])},
      "member_filter_previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorheriger Benutzer"])},
      "no_calibration_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Konversationen hinzugefügt"])},
      "no_calibration_tickets_title_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es wurden keine Konversationen hinzugefügt"])},
      "no_drafts_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier sind noch nicht beendete Überprüfungen zu finden. Lesen Sie sie erneut, überarbeiten Sie sie und reichen Sie sie in den nächsten 30 Tagen ein."])},
      "no_drafts_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Überprüfungsentwürfe"])},
      "no_starred_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine mit Stern markierten Konversationen"])},
      "no_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Konversationen zum Anzeigen vorhanden"])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Übereinstimmung"])},
      "return_to_selected_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück zur ausgewählten Nachricht"])},
      "sort_least_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenigste Antworten"])},
      "sort_most_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meiste Antworten"])},
      "sort_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stimmung"])},
      "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversationsliste umschalten"])},
      "workspace_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Benutzer"])}
    },
    "ticket_reply": {
      "bot_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BOT"])},
      "call_answered": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Anruf beantwortet um ", _interpolate(_named("time"))])},
      "call_ended": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Anruf beendet um ", _interpolate(_named("time"))])},
      "call_placed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Anruf getätigt um ", _interpolate(_named("time"))])},
      "reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überprüft"])},
      "reviewed_by_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Von Ihnen überprüft"])},
      "audio_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Fehler beim Laden der Audiodatei. Stellen Sie sicher, dass Sie bei ", _interpolate(_list(0)), " angemeldet sind."])},
      "satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar zur Kundenzufriedenheitsumfrage"])},
      "audio_error_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler beim Laden der Audiodatei"])},
      "author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autor des Kommentars"])},
      "no_call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kein Anruf wurde durchgeführt"])},
      "open_audio_in_helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beim Help Desk mithören"])},
      "open_in_aircall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Über Aircall zuhören"])},
      "playback_rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wiedergabegeschwindigkeit"])},
      "review_call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anruf überprüfen"])},
      "system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System"])},
      "transcript": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Protokoll"])}
    },
    "visual_filters": {
      "categories": {
        "agent_csat": {
          "sub_categories": {
            "high_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoher"])},
            "low_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niedriger"])},
            "medium_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mittlerer"])},
            "no_csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["–"])},
            "not_a_klaus_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kein Benutzer von Zendesk QA"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA ordnet Kundendienst-Mitarbeiter automatisch basierend auf ihrer monatlichen durchschnittlichen CSAT ein. Finden Sie mit einem Klick Konversationen, die von Mitarbeitern bearbeitet werden, deren CSAT niedriger ist als die ihrer Kollegen."])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rangfolge nach CSAT"])}
        },
        "agent_performance": {
          "sub_categories": {
            "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mittlere"])},
            "high_performer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hohe"])},
            "lowperformer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niedrige"])},
            "sampler": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niedrigste"])},
            "superstar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Höchste"])},
            "not_a_klaus_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kein Benutzer von Zendesk QA"])},
            "onemessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Ticket"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volumen"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA ordnet Kundendienst-Mitarbeiter automatisch in Leistungsgruppen ein, basierend auf der Anzahl von Konversationen, die sie pro Monat bearbeiten. Finden Sie mit einem Klick Konversationen von Mitarbeitern, die ein niedrigeres Volumen als ihre Kollegen aufweisen."])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rangfolge nach Volumen"])}
        },
        "agent_reviewed": {
          "sub_categories": {
            "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überprüft"])},
            "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht überprüft"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überprüfungsstatus"])}
        },
        "agent_sentiment": {
          "sub_categories": {
            "high_negative_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niedrige"])},
            "high_positive_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hohe"])},
            "no_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["–"])},
            "positive_negative_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neutrale"])},
            "not_a_klaus_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kein Benutzer von Zendesk QA"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stimmung"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA ordnet Kundendienst-Mitarbeiter automatisch basierend auf der durchschnittlichen Stimmung in den Konversationen ein. Finden Sie mit einem Klick Interaktionen, bei denen Mitarbeitern mit mehr frustrierten Kunden zu tun haben als ihre Kollegen."])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rangfolge nach Stimmung"])}
        },
        "complexity": {
          "sub_categories": {
            "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht erkannt"])},
            "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erkannt"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausreißer"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversationen, die Ihre Aufmerksamkeit erfordern. Unser Modell für maschinelles Lernen identifiziert komplexe oder atypische Interaktionen, die überprüft werden müssen."])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausreißer"])}
        },
        "sentiment": {
          "sub_categories": {
            "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negativ"])},
            "positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positiv"])}
          },
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie Konversationen zum Überprüfen aus, bei denen der Kunde entweder zufrieden oder frustriert war. Wählen Sie im Dropdownmenü die Art von Stimmung aus, nach der Sie in Ihren Konversationen suchen. Ziehen Sie das Filterattribut nach oben, um das Verhältnis der ausgewählten Stimmung in allen Ihren Interaktionen zu sehen."])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stimmung"])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stimmung"])}
        },
        "reviewed": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überprüfungsstatus"])},
          "sub_categories": {
            "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht überprüft"])},
            "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überprüft"])}
          }
        },
        "channel": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanal"])}
        },
        "connection": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbindung"])}
        },
        "csat": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])}
        },
        "language": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprache"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA erkennt automatisch Sprachen in Ihren Konversationen. Im Dropdownmenü können Sie Konversationen in bestimmten Sprachen zur Überprüfung auswählen. Ziehen Sie das Filterattribut nach oben, um eine Übersicht über alle in Ihrem Help Desk verwendeten Sprachen zu erhalten."])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprache"])}
        }
      },
      "info": {
        "paragraph_1_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversations-Insights"])},
        "paragraph_2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sie können mit Insights ", _interpolate(_named("paragraph_2_item")), ", als mit normalen Konversationsfiltern. Verschaffen Sie sich einen visuellen Überblick über alle Daten Ihrer Help Desks und erhalten Sie Insights wie:"])},
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Infos zur Verwendung von Konversations-Insights"])},
        "bullet_point_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verstehen Sie die relative Bedeutung bestimmter Konversationsattribute im Verhältnis zu anderen"])},
        "bullet_point_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finden Sie mit der integrierten Bewertung der Mitarbeiterleistung die Mitarbeiter mit der besten Leistung und mit einer geringen Leistung"])},
        "paragraph_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("paragraph_1_item")), " ist ein leistungsstarkes interaktives Data-Mining-Tool, mit dem Sie die Konversationen finden können, die am dringendsten überprüft werden müssen."])},
        "paragraph_2_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["so viel mehr tun"])},
        "paragraph_3": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Die Diagramme \"Konversation\" und \"Mitarbeiter\" sind ", _interpolate(_named("paragraph_3_item_1")), ": Wenn Sie bei einem der Diagramme Filter aktualisieren oder auf Diagrammsegmente klicken, wird die Konversationsliste automatisch aktualisiert. ", _interpolate(_named("paragraph_3_item_2")), ", um detaillierte Aufschlüsselungen oder Relationen der verschiedenen Elemente einzusehen."])},
        "paragraph_3_item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["miteinander verbunden"])},
        "paragraph_3_item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordnen Sie die Filterattribute neu an"])},
        "paragraph_4": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Konversations-Insights zeigt nur ", _interpolate(_named("paragraph_4_item")), " an. Wir entfernen automatisch Interaktionen ohne echten Überprüfungswert, z. B. automatisch generierte Nachrichten, Spam, Bot-Antworten oder Nachrichten, die keinen richtigen Dialog mit Ihrem Supportmitarbeiter enthalten."])},
        "paragraph_4_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bereinigte und geschlossene Konversationen"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Was sehe ich hier?"])}
      },
      "out_of": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " von ", _interpolate(_named("total"))]), _normalize([_interpolate(_named("count")), " von ", _interpolate(_named("total"))]), _normalize([_interpolate(_named("count")), " von ", _interpolate(_named("total"))])])},
      "perc_of_parent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("percentage")), " von ", _interpolate(_named("category"))])},
      "sunburst_loading_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler beim Abrufen"])},
      "sunburst_root": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle"])},
      "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["–"])},
      "unknown_category_name": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " –"])},
      "agents_sunburst_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agenten"])},
      "conversations_sunburst_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversationen"])},
      "perc_of_total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["(", _interpolate(_named("percentage")), " der Summe)"])},
      "sunburst_reload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neu laden"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insights"])}
    },
    "survey_sidebar": {
      "empty_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir sind aber sicher, dass es großartig wird, sobald es da ist."])},
      "custom_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angepasste Umfrage"])},
      "empty_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kein Umfragefeedback hinterlassen"])},
      "empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noch kein Umfragefeedback-Wert verfügbar ..."])},
      "rating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewertung"])},
      "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grund"])},
      "response_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["erhalten"])},
      "tag_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback-Stichwort"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback-Kommentar-Stichwörter"])}
    },
    "date_filter": {
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datumsbereichsfilter angewendet"])}
    }
  },
  "csat_dashboard": {
    "csat_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])},
    "pagination_item_replies_with_number": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " Antwort"]), _normalize([_interpolate(_named("count")), " Antworten"])])},
    "tags": {
      "sources": {
        "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CES"])},
        "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
        "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help Desk"])}
      },
      "empty": {
        "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Gehen Sie der Frage nach, warum eine ", _interpolate(_list(0)), "-Bewertung abgegeben wurde, indem Sie in den Umfrageeinstellungen Gründe festlegen."])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Noch keine ", _interpolate(_list(0)), "-Gründe"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Infos zu den Gründen für die Umfrage"])}
      },
      "conversations_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversationen mit Stichwörtern:"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stichwörter"])}
    },
    "ticket_count_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])},
    "breakdown_card": {
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " nach"])},
      "first_column_title": {
        "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanal"])},
        "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quelle"])},
        "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprachen"])},
        "csat_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gründe"])},
        "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stichwörter"])},
        "predicted_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentarkategorie"])}
      }
    },
    "breakdown_sources": {
      "csat_reasons": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("surveyType")), "-Gründe"])},
      "predicted_tags": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Prognostizierte ", _interpolate(_named("surveyType")), "-Treiber"])},
      "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanal"])},
      "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quelle der Konversation"])},
      "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprache"])},
      "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help-Desk-Stichwörter"])}
    },
    "conversations": {
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Umfrageantworten (", _interpolate(_named("n")), ")"]), _normalize(["Umfrageantwort (", _interpolate(_named("n")), ")"]), _normalize(["Umfrageantworten (", _interpolate(_named("n")), ")"])])},
      "no_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kein Betreff"])}
    },
    "csat_by_users": {
      "csat_ticket_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Konversationen mit ", _interpolate(_list(0))])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " nach Benutzern"])},
      "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ändern"])},
      "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
      "response_rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antwortrate"])},
      "ticket_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversationen"])},
      "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer"])}
    },
    "donut": {
      "help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " ist die Summe aller Antworten dividiert durch die Summe der Maximalwerte."])},
      "edit_target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ziel bearbeiten"])},
      "remove_target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ziel entfernen"])},
      "set_target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legen Sie ein Ziel fest"])},
      "target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ziel"])},
      "target_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ziel hinzufügen"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])}
    },
    "filter_bar": {
      "predicted_dimensions": {
        "size": {
          "bitesize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurz"])},
          "extended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sehr lang"])},
          "lengthy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lang"])},
          "mid_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mittellang"])}
        }
      },
      "predicted_tags": {
        "confusing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwirrend"])},
        "support": {
          "not_helpful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht hilfreich"])},
          "bad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schlechter Support"])},
          "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guter Support"])}
        },
        "emoji": {
          "only_emoji": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emotionen im Überfluss"])}
        },
        "account": {
          "issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto"])}
        },
        "agent_specific": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback für Mitarbeiter"])},
        "complaint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschwerde"])},
        "crumbs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fragmente"])},
        "issue": {
          "not_solved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Problem nicht gelöst"])},
          "solved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Problem gelöst"])}
        },
        "negative_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negative Stimmung"])},
        "outcome": {
          "bad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schlechtes Ergebnis"])}
        },
        "positive_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positive Stimmung"])},
        "praise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lob"])},
        "product": {
          "bad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schlechtes Produkt"])}
        },
        "refund": {
          "issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rückerstattung"])}
        },
        "speed": {
          "fast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schneller Support"])},
          "slow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langsamer Support"])}
        },
        "thanks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vielen Dank"])}
      },
      "result_none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["–"])},
      "topic_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Zendesk QA ordnet Ihre ", _interpolate(_list(0)), "-Daten automatisch in vordefinierte Kategorien ein. So können Sie komplexe Insights verstehen und Ursachen analysieren, ohne das schriftliche Feedback manuell durchgehen zu müssen."])},
      "all_scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Werte"])},
      "all_surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Umfragen"])},
      "language_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprache"])},
      "language_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprache für Antwort auswählen"])},
      "length_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentargröße"])},
      "length_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentargröße auswählen"])},
      "length_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Da 25 % aller Kommentare aus nur einem Wort bestehen, müssen längere Kommentare unbedingt gelesen werden. Gewinnen Sie ganz ohne manuelles Zutun aussagekräftiges Feedback – wir erledigen das automatisch."])},
      "reason_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grund des Feedbacks"])},
      "reason_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie den Grund für das Feedback aus"])},
      "scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Werte"])},
      "scores_filter_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine übereinstimmenden Werte"])},
      "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umfragen"])},
      "topic_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prognostizierte Treiber"])},
      "topic_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentarkategorie auswählen"])},
      "wordcloud_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wortwolken-Wörter"])},
      "wordcloud_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wortwolken-Wörter auswählen"])}
    },
    "funnel": {
      "since_last_period": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " seit dem letzten Zeitraum"])},
      "tickets_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " Konversation"]), _normalize([_interpolate(_named("n")), " Konversation"]), _normalize([_interpolate(_named("n")), " Konversationen"])])},
      "bar_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentare"])},
      "bar_conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversationen"])},
      "bar_na_surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufgrund fehlender Daten kann der Anteil der gesendeten Umfragen an allen Konversationen nicht berechnet werden."])},
      "bar_responses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antworten"])},
      "bar_surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umfragen gesendet"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umfragetrichter"])}
    },
    "n_of_total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " von ", _interpolate(_named("total"))]), _normalize([_interpolate(_named("n")), " von ", _interpolate(_named("total"))]), _normalize([_interpolate(_named("n")), " von ", _interpolate(_named("total"))])])},
    "survey_replies": {
      "comment_categories": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Prognostizierte ", _interpolate(_named("surveyType")), "-Treiber"])},
      "comment_categories_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Zendesk QA ordnet Ihre ", _interpolate(_list(0)), "-Daten automatisch in vordefinierte Kategorien ein. So können Sie komplexe Insights verstehen und Ursachen analysieren, ohne das schriftliche Feedback manuell durchgehen zu müssen."])},
      "comment_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentargröße"])},
      "comment_size_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Da 25 % aller Kommentare aus nur einem Wort bestehen, müssen längere Kommentare unbedingt gelesen werden. Gewinnen Sie ganz ohne manuelles Zutun aussagekräftiges Feedback – wir erledigen das automatisch."])},
      "comment_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar"])},
      "csat_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeit"])},
      "score_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wert"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umfrageantworten"])},
      "user_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer"])}
    },
    "timeline": {
      "average": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " (Durchschnitt)"])},
      "bars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der Antworten"])},
      "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
      "iqs_average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS (Durchschnitt)"])},
      "target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ziel"])}
    },
    "timeline_card": {
      "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durchschnitt"])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "-Trends nach"])},
      "bar_tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der Antworten"])},
      "sidebar_title": {
        "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanal"])},
        "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quelle"])},
        "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprachen"])},
        "csat_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gründe"])},
        "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stichwörter"])},
        "predicted_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Treiber"])}
      }
    },
    "view_settings": {
      "date_type_csat_sent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Datum des Absendens der ", _interpolate(_list(0)), "-Umfrage"])},
      "date_type_reply": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Datum der Antwort auf die ", _interpolate(_list(0)), "-Umfrage"])},
      "owner_type": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "-Score zuweisen für"])},
      "date_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daten anzeigen basierend auf"])},
      "date_type_creation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellungsdatum der Konversation"])},
      "owner_type_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inhaber des Tickets"])},
      "owner_type_most_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitarbeiter mit den meisten Antworten"])}
    },
    "workspaces_timeline_card": {
      "average": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " (Durchschnitt)"])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " im Zeitverlauf"])},
      "bar_tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der Antworten"])},
      "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
      "sidebar_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeitsbereiche"])}
    },
    "csat_timeline": {
      "left_axis": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " und IQS"])},
      "right_axis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der Antworten"])}
    },
    "word_cloud": {
      "conversations_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversationen, die diese Wörter enthalten:"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wortwolke"])}
    },
    "card_labels": {
      "csat_by_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT nach Benutzern"])},
      "donut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT-Übersicht"])},
      "funnel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umfragetrichter"])},
      "survey_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umfrageantworten"])},
      "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help-Desk-Stichwörter"])},
      "timeline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT im Zeitverlauf"])},
      "word_cloud": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wortwolke"])}
    },
    "missing_value": {
      "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kein Kanal"])},
      "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Quelle"])},
      "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Sprache"])},
      "csat_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Gründe"])},
      "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Stichwörter"])},
      "predicted_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Kommentarkategorie"])},
      "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kein Arbeitsbereich"])}
    }
  },
  "dashboard": {
    "average_review_time_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])},
    "goal": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])}
    },
    "looker": {
      "assignments": {
        "banner": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Daten verfügbar, da weder Zuweisungen erstellt noch Zyklen gestartet wurden. Schauen Sie später erneut vorbei oder passen Sie die Filter an."])}
        }
      },
      "reviews": {
        "banner": {
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Funktionen entdecken"])}
        }
      }
    },
    "pagination_item_default_with_number": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " Element"]), _normalize([_interpolate(_named("count")), " Elemente"])])},
    "pagination_item_with_number": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " Konversation"]), _normalize([_interpolate(_named("count")), " Konversationen"])])},
    "passed_reviews_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])},
    "percentage": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])}
    },
    "review_status": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])},
      "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht bestanden"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonstiges"])},
      "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestanden"])},
      "pass_with_minors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit geringen Fehlern bestanden"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestanden/Nicht bestanden"])}
    },
    "review_time_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])},
    "stats_card": {
      "na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])}
    },
    "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durchschnitt"])},
    "average_hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durchschnitt (h)"])},
    "average_minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durchschnitt (min)"])},
    "card_labels": {
      "category_ratings": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("categoryName")), "-Bewertungen"])},
      "csat_help": {
        "formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durchschnittlicher CSAT = [Summe der Feedbackwerte ÷ (Anzahl aller Antworten × max. möglicher Wert)] × 100 %"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der CSAT-Score in Zendesk QA normalisiert Ihren Feedback-Wert innerhalb einer Skala von 0 bis 100 % und verwendet den Durchschnitt der standardisierten Werte."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Kundenzufriedenheitsscore (CSAT) gibt an, wie zufrieden Ihre Kunden mit Ihren Services sind."])}
      },
      "help_reviews": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " Jede Bewertung repräsentiert die Rezension von einer Person zu einer Konversation. Zu einer Konversation kann es mehrere Bewertungen geben (wenn zwei oder mehr Prüfer dieselbe Konversation überprüfen)."])},
      "quality_scores_given_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verfolgen Sie die Entwicklung der Bewertungen im Zeitverlauf."])},
      "quality_scores_received_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verfolgen Sie die Entwicklung der Agentenwerte im Zeitverlauf."])},
      "responses_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " Antworten"]), _normalize([_interpolate(_named("n")), " Antwort"]), _normalize([_interpolate(_named("n")), " Antworten"])])},
      "reviews_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " Bewertungen"]), _normalize([_interpolate(_named("n")), " Bewertung"]), _normalize([_interpolate(_named("n")), " Bewertungen"])])},
      "help_reviews_bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewertungen:"])},
      "iqs_help": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der interne Qualitätswert (IQS) ist das Ergebnis Ihrer Konversationsüberprüfungen."])},
        "formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS = Summe der Überprüfungswerte ÷ Anzahl der Überprüfungen"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Wert wird als Durchschnitt berechnet. Dabei werden alle Überprüfungswerte (gewichteter Durchschnitt des Kategoriewerts) berücksichtigt, die den ausgewählten Filterbedingungen entsprechen."])}
      },
      "overview_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler beim Laden der Karte."])},
      "overview_error_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ändern Sie Ihre Filter und versuchen Sie es erneut."])},
      "pass_rate_help": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Erfolgsrate zeigt das Ergebnis Ihrer Konversationsüberprüfungen an."])},
        "tooltip": {
          "fail_rule": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Nicht bestanden, wenn der Überprüfungswert ", _interpolate(_named("failScore")), " oder niedriger ist"])},
          "pass_rule": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bestanden, wenn der Überprüfungswert ", _interpolate(_named("passScore")), " oder höher ist"])}
        },
        "tooltip_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er richtet sich nach einer vordefinierten Baseline. In diesem Fall müssen Sie Folgendes Tun:"])},
        "formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolgsrate = Summe der bestandenen Überprüfungen ÷ Gesamtsumme aller Überprüfungen"])},
        "special": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Erfolgsrate gibt an, wie viele Überprüfungen ohne Fehler der schweren oder nicht-kritischen Kategorie bestanden werden."])}
      },
      "quality_scores_given_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Beispiel: Wenn der Prüfer an einem Tag drei Überprüfungen abgibt, entspricht die angezeigte Zahl dem Durchschnitt der drei Werte. Mehr Infos zur Berechnung der einzelnen Zellen ", _interpolate(_named("link")), "."])},
      "quality_scores_received_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Beispiel: Wenn der Agent an diesem Tag 3 Überprüfungen erhält, entspricht die angezeigte Zahl dem Durchschnitt der 3 Werte. Mehr Infos zur Berechnung der einzelnen Zellen ", _interpolate(_named("link")), "."])},
      "ratings_by_category_given_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht berücksichtigt werden: Kategoriegewichtung, kritischer Status (im Gegensatz zu den Werten im Zeitverlauf)."])},
      "ratings_by_category_given_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Da Kategorien in Überprüfungen als nicht zutreffend markiert werden können, ist die Anzahl der Überprüfungen pro Kategorie möglicherweise geringer als die Gesamtzahl der Überprüfungen des Prüfers. Mehr Infos zur Berechnung der einzelnen Zellen"])},
      "ratings_by_category_received_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht berücksichtigt werden: Kategoriegewichtung, kritischer Status (im Gegensatz zu den Werten im Zeitverlauf)."])},
      "ratings_by_category_received_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Da Kategorien in Überprüfungen als nicht zutreffend markiert werden können, ist die Anzahl der Überprüfungen pro Kategorie möglicherweise geringer als die Gesamtzahl der Überprüfungen pro Agent. Mehr Infos zur Berechnung der einzelnen Zellen"])},
      "scores_by_category_given_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht berücksichtigt werden: Kategoriegewichtung, kritischer Status (im Gegensatz zu den Werten im Zeitverlauf)."])},
      "scores_by_category_given_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list, named: _named } = ctx;return _normalize([_interpolate(_list(0)), " Kategoriegruppen werden separat angezeigt. Der Wert der einzelnen Gruppen wird anhand des durchschnittlichen Kategoriewerts für diese Gruppe in demselben Zeitraum berechnet. Mehr Infos zur Berechnung der einzelnen Zellen ", _interpolate(_named("link")), "."])},
      "scores_by_category_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinweis:"])},
      "scores_by_category_received_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht berücksichtigt werden: Kategoriegewichtung, kritischer Status (im Gegensatz zu den Werten im Zeitverlauf)."])},
      "scores_by_category_received_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list, named: _named } = ctx;return _normalize([_interpolate(_list(0)), " Kategoriegruppen werden separat angezeigt. Der Wert der einzelnen Gruppen wird anhand des durchschnittlichen Kategoriewerts für diese Gruppe in demselben Zeitraum berechnet. Mehr Infos zur Berechnung der einzelnen Zellen ", _interpolate(_named("link")), "."])},
      "baseline": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Baseline ", _interpolate(_named("count")), " %"])},
      "critical_error_free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine schweren Fehler"])},
      "critical_error_free_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Rate \"Keine schweren Fehler\" gibt den Prozentsatz der Anzahl an Überprüfungen an, die ohne schwere Fehler abgeschlossen werden."])},
      "internal_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interner Qualitätswert"])},
      "other_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"Sonstige\" Hauptursachen"])},
      "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übersicht"])},
      "period_change": {
        "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regelmäßige Änderung"])},
        "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tägliche Änderung"])},
        "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monatliche Änderung"])},
        "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wöchentliche Änderung"])},
        "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jährliche Änderung"])}
      },
      "quality_scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Werte im Zeitverlauf"])},
      "quality_scores_given_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie die Tages- oder Wochenansicht."])},
      "quality_scores_given_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berechnung = Gesamtwert für einen Tag oder eine Woche dividiert durch die Gesamtzahl der abgegebenen Überprüfungen"])},
      "quality_scores_received_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie die Tages- oder Wochenansicht."])},
      "quality_scores_received_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berechnung = Gesamtwert für einen Tag oder eine Woche dividiert durch die Gesamtzahl der erhaltenen Überprüfungen"])},
      "ratings_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Werte nach Kategorie"])},
      "ratings_by_category_given_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeigen Sie den kombinierten Durchschnitt aller Kategorie-Bewertungen an, die der Prüfer in diesem Zeitraum abgegeben hat."])},
      "ratings_by_category_received_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeigen Sie den kombinierten Durchschnitt aller Kategorie-Bewertungen an, die der Prüfer in diesem Zeitraum erhalten hat."])},
      "root_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hauptursachen für niedrige Bewertungen"])},
      "root_causes_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hauptursachen für Bewertungen"])},
      "scores_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategoriewerte im Zeitverlauf"])},
      "scores_by_category_given_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysieren Sie, wie Kategorien im Zeitverlauf abschneiden."])},
      "scores_by_category_given_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeigen Sie die durchschnittlichen abgegebenen Werte für jede Kategorie an einem bestimmten Tag oder in einer bestimmten Woche an."])},
      "scores_by_category_received_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysieren Sie, wie Kategorien im Zeitverlauf abschneiden."])},
      "scores_by_category_received_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeigen Sie die durchschnittlichen erhaltenen Werte für jede Kategorie an einem bestimmten Tag oder in einer bestimmten Woche an."])},
      "scores_by_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Werte nach Überprüfungen"])},
      "tickets_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversationen"])},
      "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer"])},
      "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeitsbereiche"])}
    },
    "category_tooltip": {
      "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archiviert"])},
      "critical_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kritische Kategorie"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibung"])},
      "rating_scale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewertungsskala"])}
    },
    "custom_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durchschnitt/Gesamt"])},
    "exclude_self_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selbstüberprüfungen ausschließen"])},
    "export_all_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportiert alle Karten"])},
    "feedback_direction_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abgegeben"])},
    "feedback_direction_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empfangen"])},
    "pagination_item": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Konversation"]), _normalize(["Konversationen"])])},
    "pagination_item_default": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Element"]), _normalize(["Elemente"])])},
    "response_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([" ", _interpolate(_named("n")), " Antworten"]), _normalize([_interpolate(_named("n")), " Antwort"]), _normalize([_interpolate(_named("n")), " Antworten"])])},
    "review_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " Bewertungen"]), _normalize([_interpolate(_named("n")), " Bewertung"]), _normalize([_interpolate(_named("n")), " Bewertungen"])])},
    "show_more": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 weitere anzeigen"]), _normalize([_interpolate(_named("n")), " weitere anzeigen"])])},
    "time_periods": {
      "last_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letzte 30 Tage"])},
      "last_7_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letzte 7 Tage"])},
      "last_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorigen Monat"])},
      "last_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letzte Woche"])},
      "this_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diesen Monat"])},
      "this_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Woche"])},
      "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angepasster Zeitraum"])}
    },
    "time_steps": {
      "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tage"])},
      "months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monate"])},
      "quarters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quartale"])},
      "weeks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wochen"])},
      "years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jahre"])}
    },
    "card_load_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler beim Laden der Karte. Ändern Sie Ihre Filter und versuchen Sie es erneut."])},
    "card_no_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine anzuzeigenden Daten vorhanden."])},
    "monthly_change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monatliche Änderung"])},
    "navbar": {
      "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA"])},
      "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zuweisungen"])},
      "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrierung"])},
      "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorien"])},
      "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Streitfälle"])},
      "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übersicht"])},
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewertungen"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboards"])}
    },
    "rating_scale_change": {
      "info_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Informationen."])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die aktiven Filter umfassen Daten aus verschiedenen Bewertungsskalen."])}
    },
    "all_pins_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Kartennotizen mit diesen Filtern"])},
    "all_pins_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Dashboard-Notizen mit diesen Filtern"])},
    "average_review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durchschnittliche Überprüfungszeit"])},
    "card_customize_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anpassen"])},
    "card_export_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSV herunterladen"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie"])},
    "category_picker": {
      "no_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Kategorien"])}
    },
    "category_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie-Insights"])},
    "chart_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diagramm"])},
    "comment": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Kommentar"]), _normalize(["Kommentare"])])},
    "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentare"])},
    "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversationen"])},
    "data_grid_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matrix"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
    "former_member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ehemaliges Mitglied"])},
    "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interner Qualitätswert"])},
    "iqs_average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS (Durchschnitt)"])},
    "passed_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestandene Überprüfungen"])},
    "pin_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karte anheften"])},
    "pin_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard anheften"])},
    "ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewertungen"])},
    "reason": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Hauptursache"]), _normalize(["Hauptursachen"])])},
    "review_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überprüfungs-ID"])},
    "review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überprüfungszeit"])},
    "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geprüfte Person"])},
    "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prüfer"])},
    "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewertungen"])},
    "root_causes": {
      "no_causes_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hauptursachen einrichten"])},
      "no_causes_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Hauptursachen zeigen Ihnen, warum Konversationen niedrige Werte erhalten"])},
      "no_causes_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Für diese Kategorie sind keine Hauptursachen festgelegt"])}
    },
    "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wert"])},
    "score_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wert/Summe"])},
    "scores_by_category": {
      "grouping_options": {
        "by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewertungskategorien"])},
        "by_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewertungskategorie-Gruppen"])},
        "by_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeitsbereiche"])}
      },
      "labels": {
        "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie"])},
        "category_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategoriegruppe"])},
        "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeitsbereich"])}
      },
      "settings_modal": {
        "customize_order_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reihenfolge anpassen"])},
        "group_by_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorien gruppieren nach"])},
        "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle auswählen"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anpassen"])}
      }
    },
    "self_reviews_exclude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selbstüberprüfungen ausschließen"])},
    "self_reviews_include": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selbstüberprüfungen einschließen"])},
    "self_reviews_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nur Selbstüberprüfungen anzeigen"])},
    "sidebar": {
      "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter löschen"])},
      "comment_hashtags_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Hashtags gefunden"])},
      "comment_hashtags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar-Hashtags"])},
      "comment_hashtags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar-Hashtags auswählen"])},
      "helpdesk_tags_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Stichwörter gefunden"])},
      "helpdesk_tags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help-Desk-Stichwörter"])},
      "helpdesk_tags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help-Desk-Stichwörter durchsuchen"])},
      "scorecard_tags_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Scorecards gefunden"])},
      "scorecard_tags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecards"])},
      "scorecard_tags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecards auswählen"])},
      "self_reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selbstüberprüfungen"])},
      "ticket_source_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine übereinstimmenden Verbindungen"])},
      "ticket_source_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quelle der Konversation"])},
      "ticket_source_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quelle auswählen"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Filter"])},
      "workspace_empty_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine übereinstimmenden Arbeitsbereiche"])}
    },
    "total_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angegebene Gesamtzeiten"])},
    "user_filter_all_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Benutzer"])},
    "user_filter_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle"])},
    "user_group_filter": {
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Gruppen"])},
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine übereinstimmenden Gruppen."])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppen"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Gruppen"])}
    },
    "view_settings": {
      "cards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karten anzeigen"])},
      "date_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daten anzeigen basierend auf"])},
      "date_type_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellungsdatum der Überprüfung"])},
      "date_type_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellungsdatum der Konversation"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonstiges"])},
      "show_decimal_places": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zwei Dezimalstellen anzeigen"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ansichteneinstellungen"])},
      "trigger_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ansehen"])}
    },
    "workspace_filter_all_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Arbeitsbereiche"])},
    "workspace_filter_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeitsbereiche"])},
    "workspace_filter_whole_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamtes Konto"])},
    "workspace_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name des Arbeitsbereichs"])},
    "workspace_picker": {
      "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Arbeitsbereiche"])}
    }
  },
  "emoji_picker": {
    "category": {
      "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivität"])},
      "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angepasst"])},
      "flags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flaggen"])},
      "foods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Essen und Trinken"])},
      "nature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiere und Natur"])},
      "objects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objekte"])},
      "people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensch und Maschine"])},
      "places": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reisen"])},
      "recent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Häufig verwendet"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suchergebnisse"])},
      "smileys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smileys und Emoticon"])},
      "symbols": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Symbole"])}
    },
    "notfound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kein Emoji gefunden"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suchen"])},
    "user_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])}
  },
  "example_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öffnen Sie eine Konversation"])},
  "extension": {
    "tasks": {
      "assignment_back_to_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück zur Aufgabenliste"])}
    },
    "add_connection": {
      "connections": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("connect")), " oder durchsuchen Sie Ihre Liste von ", _interpolate(_named("link"))])},
      "call_to_action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sie verwenden gegenwärtig den ", _interpolate(_named("workspace")), "-Arbeitsbereich, der nicht mit dieser Domäne verbunden ist. Klicken Sie hier, um ihn jetzt zu verbinden."])},
      "connected_domains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["verfügbare Verbindungen"])},
      "intro": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sie können zu ", _interpolate(_named("host")), " noch kein Feedback abgeben."])},
      "no_host_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help Desk als aktive Registerkarte verwenden."])},
      "no_permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Domäne kann noch nicht überprüft werden. Falls dies der Fall sein sollte, benachrichtigen Sie Ihren Administrator."])},
      "add_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Domäne hinzufügen"])},
      "connect": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Verbinden mit ", _interpolate(_named("host"))])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einen Moment – die Einrichtung läuft"])},
      "no_host_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL konnte nicht abgerufen werden"])}
    },
    "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback"])},
    "settings": {
      "connections_connected_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tools, die bereits mit dem Zendesk QA-Konto der Organisation verbunden sind."])},
      "connections_empty_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es sind keine Tools mit dem Zendesk QA-Konto der Organisation verbunden."])},
      "hash_setting_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ermöglicht es Ihnen, Websites zu überprüfen, bei denen die URL immer gleich bleibt, indem Sie einen eindeutigen Hash am Ende anfügen, bevor Sie eine Bewertung abgeben."])},
      "account_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto wechseln"])},
      "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbindungen"])},
      "domains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Domänen"])},
      "domains_connected_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domänen, die bereits mit diesem Arbeitsbereich verbunden sind."])},
      "domains_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können jede Seite in den folgenden Domänen überprüfen"])},
      "domains_whitelisted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zur Überprüfung verfügbare Domänen"])},
      "extension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nebenstelle"])},
      "hash_setting_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eindeutige URL"])},
      "no_connections_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Noch kein Help Desk verbunden – gehen Sie zu ", _interpolate(_named("link")), ", um eines hinzuzufügen."])},
      "plan_restriction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Zendesk QA-Browsererweiterung kann nur bei\nProfessional- und Advanced-Plänen verwendet werden."])},
      "workspace_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeitsbereich wechseln"])}
    },
    "create_ticket": {
      "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitarbeiter:"])},
      "custom_fields": {
        "no_name_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie einen Namen ein"])},
        "no_value_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie einen Wert ein"])},
        "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angepasste Felder"])},
        "value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wert"])}
      },
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dies ist das erste Mal, dass wir auf diese Konversation stoßen. Geben Sie einige Details ein."])},
      "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversationsreferenz:"])},
      "subject_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etwas, worüber das Ticket später identifiziert werden kann"])},
      "subject_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rückerstattung"])},
      "validation_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie den Bearbeiter und einen Betreff ein"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellen"])},
      "button_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellen"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Konversation überprüfen"])},
      "unassigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht zugewiesen"])}
    },
    "disabled": {
      "settings_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wenden Sie sich an Ihren Manager oder wechseln Sie zu einem anderen Arbeitsbereich als ", _interpolate(_named("settings")), "."])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selbstüberprüfungen sind für diesen Arbeitsbereich deaktiviert."])},
      "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einstellungen"])}
    },
    "footer": {
      "log_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abmelden"])},
      "log_out_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tschüss"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer"])},
      "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeitsbereich"])}
    },
    "invalid_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese URL kann nicht überprüft werden. Navigieren Sie zu einer Konversation."])},
    "login": {
      "app_login_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Melden Sie sich zuerst über Ihren SSO-Anbieter bei Zendesk QA an und versuchen Sie es dann erneut."])},
      "log_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelden"])},
      "log_in_sso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelden per SSO"])},
      "opening_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pop-up-Fenster für die Anmeldung wird geöffnet."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Melden Sie sich mit Ihrem Zendesk QA-Konto an"])},
      "wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warten Sie ..."])},
      "authenticating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wird authentifiziert ..."])},
      "no_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben noch kein Konto?"])},
      "sign_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrieren"])}
    },
    "messages": {
      "access": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben keinen Zugriff auf diese Konversation. Falls dies der Fall sein sollte, wenden Sie sich an Ihren Manager."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kein Zugriff"])}
      },
      "connection_error": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stellen Sie sicher, dass Ihre Verbindung in Zendesk QA aktiviert ist"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Verbindungen verwalten"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbindungsfehler"])}
      },
      "invalid_url": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navigieren Sie zu einer Konversation in Ihrem Help Desk, um fortzufahren"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültige URL"])}
      },
      "reconnect": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Wiederherstellung der Verbindung ist fast abgeschlossen."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einen Moment"])}
      },
      "reload": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versuchen Sie, die Seite erneut zu laden, um fortzufahren"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Fehler ist aufgetreten"])}
      },
      "subscription": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überwinden Sie die virtuellen Grenzen Ihres Help Desks und überprüfen Sie Konversationen an jedem Ort."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualisieren Sie Ihr Abonnement, um die Erweiterung zu verwenden"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Abonnement verwalten"])}
      },
      "base_connection_error": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Help Desk-Verbindung ist nicht mit diesem Arbeitsbereich verknüpft"])}
      },
      "not_found": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieses Ticket wird vom Help Desk nicht erkannt oder Zendesk QA hat keinen Zugriff darauf"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversation nicht gefunden"])}
      }
    },
    "notifications": {
      "feedback": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Aktivität"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben Feedback erhalten"])}
      },
      "login": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmeldung erfolgreich"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können die App jetzt verwenden"])}
      }
    },
    "review": {
      "require_all_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Kategorien bewerten"])},
      "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiermit können Sie Fälle als Lesezeichen hinzufügen und später herausfiltern"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wird geladen..."])},
      "no_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kein Feedback zu dieser Konversation"])},
      "read_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback lesen"])},
      "view_on_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Zendesk QA öffnen"])}
    },
    "activity": {
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier werden alle Benachrichtigungen und das gesamte Feedback angezeigt. Noch nichts vorhanden ..."])},
      "see_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehr in Zendesk QA anzeigen"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erhaltene Überprüfungen"])}
    },
    "nav": {
      "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivität"])},
      "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zuweisungen"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überprüfen"])},
      "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einstellungen"])},
      "ticket_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversationsdetails"])}
    }
  },
  "filter": {
    "condition": {
      "label": {
        "all_strings_contain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enthält alle"])},
        "all_strings_equal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ist alles von"])},
        "all_strings_not_contain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["enthält nicht alle von"])},
        "all_strings_not_equal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ist nicht alles von"])},
        "bot_better": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Besser als Agenten"])},
        "bot_worse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schlechter als Agenten"])},
        "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["falsch"])},
        "string_is_greater_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ist länger als"])},
        "string_list_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["enthält beliebige von"])},
        "string_list_equals_any": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ist beliebiges von"])},
        "string_list_not_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["enthält keines von"])},
        "string_list_not_equals_any": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ist keines von"])},
        "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wahr"])},
        "breached_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["verletzt"])},
        "comment_commented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kommentiert"])},
        "comment_commented_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["von mir kommentiert"])},
        "comment_not_commented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hat nicht kommentiert"])},
        "comment_not_commented_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nicht von mir kommentiert"])},
        "complex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["entscheidend für die Überprüfung"])},
        "contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["enthält"])},
        "date_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["letzte 30 Tage"])},
        "date_dynamic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["innerhalb"])},
        "date_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["von"])},
        "date_last_14_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["letzte 14 Tage"])},
        "date_last_24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["letzte 24 Std."])},
        "date_last_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["letzte 30 Tage"])},
        "date_last_7_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["letzte 7 Tage"])},
        "date_last_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["letzter Monat"])},
        "date_last_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["letzte Woche"])},
        "date_this_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dieser Monat"])},
        "date_this_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["diese Woche"])},
        "date_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bis"])},
        "date_today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["heute"])},
        "date_yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gestern"])},
        "detected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["erkannt"])},
        "exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vorhanden"])},
        "highlight_highlighted_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["von mir markiert"])},
        "highlight_not_highlighted_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nicht von mir markiert"])},
        "includes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["enthält"])},
        "integer_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ist"])},
        "integer_higher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["größer als"])},
        "integer_higher_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["seit"])},
        "integer_lower": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kleiner als"])},
        "integer_lower_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bis"])},
        "integer_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ist nicht"])},
        "long_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ist"])},
        "long_list_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ist nicht"])},
        "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["negativ"])},
        "not_breached_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nicht verletzt"])},
        "not_complex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nicht komplex"])},
        "not_detected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nicht erkannt"])},
        "not_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nicht vorhanden"])},
        "not_includes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["enthält nicht"])},
        "not_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nicht erhalten"])},
        "not_viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nicht gelesen"])},
        "positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["positiv"])},
        "rating_not_rated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nicht überprüft"])},
        "rating_not_rated_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nicht von mir überprüft"])},
        "rating_rated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["überprüft"])},
        "rating_rated_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["von mir überprüft"])},
        "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["erhalten"])},
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["entscheidend für die Überprüfung"])},
        "string_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["enthält"])},
        "string_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ist"])},
        "string_greater_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["größer als"])},
        "string_greater_than_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["seit"])},
        "string_less_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kleiner als"])},
        "string_less_than_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bis"])},
        "string_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ist"])},
        "string_list_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ist nicht"])},
        "string_not_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["enthält nicht"])},
        "string_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ist nicht"])},
        "viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gelesen"])},
        "comment_my_comment_has_reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zu meinem Kommentar gibt es Antworten"])},
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nein"])},
        "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ja"])}
      }
    },
    "groups": {
      "custom_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angepasste Kategorien"])},
      "custom_spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angepasstes Spotlight"])},
      "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nach Bedingung suchen"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonstiges"])},
      "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorien"])},
      "conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversation"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
      "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help Desk"])},
      "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informationen"])},
      "metrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metriken"])},
      "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teilnehmer"])},
      "review_and_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überprüfung und Feedback"])},
      "show_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Bedingungen anzeigen"])},
      "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spotlight"])}
    },
    "option": {
      "concatenated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("description")), " ", _interpolate(_named("condition")), " ", _interpolate(_named("selectedOption"))])},
      "description": {
        "bot_communication_efficiency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vergleicht die Konversationsführung Ihres Bots mit der von durchschnittlichen Agenten"])},
        "bot_repetition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Bot befindet sich in einer Schleife und sendet die gleiche Nachricht als aufeinanderfolgende Antworten."])},
        "bot_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl von Bot-Antworten in Konversationen"])},
        "bot_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein an der Konversation beteiligter Bot-Typ"])},
        "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein an der Konversation beteiligter Bot"])},
        "custom_spotlight": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Konversationsinhalt enthält den Ausdruck „", _interpolate(_list(0)), "“ in Agentennachrichten"])},
        "has_recording_disclosure_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anrufe automatisch erkennen, bei denen der erforderliche Offenlegungshinweis fehlt, z. B. „Dieser Anruf wird aufgezeichnet“."])},
        "related_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Person oder Bot, die bzw. der an der Konversation beteiligt ist"])},
        "reviewee_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Kundendienst-Mitarbeiter oder der Bot, die in der Konversation überprüft werden"])},
        "ticket_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Kunde hat um Unterstützung auf höherer Ebene gebeten"])},
        "ticket_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In der Konversation erkannte positive und negative Stimmung"])},
        "agent_most_public_messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifiziert den aktivsten Agenten in Konversationen durch KI-Analyse"])},
        "closing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysiert, ob der Agent die Konversation freundlich abgeschlossen hat"])},
        "empathy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysiert, ob der Agent die Gefühle des Kunden versteht und anerkennt"])},
        "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysiert Grammatik-, Rechtschreib- und Stilfehler des Agenten"])},
        "greeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysiert die gesamte Konversation auf typische Begrüßungssätze"])},
        "issue_understanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysiert, ob der Agent die Frage oder das Anliegen des Kunden versteht"])},
        "readability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysiert, wie leicht ein Text unter Berücksichtigung der Wortkomplexität und der Satzlänge verstanden werden kann"])},
        "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysiert die gesamte Konversation im Hinblick auf eine angebotene Lösung"])},
        "tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysiert den Ton des Agenten während der Konversation"])},
        "has_churn_risk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzeichen für Kundenabwanderung. Der Kunde hat einen Wechsel in Betracht gezogen oder seinen Weggang angekündigt."])},
        "ticket_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppe, zu der die Konversation gehört."])},
        "agent_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Inhalt oder Text der Nachricht des Mitarbeiters"])},
        "agent_public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl an Mitarbeiter-Antworten in Konversationen"])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Mitarbeiter, dem die Überprüfung der Konversation zugewiesen wurde"])},
        "call_duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Dauer der Anrufkonversation in Sekunden"])},
        "comment_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Inhalt oder Text der Konversation"])},
        "comment_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Für die Konversation genutzter Kommunikationskanal (z. B. E-Mail, Chat)"])},
        "comment_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zur Konversation hinzugefügte Kommentare"])},
        "commented_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Datum, an dem ein Kommentar zur Konversation hinzugefügt wurde"])},
        "conversation_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In der Konversation erkannte positive und negative Stimmung"])},
        "csat_answered_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Datum, an dem der Kunde auf die Kundenzufriedenheitsumfrage geantwortet hat"])},
        "dispute_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ob die Überprüfung der Konversation angefochten wurde"])},
        "end_user_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Kanal, über den der Kunde die Konversation gestartet hat"])},
        "end_user_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Anzahl an Kunden-Antworten in Konversationen"])},
        "external_comment_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Datum der letzten Nachricht in der Konversation"])},
        "from_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Rolle oder der Typ des an der Konversation beteiligten Benutzers"])},
        "has_deadair_exceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Schweigen zwischen dem Mitarbeiter und dem Kunden hat den Schwellenwert überschritten"])},
        "has_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Kunde hat um Unterstützung auf höherer Ebene gebeten"])},
        "has_extra_mile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Kunde hat sich sehr bedankt oder war mit dem Support sehr zufrieden"])},
        "has_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Kunde oder der Mitarbeiter hat ausdrücklich um eine Folgeanfrage gebeten"])},
        "has_transcription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Text oder die Aufzeichnung der gesamten Sprachkonversation"])},
        "highlight_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ob die Konversation mit Stern markiert wurde"])},
        "internal_comment_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtags, die in Kommentaren zur Kategorisierung von Inhalten verwendet werden"])},
        "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Von der KI erkannte Sprache der Konversation"])},
        "last_external_comment_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Datum der letzten Nachricht in der Konversation"])},
        "private_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl privater Notizen in Konversationen"])},
        "public_character_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durchschnittliche Anzahl an Zeichen pro Nachricht in Konversationen"])},
        "public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der an den Kunden gerichteten Antworten in Konversationen"])},
        "public_participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Teilnehmer, dessen Informationen öffentlich sichtbar sind"])},
        "public_word_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durchschnittliche Anzahl an Wörtern pro Nachricht in Konversationen"])},
        "rating_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zum Bewerten oder Auswerten der Konversation verwendete Kategorien"])},
        "rating_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Status der Überprüfung der Konversation"])},
        "related": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Teilnehmer oder eine Person, der bzw. die an der Konversation beteiligt ist"])},
        "related_name_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine mit der Konversation verknüpfte E-Mail"])},
        "review_viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ob die Überprüfung aufgerufen wurde"])},
        "reviewed_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Prüfer, der die Konversation bewertet hat"])},
        "reviewed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Datum, an dem die Konversation zuletzt überprüft wurde"])},
        "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Mitarbeiter, der in der Konversation überprüft wird"])},
        "satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konkrete Kommentare oder Feedback des Kunden bezüglich seiner Zufriedenheit mit der Konversation"])},
        "satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Kundenzufriedenheitswert für die Konversation"])},
        "satisfaction_score_per_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundenzufriedenheitswert pro Nachricht in der Konversation"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die zur Überprüfung der Konversation verwendete Scorecard"])},
        "sla_breach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorgegebene SLAs wurden verletzt"])},
        "source_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Typ der Quelle oder Verbindung, von der die Konversation stammt"])},
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Konversation war ungewöhnlich oder atypisch und es dauerte länger, bis eine Lösung gefunden wurde"])},
        "survey_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Umfrage-Frage, die mit dem Inhalt der Konversation zusammenhängt"])},
        "survey_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Titel oder Name der Umfrage, die mit der Konversation verknüpft ist"])},
        "ticket_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Mitarbeiter, dem die Überprüfung der Konversation zugewiesen wurde"])},
        "ticket_brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit der Konversation verknüpfte/r/s Marke, Produkt oder Service"])},
        "ticket_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Für die Konversation genutzter Kommunikationskanal (z. B. E-Mail, Chat)"])},
        "ticket_closed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Datum, an dem die Konversation geschlossen oder gelöst wurde"])},
        "ticket_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Datum, an dem die Konversation ursprünglich erstellt wurde"])},
        "ticket_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Datum, an dem die Konversation ursprünglich erstellt wurde"])},
        "ticket_csat_answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Datum, an dem der Kunde auf die Kundenzufriedenheitsumfrage geantwortet hat"])},
        "ticket_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine kurze Beschreibung oder Zusammenfassung der Konversation"])},
        "ticket_external_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine mit der Konversation verknüpfte externe Referenz-ID"])},
        "ticket_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Ordner oder die bestimmte Gruppierung, in den bzw. die die Konversation eingeordnet wurde"])},
        "ticket_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Formular oder die Vorlage zum Strukturieren der Konversation"])},
        "ticket_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine mit der Konversation verknüpfte externe Referenz-ID"])},
        "ticket_mailbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Mailbox oder das E-Mail-Konto, über die die Konversation eingegangen ist"])},
        "ticket_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die mit der Konversation verknüpfte Telefonnummer"])},
        "ticket_priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die der Konversation zugewiesene Prioritätsstufe"])},
        "ticket_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Gesamtzahl der Antworten in der Konversation"])},
        "ticket_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Status oder der aktuelle Status der Konversation"])},
        "ticket_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der aktuelle Status der Konversation"])},
        "ticket_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Betreff oder das Thema der Konversation"])},
        "ticket_support_rep_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl an Mitarbeiter-Antworten in Konversationen"])},
        "ticket_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zur Kategorisierung der Konversation zugewiesene Stichwörter"])},
        "ticket_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Typ oder die Kategorie der Konversation"])},
        "ticket_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Datum, an dem die Konversation zuletzt aktualisiert wurde"])},
        "ticket_updated_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Datum, an dem die Konversation zuletzt aktualisiert wurde"])},
        "ticket_via": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Medium oder der Kanal, über das/den die Konversation erfolgte"])},
        "transcription_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Inhalt einer Transkription in einer Konversation"])},
        "trash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversationen, die nicht für eine Überprüfung durch KI-Analyse infrage kommen"])},
        "unique_agent_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der an Konversationen beteiligten Kundendienst-Mitarbeiter"])},
        "viewed_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ob die Konversation aufgerufen wurde"])}
      },
      "label": {
        "all_messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beliebige Nachricht"])},
        "bot_communication_efficiency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effizienz der Bot-Kommunikation"])},
        "bot_repetition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot-Wiederholung"])},
        "bot_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot-Antwortanzahl"])},
        "bot_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot-Typ"])},
        "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot"])},
        "call_direction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anrufrichtung"])},
        "client_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundennachricht"])},
        "deadair": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesprächspause"])},
        "has_recording_disclosure_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offenlegung der Aufzeichnung fehlt"])},
        "sla_breach_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLA"])},
        "ticket_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eskalation"])},
        "transcription_content_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inhalt von Anrufprotokollen"])},
        "closing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abschluss"])},
        "highlight_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Markiert"])},
        "is_sunburst_trash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kann nicht überprüft werden"])},
        "reviewed_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überprüft von"])},
        "ticket_via": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversation per"])},
        "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechtschreibung und Grammatik"])},
        "agent_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitarbeiter-Nachricht"])},
        "agent_most_public_messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivster Mitarbeiter"])},
        "agent_public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl an Mitarbeiter-Antworten"])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitarbeiter"])},
        "call_duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anrufdauer (Sek.)"])},
        "comment_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversationsinhalt"])},
        "comment_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachrichtenkanal"])},
        "comment_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentarstichwort"])},
        "comment_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar"])},
        "commented_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum des Kommentars"])},
        "conversation_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stimmung"])},
        "csat_answered_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum der Antwort auf die CSAT-Umfrage"])},
        "dispute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Streitfall"])},
        "empathy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empathie"])},
        "end_user_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundenkanal"])},
        "end_user_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl an Kunden-Antworten"])},
        "external_comment_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum der letzten Antwort"])},
        "from_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzertyp"])},
        "greeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruß"])},
        "has_churn_risk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abwanderungsrisiko"])},
        "has_deadair_exceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesprächspause"])},
        "has_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eskalation"])},
        "has_extra_mile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herausragender Service"])},
        "has_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folgeanfrage"])},
        "has_transcription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Protokoll"])},
        "internal": {
          "comment_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar-Hashtag"])}
        },
        "is_complex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausreißer"])},
        "issue_understanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verständnis"])},
        "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprache"])},
        "last_external_comment_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum der letzten Antwort"])},
        "private_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl an privaten Notizen in Konversationen"])},
        "public_mean_character_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durchschnittliche Anzahl an Zeichen"])},
        "public_mean_word_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durchschnittliche Anzahl an Wörtern"])},
        "public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl an öffentlichen Antworten"])},
        "public_participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öffentlicher Teilnehmer"])},
        "rated_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewertungsdatum"])},
        "rating_and_comment_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewertung und Kommentar"])},
        "rating_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewertungskategorie"])},
        "rating_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überprüfungsstatus"])},
        "readability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lesbarkeit"])},
        "related": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teilnehmer"])},
        "related_name_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verknüpfte E-Mail-Adresse"])},
        "related_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verknüpfung"])},
        "reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl an Antworten"])},
        "review_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erhaltene Überprüfungen"])},
        "review_scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überprüfte Scorecard"])},
        "review_viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufgerufene Überprüfung"])},
        "reviewed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überprüfungsdatum"])},
        "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geprüfte Person"])},
        "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prüfer"])},
        "satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umfragekommentar"])},
        "satisfaction_per_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT pro Nachricht"])},
        "satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zufriedenheitswert (CSAT)"])},
        "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angebotene Lösung"])},
        "source_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quellentyp"])},
        "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betreff"])},
        "survey_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frage der Umfrage"])},
        "survey_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titel der Umfrage"])},
        "ticket_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitarbeiter"])},
        "ticket_brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marke der Konversation"])},
        "ticket_brand_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Markendomäne"])},
        "ticket_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversationskanal"])},
        "ticket_closed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschlossen am date"])},
        "ticket_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellungsdatum"])},
        "ticket_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellungsdatum"])},
        "ticket_csat_answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum der Antwort auf die CSAT-Umfrage"])},
        "ticket_csat_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum des Absendens der CSAT-Umfrage"])},
        "ticket_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibung der Konversation"])},
        "ticket_external_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Externe ID"])},
        "ticket_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversationsfeld"])},
        "ticket_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversationsordner"])},
        "ticket_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversationsformular"])},
        "ticket_freshdesk_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freshdesk-ID"])},
        "ticket_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversationsgruppe"])},
        "ticket_help_scout_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helpscout-ID"])},
        "ticket_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Externe ID"])},
        "ticket_intercom_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intercom-ID"])},
        "ticket_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard"])},
        "ticket_mailbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mailbox der Konversation"])},
        "ticket_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonnummer"])},
        "ticket_priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Priorität der Konversation"])},
        "ticket_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl an Konversationsantworten"])},
        "ticket_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversationsstatus"])},
        "ticket_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversationsstatus"])},
        "ticket_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betreff der Konversation"])},
        "ticket_support_rep_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl an Mitarbeiter-Antworten"])},
        "ticket_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help-Desk-Stichwort"])},
        "ticket_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help-Desk-Stichwort"])},
        "ticket_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversationstyp"])},
        "ticket_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualisierungsdatum"])},
        "ticket_updated_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualisierungsdatum"])},
        "ticket_zendesk_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk-ID"])},
        "tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Gesprächston"])},
        "transcription_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transkriptionsinhalt"])},
        "unique_agent_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der teilnehmenden Kundendienst-Mitarbeiter"])},
        "viewed_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufgerufen-Status"])},
        "vulnerability_capability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schwachstellen-Funktion"])},
        "vulnerability_health": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schwachstellen-Zustand"])},
        "vulnerability_life_event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schwachstellen-Lebensereignis"])}
      },
      "autoqa_prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA-Kategorie"])}
    },
    "date_condition_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie eine datumsbezogene Bedingung aus"])},
    "empty_name_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fügen Sie einen Namen zu Ihrem Filter hinzu"])},
    "name_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie einen Filternamen ein"])},
    "value": {
      "current_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angemeldete Person"])},
      "removed_workspace_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ehemalige Arbeitsbereichsmitglieder"])},
      "workspace_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeitsbereichsmitglieder"])}
    },
    "add": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("filterName")), " hinzufügen"])},
    "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter anwenden"])},
    "date_condition_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie sollten mindestens einen datumsbezogenen Filter anlegen"])},
    "multiple_value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optionen auswählen"])},
    "single_value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie eine Option aus"])}
  },
  "funnelgraph": {
    "na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])}
  },
  "modules": {
    "application": {
      "banners": {
        "trial_end": {
          "template_1_day": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ihr Testzeitraum endet in ", _interpolate(_named("n")), " Tag. ", _interpolate(_named("link"))])},
          "template_1_day_zd_only": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ihr Testzeitraum endet <a href=\"/admin\" target=\"_blank\" rel=\"noopener noreferrer\"><strong>in ", _interpolate(_named("n")), " Tag</strong></a>."])},
          "template_days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ihr Testzeitraum endet in ", _interpolate(_named("n")), " Tagen. ", _interpolate(_named("link"))])},
          "template_days_zd_only": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ihr Testzeitraum endet <a href=\"/admin\" target=\"_blank\" rel=\"noopener noreferrer\"><strong>in ", _interpolate(_named("n")), " Tagen</strong></a>."])},
          "template_today": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ihr Testzeitraum endet heute. ", _interpolate(_named("link"))])},
          "template_today_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Testzeitraum endet <a href=\"/admin\" target=\"_blank\" rel=\"noopener noreferrer\"><strong>heute</strong></a>."])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Richten Sie jetzt ein Abonnement ein"])}
        },
        "trial_left": {
          "days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), " Tage"])},
          "template_zd_only": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sie haben noch ", _interpolate(_named("days")), " im Testzeitraum"])}
        },
        "user_limit": {
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sie haben das Benutzerlimit für Ihren Plan erreicht. Für weitere Infos oder ein Upgrade Ihres Plans: ", _interpolate(_named("link")), "."])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klicken Sie hier"])}
        },
        "demo": {
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["verbinden Sie Ihren Help Desk"])},
          "modal_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dadurch können wir Ihre Tickets abrufen, damit Sie sie überprüfen können."])},
          "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbinden Sie Ihren Help Desk"])},
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sie verwenden Zendesk QA mit Beispieldaten. Um Beispieldaten zu löschen und Ihre eigenen Konversationen zu importieren, ", _interpolate(_named("link")), "."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beispieldaten"])}
        }
      },
      "error_cat": {
        "bullet1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopieren Sie die zusätzlichen Informationen unten"])},
        "bullet2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Machen Sie einen Screenshot der ganzen Seite, um Kontext bereitzustellen"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenden Sie sich an unser Kundensupportteam, um dieses Problem zu lösen:"])},
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Fehler ist aufgetreten"])},
        "error_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehlerreferenz:"])},
        "copy_debug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hilfreiche Informationen kopieren"])}
      },
      "navbar": {
        "settings_submenu": {
          "users_bots_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer, Bots und Arbeitsbereiche"])},
          "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer und Arbeitsbereiche"])},
          "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einstellungen"])}
        },
        "goal_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["heute"])},
        "goal_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dieser Monat"])},
        "goal_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["diese Woche"])},
        "review_goal": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), "/", _interpolate(_named("total")), " überprüft ", _interpolate(_named("period"))]), _normalize([_interpolate(_named("n")), "/", _interpolate(_named("total")), " überprüft ", _interpolate(_named("period"))]), _normalize([_interpolate(_named("n")), "/", _interpolate(_named("total")), " überprüft ", _interpolate(_named("period"))])])},
        "changelog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Was gibt‘s Neues?"])},
        "log_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abmelden"])},
        "shortcuts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tastenkombinationen"])},
        "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivität"])},
        "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zuweisungen"])},
        "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chatten Sie mit uns!"])},
        "chat_failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unser Chat konnte nicht geladen werden. (Verwenden Sie einen Werbeblocker?)"])},
        "coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coaching"])},
        "command_palette": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wechseln zu ..."])},
        "community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Community"])},
        "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversationen"])},
        "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hilfe"])},
        "homepage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Homepage"])},
        "reviews_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abgegebene Überprüfungen"])},
        "reviews_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erhaltene Überprüfungen"])},
        "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einstellungen"])},
        "support_center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wissensdatenbank"])},
        "switch_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto wechseln"])},
        "tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufgaben"])}
      },
      "toast_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Anwendung wurde aktualisiert. ", _interpolate(_named("buttonStart")), "Neu laden", _interpolate(_named("buttonEnd")), "."])},
      "pending_invites": {
        "use_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn Sie Zendesk QA als Teil Ihres Arbeitsbereichs verwenden möchten, gehen Sie wie folgt vor:"])},
        "invitation_received": {
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wir möchten Sie darauf hinweisen, dass Sie bereits eine Einladung erhalten haben, Zendesk QA als Teil eines Arbeitsbereichs zu nutzen. Wenn Sie möchten, können Sie selbstverständlich fortfahren, beachten Sie aber, dass dadurch ein ", _interpolate(_named("bold")), " für Sie erstellt wird."])},
          "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["separate Organisation"])}
        },
        "onboarding": {
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ja, ich möchte mit ", _interpolate(_named("link")), " fortfahren."])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["einem neuen Konto"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sind Sie sicher?"])},
        "invite_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("inviter")), " bittet Sie darum, ", _interpolate(_named("account")), " beizutreten"])},
        "join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beitreten"])}
      },
      "offline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Internetverbindung"])},
      "shortcuts": {
        "add_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Notiz hinzufügen"])},
        "command_palette": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schnellnavigation"])},
        "expand_editor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar-Editor fokussieren und erweitern"])},
        "hide_show_subnav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seitenleiste ausblenden oder anzeigen"])},
        "next_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zur nächsten Konversation wechseln"])},
        "open_original_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ursprüngliche Konversation öffnen"])},
        "previous_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zur vorherigen Konversation wechseln"])},
        "rate_highest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allen Kategorien die bestmögliche Bewertung geben"])},
        "rate_lowest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allen Kategorien die schlechtestmögliche Bewertung geben"])},
        "shuffle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversationen in zufälliger Reihenfolge sortieren (Neusortierung nach dem Zufallsprinzip)"])},
        "suggest_shortcuts_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teilen Sie uns mit,"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tastenkombinationen"])},
        "toggle_shortcuts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modalfenster für Shortcuts umschalten"])}
      }
    },
    "comment_editor": {
      "char_limit_reached": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sie haben die Zeichenbeschränkung von ", _interpolate(_named("charLimit")), " erreicht"])},
      "file_error_format_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bild ", _interpolate(_named("name")), " vom Typ (", _interpolate(_named("type")), ") ist nicht zulässig"])},
      "file_error_size_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bild ", _interpolate(_named("name")), " ist zu groß (5 MB zulässig)"])},
      "async_loader_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler beim Laden des Kommentar-Editors"])},
      "drag_overlay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zum Hochladen ablegen"])},
      "gif": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GIF"])},
      "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bild"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link"])},
      "link_editor": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verknüpfen"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text"])}
      },
      "template": {
        "manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorlagen verwalten"])},
        "no_templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Vorlagen hinzugefügt"])}
      }
    },
    "ratings": {
      "reason": {
        "add_root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hauptursachen hinzufügen"])},
        "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
        "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonstiges"])},
        "others_and_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonstiges, einschließlich gelöschter Gründe"])},
        "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grund für Bewertung"])}
      }
    }
  },
  "onboarding": {
    "form": {
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land"])},
      "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorname"])},
      "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachname"])},
      "number_of_employees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl von Mitarbeitern"])},
      "phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonnummer"])},
      "company_number_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir melden uns bei Ihnen, um Ihnen beim Einrichten Ihres Kontos zu helfen."])},
      "company_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unternehmensname"])},
      "company_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonnummer des Unternehmens"])},
      "first_hearing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wie sind Sie auf Zendesk QA aufmerksam geworden?"])},
      "first_hearing_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanal auswählen"])},
      "number_of_agents_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie eine Anzahl an Kundendienst-Mitarbeitern aus"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])}
    },
    "errors": {
      "fill_required_fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle erforderlichen Felder ausfüllen"])},
      "agree_to_terms_and_privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie müssen bestätigen, dass Sie unsere Allgemeinen Geschäftsbedingungen und unsere Datenschutzrichtlinie gelesen und ihnen zugestimmt haben."])}
    },
    "first_hearing_options": {
      "slack_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supportgesteuerter Slack-Kanal"])},
      "advertising": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Werbung"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E‑Mail"])},
      "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ereignis"])},
      "friend_or_colleague": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekannten oder Kollegen"])},
      "help_desk_or_manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help-Desk-Marketplace oder Account Manager"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonstiges"])},
      "referring_site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verweis-Website"])},
      "search_engine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suchmaschine (Google, Bing usw.)"])},
      "social_media": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soziale Medien"])}
    },
    "step_1": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angaben zu Ihrer Person und Ihrem Unternehmen."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontodetails"])}
    },
    "step_2": {
      "own_data_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fertig."])},
      "sample_data_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sehen Sie sich um, machen Sie sich mit der App vertraut, löschen Sie Dinge und fügen Sie neue hinzu."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto wurde erstellt. Wie geht es weiter?"])},
      "own_data_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sind Sie bereit für echte Konversationen?"])},
      "own_data_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fügen Sie eigene Daten hinzu"])},
      "sample_data_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit Explore loslegen"])},
      "sample_data_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Probieren Sie es mit Beispieldaten aus"])}
    },
    "step_3": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir können damit beginnen, Ihre Tickets zur Überprüfung abzuholen."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbinden Sie Ihr Help-Desk-Konto"])}
    },
    "cancel_flow_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich habe es mir anders überlegt."])},
    "cancel_signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrierung abbrechen"])}
  },
  "pins": {
    "modal": {
      "pin_created_with_action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Notiz erstellt. ", _interpolate(_named("buttonStart")), "Zu Notizen gehen", _interpolate(_named("buttonEnd")), "."])},
      "cta": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coaching-Notizen sind im Professional-Plan verfügbar."])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plan upgraden"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Pläne anzeigen"])}
      },
      "how_to_save_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern Sie Ihre Notizen, um sich auf die nächste 1:1- oder Coaching-Sitzung vorzubereiten."])},
      "pins_for_coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notizen für Coaching"])},
      "add_others_with": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentieren Sie oder fügen Sie andere Benutzer mit ", "@", " hinzu"])},
      "conversation_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Konversation wurde gelöscht"])},
      "create_new_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Notiz erstellen"])},
      "create_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notiz erstellen"])},
      "csat_dashboard_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
      "edit_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notiz bearbeiten"])},
      "how_pins_work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funktionsweise von Notizen"])},
      "no_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie sind nicht zum Anzeigen dieser Konversation berechtigt"])},
      "pin_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karte anheften"])},
      "pin_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversation anheften"])},
      "pin_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard anheften"])},
      "pin_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notiz gespeichert"])},
      "pin_this_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Karte anheften"])},
      "pin_this_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Konversation anheften"])},
      "pin_this_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieses Dashboard anheften"])},
      "pin_under": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unter einem Benutzer anheften"])},
      "search_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie einen Benutzer aus"])},
      "who_can_see_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nur Sie können sehen, was Sie angeheftet haben. Um die angehefteten Elemente zu teilen, erwähnen Sie die gewünschten Benutzer mit ", "@", "."])}
    },
    "comments": {
      "are_invited": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("agentsNames")), " ist jetzt zu dieser Konversation eingeladen"]), _normalize([_interpolate(_named("agentsNames")), " sind jetzt zu dieser Konversation eingeladen"])])},
      "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 Kommentar"]), _normalize([_interpolate(_named("n")), " Kommentare"])])},
      "mentioned_participants": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Sie haben ", _interpolate(_named("participants")), " erwähnt, diese sind aber nicht in dieser Konversation."]), _normalize(["Sie haben ", _interpolate(_named("participants")), " erwähnt, diese sind aber nicht in dieser Konversation."])])},
      "participants": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " Teilnehmer"]), _normalize([_interpolate(_named("n")), " Teilnehmer"]), _normalize([_interpolate(_named("n")), " Teilnehmer"])])},
      "remove_user_body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Möchten Sie ", _interpolate(_named("agentName")), " daran hindern, auf diese Notiz zuzugreifen?"])},
      "do_nothing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Aktion durchführen"])},
      "do_nothing_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laden Sie sie nicht zu dieser Konversation ein"])},
      "editor_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar hinzufügen"])},
      "invite_them": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie einladen"])},
      "invite_them_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laden Sie sie zu dieser Konversation ein"])},
      "remove_user_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer entfernen"])},
      "thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thread"])}
    },
    "folder_content": {
      "delete_cannot_be_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Schritt kann nicht rückgängig gemacht werden."])},
      "delete_pin_with_comments_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dadurch werden auch alle Kommentare unter dieser Notiz gelöscht."])},
      "new_messages": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " neue Nachrichten"]), _normalize([_interpolate(_named("n")), " neue Nachricht"]), _normalize([_interpolate(_named("n")), " neue Nachrichten"])])},
      "no_pins_body_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nur Sie können Ihre Notizen sehen. Um Notizen zu teilen, müssen Sie jemanden in den Kommentaren mit ", "@", " markieren."])},
      "pinned": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentName")), " hat ", _interpolate(_named("pinType")), " angeheftet"])},
      "pinned_2_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ein Dashboard"])},
      "pinned_2_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eine Konversation"])},
      "pinned_2_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["einen Hinweis"])},
      "you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie"])},
      "cta": {
        "admin_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Führen Sie ein Upgrade durch, um Coaching-Notizen zu erhalten"])},
        "no_pins_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coaching-Notizen sind im Professional-Plan verfügbar."])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coaching-Notizen sind im Professional-Plan verfügbar"])},
        "no_pins_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgraden, um die Funktion zu erhalten"])}
      },
      "no_pins_body_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bereiten Sie sich auf Ihre 1:1-Gespräche vor oder notieren Sie etwas für später."])},
      "delete_pin_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dadurch werden die Notiz und alle hinzugefügten Kommentare endgültig gelöscht."])},
      "delete_pin_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notiz löschen"])},
      "delete_pin_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notiz löschen"])},
      "folder_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name des Ordners"])},
      "no_pins_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heften Sie Elemente an, um später auf sie zurückzukommen"])},
      "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antworten"])},
      "sort_options": {
        "new_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neueste zuerst"])},
        "old_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Älteste zuerst"])}
      }
    },
    "no_pins_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern Sie einen interessanten Dashboard-Trend oder eine Konversation als Vorbereitung für Ihre 1:1-Gespräche oder notieren Sie sie für später."])},
    "no_pins_subtitle_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellen Sie eine Notiz, indem Sie in der Konversations- oder Dashboard-Ansicht oben auf die Schaltfläche bzw. das Stecknadelsymbol klicken."])},
    "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anheften"])},
    "no_pins_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Notizen erstellt"])},
    "no_results_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Keine Ergebnisse zu \"", _interpolate(_list(0)), "\""])},
    "no_results_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versuchen Sie es mit anderen Schlüsselwörtern oder überprüfen Sie, ob Tippfehler vorliegen."])},
    "pin_manual_button": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neu"])},
      "tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Notiz erstellen"])}
    },
    "sidebar": {
      "messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachrichten"])},
      "people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personen"])},
      "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suchen nach Benutzer, Schlüsselwort ..."])},
      "sort_option_alphabetically": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alphabetisch"])},
      "sort_option_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zuletzt erstellt"])},
      "sort_option_edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zuletzt bearbeitet"])},
      "sort_option_most_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meiste Notizen"])}
    }
  },
  "profile": {
    "goal": {
      "form": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Ich möchte ", _interpolate(_named("n")), " Konversation ", _interpolate(_named("schedule")), " überprüfen"]), _normalize(["Ich möchte ", _interpolate(_named("n")), " Konversationen überprüfen ", _interpolate(_named("schedule"))])])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ziel aktualisiert"])},
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ziel festlegen"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können ein persönliches Ziel für die Anzahl der Konversationen festlegen, die Sie überprüfen möchten."])},
      "period_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jeden Tag"])},
      "period_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jeden Monat"])},
      "period_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jede Woche"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ziel für Überprüfungen"])}
    },
    "navigation": {
      "title_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PERSÖNLICH"])},
      "logins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmeldung"])},
      "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allgemein"])},
      "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benachrichtigungen"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kennwort"])},
      "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorlagen"])}
    },
    "notifications": {
      "sections": {
        "praise": {
          "loading_channels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laden von Kanälen"])},
          "channel_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanal auswählen"])},
          "comments_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nur Umfragefeedback mit Kommentaren teilen"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unterstützen Sie Ihre Kollegen, indem Sie ihre außergewöhnliche Servicequalität und ihr herausragendes Kundenfeedback hervorheben."])},
          "enabled_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positives Umfragefeedback auf Slack teilen"])},
          "no_channel_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Channels gefunden"])},
          "threshold_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatisches Teilen von Feedback mit Werten über"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lob-Bot-Integrationen"])}
        },
        "reminders": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie erhalten zu Beginn der Woche eine Erinnerung, wenn Sie noch keine Überprüfungen vorgenommen haben."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erinnerungen"])},
          "weekly_reminder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wöchentliche Erinnerung an Überprüfungen"])}
        },
        "slack": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erhalten Sie Benachrichtigungen über neue Überprüfungen, Antworten, Erwähnungen und Streitfälle in Slack."])},
          "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentare"])},
          "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Streitfälle"])},
          "mentions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erwähnungen"])},
          "ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewertungen"])},
          "thread_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thread-Antworten"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack-Integrationen"])}
        },
        "notifications": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lassen Sie sich über neue Überprüfungen, Antworten, Erwähnungen und Streitfälle informieren."])},
          "disputes_related_to_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Streitfälle, die mit mir zusammenhängen"])},
          "mentions_in_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erwähnungen in Kommentaren"])},
          "new_received_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue erhaltene Überprüfungen"])},
          "replies_in_threads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antworten in Threads"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benachrichtigungen"])}
        },
        "reports": {
          "content_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bericht mit Statistiken"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empfangen Sie Berichte zu abgegebenen und erhaltenen Überprüfungen."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berichte"])}
        }
      },
      "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["täglich"])},
      "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["monatlich"])},
      "weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wöchentlich"])},
      "feedback_steps": {
        "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Täglich"])},
        "hours_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle 3 Stunden"])},
        "hours_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle 6 Stunden"])},
        "instant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sofort"])},
        "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monatlich"])},
        "off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aus"])},
        "weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wöchentlich"])}
      },
      "locked_notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Kontoadministrator hat die Benachrichtigungseinstellungen gesperrt."])},
      "section_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E‑Mail"])},
      "section_slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])},
      "select_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeitraum auswählen"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persönliche Benachrichtigungen"])}
    },
    "logins": {
      "active": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("email")), " (aktiv)"])},
      "add_new_social_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Social Media-Anmeldung zu diesem Konto hinzufügen:"])},
      "delete_confirm_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn Sie eine aktive Anmeldemethode entfernen, werden Sie abgemeldet. Sie können sich mit Ihrer alternativen E-Mail-Adresse erneut anmelden."])},
      "delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmeldemethode entfernen"])},
      "google_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google wurde erfolgreich als Anmeldeoption hinzugefügt"])},
      "login_subsection_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmeldeoptionen"])},
      "slack_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack wurde erfolgreich als Anmeldeoption hinzugefügt"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmeldung"])},
      "add_google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google hinzufügen"])},
      "add_slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack hinzufügen"])},
      "create_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kennwort erstellen"])},
      "delete_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sind Sie sicher?"])},
      "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entfernen"])},
      "method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Methode"])},
      "password_reset_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kennwort zurücksetzen"])},
      "password_reset_intro": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Eine E-Mail wird an ", _interpolate(_named("email")), " gesendet, damit Sie Ihr Kennwort zurücksetzen können."])},
      "password_subsection_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kennwort"])}
    },
    "password": {
      "primary_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["primäre E-Mail-Adresse"])},
      "confirmation_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prüfen Sie, ob sich unsere Bestätigungs-E-Mail in Ihrem Posteingang befindet."])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magic Link"])},
      "auth_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kennwort"])},
      "change_email_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Um das zu ändern, ändern Sie zuvor Ihre ", _interpolate(_named("link")), "."])},
      "email_associated_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Die E-Mail-Adresse ", _interpolate(_named("email")), " wird mit diesem Kennwort verknüpft."])},
      "google_oauth_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google"])},
      "oauth_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])}
    },
    "general": {
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil aktualisiert"])},
      "avatar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profilbild"])},
      "avatar_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die besten Ergebnisse erzielen Sie, wenn Sie ein quadratisches Foto hochladen (256 x 256 Pixel oder mehr)"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primäre E-Mail-Adresse"])},
      "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprache"])},
      "start_of_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anfang der Woche"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persönliche Einstellungen"])}
    },
    "templates": {
      "create_template": {
        "template_view_options_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie mindestens eine Option aus"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inhalt der Vorlage"])},
        "name_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorlagenname"])},
        "show_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorlage anzeigen in"])},
        "template_content_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inhalt der Vorlage hinzufügen"])},
        "template_name_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fügen Sie einen Namen für die Vorlage hinzu"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorlage erstellen"])}
      },
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorlage erstellen"])},
      "default": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellen Sie Kommentarvorlagen, um die Überprüfung, das Anheften von Elementen und das Coaching zu beschleunigen."])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "used_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwendet in"])}
      },
      "delete_template": {
        "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorlage löschen"])},
        "delete_confirmation_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Vorlage endgültig löschen?"])}
      },
      "edit_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorlage bearbeiten"])},
      "empty_state": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellen Sie Kommentarvorlagen, um die Überprüfung, das Anheften von Elementen und das Coaching zu beschleunigen."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Vorlagen erstellt"])}
      },
      "toast": {
        "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentarvorlage erstellt"])},
        "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentarvorlage gelöscht"])},
        "saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentarvorlage gespeichert"])}
      },
      "types": {
        "coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coaching"])},
        "pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notizen"])},
        "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überprüfungen und Streitfälle"])}
      }
    }
  },
  "provisioning": {
    "errors": {
      "account_not_found": {
        "body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Um Zugriff zu erhalten, wenden Sie sich an den ", _interpolate(_named("link")), "."])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundensupport"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kein Konto gefunden"])}
      },
      "account_not_migrated": {
        "body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Verwenden Sie vorerst ", _interpolate(_named("link")), ", um auf Zendesk QA zuzugreifen."])},
        "return_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zu Zendesk zurückkehren"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto nicht migriert"])}
      }
    }
  },
  "review": {
    "rating_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])}
  },
  "routes": {
    "dashboard": {
      "label": {
        "bot_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BotQA"])},
        "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zuweisungen"])},
        "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA"])},
        "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrierung"])},
        "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorien"])},
        "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Streitfälle"])},
        "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übersicht"])},
        "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewertungen"])},
        "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umfragen"])}
      },
      "keyword": {
        "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
        "metrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metriken"])},
        "scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Werte"])},
        "statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistiken"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])}
    },
    "conversations": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversationen"])},
      "keyword": {
        "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrierung"])},
        "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversationen"])},
        "filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])},
        "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überprüfung"])}
      }
    },
    "activity": {
      "breadcrumb": {
        "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Streitfälle"])},
        "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abgegeben"])},
        "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empfangen"])}
      },
      "keyword": {
        "my_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["meine Kommentare"])},
        "my_disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["meine Streitfälle"])},
        "my_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["meine Überprüfungen"])}
      },
      "label": {
        "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivität"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle"])},
        "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentare"])},
        "reactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reaktionen"])},
        "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empfangen"])},
        "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewertungen"])},
        "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestartet"])},
        "survey_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umfragefeedback"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivität"])}
    },
    "assignments": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zuweisungen"])}
    },
    "coaching": {
      "label": {
        "pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notizen"])},
        "quizzes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz"])},
        "sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sitzungen"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coaching"])}
    },
    "profile_settings": {
      "breadcrumb": {
        "personal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persönlich"])},
        "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorlagen"])}
      },
      "keyword": {
        "create_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kennwort erstellen"])},
        "credentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmeldedaten"])},
        "e_mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
        "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["google"])},
        "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmeldung"])},
        "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zurücksetzen"])},
        "review_goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ziel für Überprüfungen"])},
        "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])},
        "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorlagen"])},
        "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer"])}
      },
      "label": {
        "create_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorlage erstellen"])},
        "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allgemein"])},
        "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ziel"])},
        "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelden"])},
        "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benachrichtigungen"])},
        "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorlagen"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einstellungen"])}
    },
    "settings": {
      "breadcrumb": {
        "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto"])},
        "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA"])},
        "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnement"])}
      },
      "keyword": {
        "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account"])},
        "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abrechnung"])},
        "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firma"])},
        "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help Desk"])},
        "integrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrationen"])},
        "organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisation"])},
        "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlung"])},
        "seats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lizenzen"])},
        "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einstellungen"])},
        "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnement"])}
      },
      "label": {
        "authentications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentifizierungen"])},
        "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA"])},
        "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbindungen"])},
        "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allgemein"])},
        "new_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Kategorie"])},
        "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benachrichtigungen"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard"])},
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spotlight"])},
        "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnement"])},
        "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umfragen"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einstellungen"])}
    }
  },
  "settings": {
    "auto_qa": {
      "conflicting_error_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Konflikt stehende Nachricht gefunden"])},
      "conflicting_error_msg_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ändern oder entfernen Sie eine der in Konflikt stehenden Nachrichten"])},
      "dynamic_content_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Verwenden Sie das ", _interpolate(_named("placeholder")), "-Symbol um sensible Daten von AutoQA auszuschließen. Beispiel: Anstatt „Tschüss – John“ können Sie „Tschüss – ", _interpolate(_named("placeholder")), "“ verwenden, um jeden beliebigen Namen zu repräsentieren."])}
    },
    "autoqa": {
      "create": {
        "phrase_row_limit_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Maximal ", _interpolate(_named("limit")), " Zeilen"])}
      }
    },
    "categories": {
      "create": {
        "apply_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regel anwenden auf"])},
        "category_limit_reached": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben das Limit von 10 angepassten Kategorien erreicht."])},
        "category_limit_reached_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sie haben das Limit von ", _interpolate(_named("limit")), " angepassten Kategorien erreicht"])},
        "conditions": {
          "add_condition_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedingung hinzufügen"])},
          "add_row_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeile hinzufügen"])},
          "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["und"])},
          "delete_condition_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedingung löschen"])},
          "if_agent_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn Agentennachricht"])},
          "if": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn"])},
          "otherwise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andernfalls Wert"])},
          "then": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dann Wert"])}
        },
        "create_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard-Kategorie erstellen"])},
        "description": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description for reviewers (optional)"])}
        },
        "dynamic_content_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwenden Sie „/“, um eine Variable hinzuzufügen"])},
        "filters": {
          "call_direction": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Richtungen"])}
          },
          "agent_message": {
            "errors": {
              "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitarbeiter-Nachricht darf nicht leer sein"])},
              "too_long": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachricht darf nicht länger als 300 Zeichen sein"])}
            },
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text"])}
          },
          "source_type": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Quellen"])}
          },
          "ticket_channel": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Kanäle"])}
          },
          "ticket_tags": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Stichwörter"])}
          }
        },
        "rule_radio": {
          "all_conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Konversationen"])},
          "description_narrow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grenzen Sie ein, wo die Regel Anwendung findet."])},
          "specific_conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestimmte Konversationen"])}
        },
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spotlight-Insight erstellen"])},
        "spotlight_filter": {
          "errors": {
            "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitarbeiter-Nachricht darf nicht leer sein"])}
          }
        },
        "spotlight_limit_reached": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben das Limit von 10 angepassten Insights erreicht."])},
        "spotlight_limit_reached_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sie haben das Limit von ", _interpolate(_named("limit")), " angepassten Insights erreicht"])},
        "spotlight_name": {
          "errors": {
            "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie den Insights-Namen ein"])}
          },
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insights-Name"])}
        },
        "title_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie erstellen"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insights-Typ"])},
        "type_negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negativ"])},
        "type_neutral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neutral"])},
        "type_positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positiv"])},
        "category_created": {
          "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zu Arbeitsbereichseinstellungen gehen"])},
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht jetzt"])},
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um diese Bewertungskategorie zu verwenden, gehen Sie zu den Scorecard-Einstellungen Ihres Arbeitsbereichs und fügen Sie sie zu einer Scorecard Ihrer Wahl hinzu."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewertungskategorie erstellt"])}
        },
        "description_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewerten Sie geschlossene Konversationen automatisch durch die Identifizierung von bestimmten Schlüsselwörtern oder Phrasen."])},
        "description_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funktioniert nicht bei Sprachtranskriptionen."])},
        "limit_reached": {
          "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
          "content": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Super! ✋ Sie haben die automatischen Bewertungskategorien mit ", _interpolate(_named("limit")), " ausgeschöpft. Sie sind ein wahrer Meister der Kategorisierung! 🧙"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Höchstwert für Kategorien erreicht"])}
        },
        "multiple_errors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stellen Sie sicher, dass alle Felder richtig ausgefüllt sind"])},
        "name": {
          "errors": {
            "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legen Sie einen Namen für die Bewertungskategorie fest"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategoriename"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Bewertungskategorie"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Bewertungskategorie"])}
      },
      "custom_category": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewerten Sie geschlossene Konversationen automatisch durch die Identifizierung von bestimmten Schlüsselwörtern oder Phrasen. Funktioniert nicht bei Sprachtranskriptionen."])},
        "description_transcriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeigen Sie geschlossene Konversationen anhand bestimmter Schlüsselwörter oder Phrasen automatisch an. Funktioniert nur bei Protokollen."])},
        "description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewerten Sie Konversationen automatisch durch die Identifizierung von bestimmten Schlüsselwörtern oder Phrasen sowohl in Agenten- als auch in Bot-Nachrichten."])},
        "info_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um diese Bewertungskategorie zu verwenden, gehen Sie zu den Scorecard-Einstellungen Ihres Arbeitsbereichs und fügen Sie sie zu einer Scorecard hinzu."])}
      },
      "description": {
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewerten Sie Konversationen automatisch anhand bestimmter Schlüsselwörter oder Phrasen in Nachrichten."])}
      },
      "edit": {
        "available_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verfügbar für"])},
        "insight_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insights-Einrichtung"])},
        "insight_setup_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Insight wird basierend auf festgelegten Kriterien in Spotlight angezeigt."])},
        "scorecard_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard-Einrichtung"])},
        "scorecard_setup_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Kategorie kann in Scorecards verwendet werden, die auf bestimmten Kriterien basieren."])},
        "tags": {
          "bot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot"])},
          "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat"])},
          "coming_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demnächst verfügbar"])},
          "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["angepasst"])},
          "predefined_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vordefiniert"])},
          "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard"])},
          "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spotlight"])},
          "voice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voice"])}
        },
        "save_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Änderungen speichern"])},
        "saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Änderungen gespeichert"])}
      },
      "ignore_list": {
        "modal": {
          "input_tip_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipp:"])},
          "snippet_editor_help_first": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Click on the ", _interpolate(_named("placeholder")), " icon to exclude names, company details, or any other sensitive information during AutoQA's closing checks."])},
          "snippet_editor_help_second": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["For example, instead of 'Take care, John' you can use 'Take care, ", _interpolate(_named("placeholder")), "' to represent any name."])},
          "snippet_editor_help_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klicken Sie auf das Symbol ", "{··}", ", um Namen, Firmendetails und andere vertrauliche Informationen von den AutoQA-Abschlussprüfungen auszuschließen."])},
          "closing": {
            "add_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genehmigten Abschluss hinzufügen"])},
            "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genehmigten Abschluss bearbeiten"])},
            "input_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genehmigter Abschluss"])}
          },
          "grammar": {
            "add_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausnahmen hinzufügen"])},
            "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausnahme bearbeiten"])},
            "input_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausnahme"])}
          },
          "greeting": {
            "add_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genehmigte Begrüßung hinzufügen"])},
            "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genehmigte Begrüßung bearbeiten"])},
            "input_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genehmigte Begrüßung"])}
          },
          "input_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durch Zeilenumbrüche getrennte Wörter hier einfügen"])},
          "input_tip_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drücken Sie nach jedem Wort die Eingabetaste, um mit einem neuen Eintrag zu beginnen"])},
          "language_picker_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anwendbare Sprache"])},
          "snippet_editor_help_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beispiel: Anstatt „Tschüss – John“ können Sie „Tschüss – ", "{··}", "“ verwenden, um jeden beliebigen Namen zu repräsentieren."])},
          "snippet_editor_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platzhalter für dynamische Inhalte"])},
          "textarea_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausnahmen"])}
        },
        "grammar": {
          "toast_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 Ausnahme hinzugefügt"]), _normalize([_interpolate(_named("count")), " Ausnahmen hinzugefügt"])])},
          "phrase_exists": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Ausnahme bereits hinzugefügt"]), _normalize(["Bereits hinzugefügte Ausnahmen: ", _interpolate(_named("phrases"))])])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausnahme hinzufügen"])},
          "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Ausnahmen gefunden"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Ausnahmen hinzugefügt"])},
          "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausnahme (Klein-/Großschreibung relevant)"])},
          "save_button": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Ausnahmen hinzufügen"]), _normalize(["1 Ausnahme hinzufügen"]), _normalize([_interpolate(_named("count")), " Ausnahmen hinzufügen"])])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Wort oder eine Phrase suchen"])},
          "toast_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausnahme gespeichert"])}
        },
        "language": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprache"])},
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Sprachen"])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nach Sprache suchen"])}
        },
        "closing": {
          "phrase_exists": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Abschluss wurde bereits hinzugefügt"]), _normalize(["Bereits hinzugefügte Abschlüsse: ", _interpolate(_named("phrases"))])])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abschluss hinzufügen"])},
          "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine genehmigten Abschlüsse gefunden"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine genehmigten Abschlüsse"])},
          "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abschluss"])},
          "save_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abschluss hinzufügen"])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einen Abschluss suchen"])},
          "toast_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abschluss hinzugefügt"])},
          "toast_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abschluss gespeichert"])}
        },
        "duplicate_lines": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Formular enthält doppelte Einträge: ", _interpolate(_list(0))])},
        "greeting": {
          "phrase_exists": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Begrüßung bereits hinzugefügt"]), _normalize(["Bereits hinzugefügte Begrüßungen: ", _interpolate(_named("phrases"))])])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Begrüßung hinzufügen"])},
          "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine genehmigten Begrüßungen gefunden"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine genehmigten Begrüßungen"])},
          "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruß"])},
          "save_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Begrüßung hinzufügen"])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine Begrüßung suchen"])},
          "toast_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Begrüßung hinzugefügt"])},
          "toast_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Begrüßung gespeichert"])}
        },
        "add_exemption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausnahme hinzufügen"])},
        "delete_exemption_confirm": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Ausnahme löschen"]), _normalize(["Ausnahmen löschen"])])},
        "delete_exemption_title": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Diese Ausnahme endgültig löschen?"]), _normalize(["Diese Ausnahmen endgültig löschen?"])])},
        "edit_exemption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausnahme bearbeiten"])},
        "long_input": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Mindestens eine Ausnahme ist länger als ", _interpolate(_list(0)), " Zeichen"])},
        "long_input_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("phraseLength")), "/", _interpolate(_named("maxPhraseLength")), " Zeichen"])},
        "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualisiert"])},
        "updated_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualisiert von"])}
      },
      "snippets": {
        "dynamic_content": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Variable"])}
        }
      },
      "tab_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorien"])},
      "closing": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Definieren Sie eigene Grußformeln, die zu Ihrer Marke und Ihren Servicestandards passen. Wenn AutoQA hinzugefügt wird, werden nur genehmigte Abschlüsse erkannt. Die vorgenommenen Änderungen werden auf zukünftige Konversationen angewendet."])},
        "limit_reached_popup": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben den Höchstwert von 100 genehmigten Abschlüssen erreicht. Wenn Sie weitere hinzufügen möchten, sollten Sie Ihre vorhandenen Abschlüsse überprüfen und verfeinern."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Höchstwert für genehmigte Abschlüsse erreicht"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genehmigte Abschlüsse"])}
      },
      "grammar": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wörter und Phrasen, die AutoQA nicht als Rechtschreib- oder Grammatikfehler einstufen soll. Die vorgenommenen Änderungen werden auf zukünftige Konversationen angewendet."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste ignorieren"])}
      },
      "greeting": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legen Sie eigene Begrüßungen fest, die zu Ihrer Marke und Ihren Servicestandards passen. Wenn AutoQA hinzugefügt wird, werden nur genehmigte Begrüßungen erkannt. Die vorgenommenen Änderungen werden auf zukünftige Konversationen angewendet."])},
        "limit_reached_popup": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben den Höchstwert von 100 genehmigten Begrüßungen erreicht. Wenn Sie weitere hinzufügen möchten, sollten Sie Ihre vorhandenen Begrüßungen überprüfen und verfeinern."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Höchstwert für genehmigte Begrüßungen erreicht"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genehmigte Begrüßungen"])}
      },
      "delete_category_template": {
        "cancel_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht jetzt"])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie löschen"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Bewertungskategorie endgültig löschen?"])}
      },
      "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorieliste umschalten"])}
    },
    "category": {
      "delete": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gelöschte Kategorien werden in neuen Konversationen und Filtern nicht angezeigt. Historische Daten in Dashboards und Konversationen bleiben intakt."])},
        "description_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Kategorie wird aus allen Scorecards entfernt, zu denen sie hinzugefügt wurde."])},
        "failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler beim Löschen der Scorecard-Kategorie"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard-Kategorie gelöscht"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard-Kategorie löschen"])}
      }
    },
    "company": {
      "consents": {
        "details": {
          "cancel_open_ai": {
            "categories": {
              "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA-Kategorien"])},
              "main": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vom KI-Modell unterstützte ", _interpolate(_named("highlight")), " bleiben in der Scorecard erhalten, werden aber nicht automatisch bewertet"])}
            },
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durch das Deaktivieren der LLM-Integration wird die AutoQA-Funktion nicht vollständig deaktiviert. In diesem Fall geschieht Folgendes:"])},
            "historical_data": {
              "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historische Daten"])},
              "main": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("highlight")), " verbleiben im AutoQA-Dashboard"])}
            },
            "root_causes": {
              "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manuell hinzugefügte Ursachen"])},
              "main": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("highlight")), " bleiben bestehen, aber alle LLM-basierten Ursachen werden entfernt"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LLM-basierte AutoQA deaktivieren"])},
            "turn_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deaktivieren"])}
          },
          "open_ai": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA nutzt KI und große Sprachmodelle (LLM), um bestimmte Kategorien automatisch zu bewerten. Ihre Daten werden im Rahmen einer strikten Datenverarbeitungsvereinbarung (Data Processing Agreement, DPA) verarbeitet und keinesfalls zum Trainieren von Modellen verwendet."])},
            "support_link_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Infos zu generativer KI"])}
          },
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["AutoQA verwendet den Microsoft Azure OpenAI Service für ", _interpolate(_named("automatically_scoring_categories")), ". Ihre Daten werden ausschließlich im Rahmen der Auftragsdatenverarbeitungsvereinbarung zum Zweck der AutoQA-Bereitstellung für Sie und ", _interpolate(_named("never_used_for_training_models")), " verarbeitet."])},
          "automatically_scoring_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["automatisches Bewerten einiger Kategorien"])},
          "never_used_for_training_models": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nie zum Trainieren von OpenAI-Modellen"])}
        },
        "open_ai": {
          "toast_success": {
            "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LLM-basierte AutoQA aktiviert"])},
            "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LLM-basierte AutoQA deaktiviert"])}
          }
        },
        "open_ai_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LLM-basierte AutoQA"])},
        "open_ai_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatisches Scoring mit KI in AutoQA"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datenverarbeitung von auf MS Azure basierenden AutoQA-Kategorien"])},
        "toast_success": {
          "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zustimmung zu Microsoft Azure widerrufen"])},
          "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zustimmung zu Microsoft Azure erteilt. Konversationen werden in Kürze automatisch bewertet."])}
        },
        "withdraw_dialog": {
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können AutoQA weiterhin nutzen, in diesem Fall passiert jedoch Folgendes:"])},
          "footer": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beim Deaktivieren des MS Azure OpenAI-Dienstes wird die Funktion \"AutoQA\" nicht deaktiviert."])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dies ist in der Scorecard-Kategorie-Ansicht möglich."])}
          },
          "message": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vom Modell unterstützte AutoQA-Kategorien bleiben in der Scorecard erhalten, werden aber nicht automatisch bewertet."])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die manuell hinzugefügten Hauptursachen bleiben erhalten. Alle vom Modell generierten Hauptursachen werden entfernt."])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historische Daten werden weiterhin auf dem AutoQA-Dashboard angezeigt."])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deaktivieren Sie die Datenverarbeitung durch den MS Azure OpenAI-Dienst"])}
        },
        "give_consent_dialog": {
          "message": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Größere Auswahl an Auto-QA-Kategorien."])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sofort einsatzbereiter mehrsprachiger Support."])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schnelles Hinzufügen neuer Kategorien."])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbesserte Bewertungsgenauigkeit. GPT-3.5 zeigt Kontextbewusstsein auf menschlicher Ebene."])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profitieren Sie von den neuesten Fortschritten in der KI-Technologie."])}
          },
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das erhalten Sie:"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivieren Sie die Datenverarbeitung durch den MS Azure OpenAI-Dienst"])}
        },
        "ms_azure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Microsoft Azure"])},
        "status_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zustimmung erteilt"])},
        "status_not_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Zustimmung erteilt"])}
      },
      "default_workspace_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle neuen Benutzer werden diesem Arbeitsbereich zugewiesen"])},
      "default_workspace_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standard-Arbeitsbereich"])},
      "time_format_12": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["12-Stunden-Format (", _interpolate(_named("time")), ")"])},
      "time_format_24": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["24-Stunden-Format (", _interpolate(_named("time")), ")"])},
      "time_format_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeitformat"])},
      "week_start_monday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon."])},
      "week_start_sunday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["So."])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unternehmensprofil wurde aktualisiert"])},
      "week_start_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anfang der Woche"])}
    },
    "connections": {
      "add_custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angepasste Integration"])},
      "amazon_connect": {
        "access_key_id": {
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Zugriffsschlüssel-ID und der Geheimschlüssel sind in der Regel unter „Zugriffsverwaltung“ > „Benutzer“ zu finden"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AWS-Zugriffsschlüssel-ID"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["z. B. AKIAIOSFODNN7EXAMPLE"])}
        },
        "add_checkbox": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amazon Connect hinzufügen"])}
        },
        "cancel_changing_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ändern des geheimen Zugriffsschlüssels abbrechen"])},
        "change_key": {
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wert des geheimen Zugriffsschlüssels ändern"])}
        },
        "region": {
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Verbindung ermöglicht die Definition einer einzigen Region, die in der Regel unter „Standort“ zu finden ist"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AWS-Region"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["z. B. eu-central-1"])}
        },
        "secret_access_key": {
          "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fügen Sie einen Geheimschlüssel hinzu"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geheimer AWS-Zugriffsschlüssel"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["z. B. wJalrXUtnFEMI/K7MDENG/bPxRfiCYEXAMPLEKEY"])}
        },
        "section_explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle relevanten Informationen finden Sie in Amazon Connect."])},
        "storage_location": {
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In der Regel unter den Amazon Connect-Instanzeinstellungen auf der Seite zum Speicherplatz für Daten zu finden"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speicherort der Anrufaufzeichnungen"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["z. B.. amazon-connect-123456/connect/instance-name/CallRecordings"])}
        },
        "validation_error": {
          "generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beim Hinzufügen von Amazon Connect ist ein Fehler aufgetreten. Überprüfen Sie, ob alle Felder richtig ausgefüllt sind, und versuchen Sie es erneut."])},
          "location_format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Speicherort sollte im Format eines Buckets/Ordners vorliegen und durch einen Schrägstrich getrennt sein."])}
        }
      },
      "contact_to_add": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wenden Sie sich an ", _interpolate(_named("link")), ", um eine Verbindung hinzuzufügen."])},
      "contact_to_add_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundensupport"])},
      "first_sync_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Ihre Zendesk-Tickets werden automatisch synchronisiert. Sie können ausgewählte Tickets ausschließen."])},
      "genesys_region": {
        "ap-northeast-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asien-Pazifik (Tokio)"])},
        "ap-northeast-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asien-Pazifik (Seoul)"])},
        "ap-northeast-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asien-Pazifik (Osaka)"])},
        "ap-south-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asien-Pazifik (Mumbai)"])},
        "ap-southeast-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asien-Pazifik (Sydney)"])},
        "ca-central-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanada (Zentral)"])},
        "eu-central-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Europa (Frankfurt)"])},
        "eu-central-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Europa (Zürich)"])},
        "eu-west-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Europa (Irland)"])},
        "eu-west-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Europa (London)"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serverregion"])},
        "me-central-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naher Osten (VAE)"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serverregion auswählen"])},
        "sa-east-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Südamerika (São Paulo)"])},
        "us-east-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["USA Ost (Virginia)"])},
        "us-east-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["USA Ost 2 (Ohio)"])},
        "us-west-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["USA West (Oregon)"])}
      },
      "integration_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbindung bereits hinzugefügt"])},
      "start_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbindung herstellen"])},
      "sync_only_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nur Konversationen synchronisieren, die"])},
      "channel_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanal 1, Kanal 2"])},
      "connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbindung"])},
      "connection_unique_id": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Die eindeutige ID dieser Verbindung lautet ", _interpolate(_named("tokenId"))])},
      "data_retention_period": {
        "months_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 Monat"])},
        "months_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 Jahr"])},
        "months_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 Monate"])},
        "months_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6 Monate"])},
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inaktive Konversationen werden nach dem ausgewählten Aufbewahrungszeitraum gelöscht. Eingereichte Überprüfungen bleiben in der Aktivitäts- und Dashboard-Ansicht sichtbar."])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufbewahrungsfrist"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufbewahrungszeitraum auswählen"])}
      },
      "deletion_confirmation": {
        "action_irreversible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinweis: Diese Aktion kann nicht rückgängig gemacht werden."])},
        "removed_data_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mir ist klar, dass dadurch alle Help Desk-bezogenen Daten für diese Verbindung aus Zendesk QA entfernt werden, einschließlich Konversationen, Felder und Stichwörter."])},
        "reviews_not_affected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eingereichte Überprüfungen sind nicht betroffen und in bestimmten Ansichten weiterhin sichtbar."])}
      },
      "group_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppe 1, Gruppe 2"])},
      "include": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einschließen"])},
      "integration_in_beta_warning": {
        "active_development": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aktiven Entwicklung"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Diese Integration ist noch in der ", _interpolate(_named("activeDevelopment")), ". Einige Details fehlen möglicherweise (wie Anhänge oder angepasste Felder)."])}
      },
      "mailbox_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postfach 1, Postfach 2"])},
      "tag_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stichwort 1, Stichwort 2"])},
      "api_token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API-Token"])},
      "api_token_secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API-Token-Geheimnis"])},
      "blacklist_instructions": {
        "freshdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie finden Ticketfeldnamen in Freshdesk unter „Einstellungen“ → „Ticketfelder“."])},
        "help_scout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie finden Custom Field Names in Help Scout unter „Settings“ → „Custom fields“."])},
        "kustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie finden Namen von Klass-Konversationen in Kustomer unter „Einstellungen“ → „Klasses“ → „Konversation“"])},
        "live_agent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie finden angepasste Feldnamen in Live Agent unter „Einstellungen“ → „Angepasste Felder“"])},
        "wix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie finden angepasste Feldnamen in Wix unter „Einstellungen“ → „Angepasste Felder“"])},
        "zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie Feld-IDs durch Kommas getrennt an. Sie finden diese IDs in Zendesk unter „Admin“ → „Ticketfelder“."])}
      },
      "connection_added": {
        "next_step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbinden Sie einige Ihrer Help-Desk-Benutzer, um die ersten Konversationen zu überprüfen. Keine Sorge, diese werden von uns erst benachrichtigt, wenn Sie sie einladen, Mitglied im Arbeitsbereich zu werden."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolgreich integriert"])},
        "add_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitglieder hinzufügen"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nun starten wir das Abrufen der Konversationen."])}
      },
      "connection_domain": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Domäne: ", _interpolate(_list(0))])},
      "connection_helpdesk_identifier": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Help Desk-ID: ", _interpolate(_list(0))])},
      "could_not_authenticate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Verbindung konnte nicht authentifiziert werden"])},
      "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eingerichtet. Das ist Ihre neue Verbindung."])},
      "failed_jobs_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einige Verbindungen wurden nicht aktualisiert oder sind inaktiv. Versuchen Sie, diese Verbindungen zu aktualisieren, oder entfernen Sie sie aus Ihrer Liste."])},
      "fields": {
        "client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API-Client-ID"])},
        "client_secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API-Client-Geheimnis"])},
        "zd_chat_instruction_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Erstellen Sie unter „Einstellungen“ → „Konto“ → „API und SDKs“ ein neues API-Token. Die zu verwendende Weiterleitungs-URL lautet ", _interpolate(_named("redirectUrl"))])},
        "account_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto-ID"])}
      },
      "integration_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie Ihren Help Desk aus, um loszulegen"])},
      "intercom_region_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn Ihr Intercom-Konto für die Betreuung aus der EU oder Australien eingerichtet wurde, müssen Sie dies aktualisieren."])},
      "login_email": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["E-Mail-Adresse für die Anmeldung bei ", _interpolate(_list(0))])},
      "mask_data_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Namen, E-Mail-Adresse und Telefonnummer des Endbenutzers maskieren. Bankdaten werden für alle Help Desk-Verbindungen bereits maskiert."])},
      "no_connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noch keine Integrationen vorhanden. Fügen Sie jetzt eine hinzu."])},
      "roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie benötigen folgende Rollen:"])},
      "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv"])},
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbindung herstellen"])},
      "add_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Verbindung hinzufügen"])},
      "add_to_workspaces_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn Sie diese Option deaktivieren, können Sie diese Verbindung manuell nur zu bestimmten Arbeitsbereichen hinzufügen"])},
      "add_to_workspaces_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit allen vorhandenen Arbeitsbereichen verbinden"])},
      "all_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Arbeitsbereiche"])},
      "api_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API-Schlüssel"])},
      "api_key_secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API-Schlüsselgeheimnis"])},
      "channel_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Channels"])},
      "connection_deleted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Verbindung ", _interpolate(_list(0)), " gelöscht"])},
      "connection_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbindungsname"])},
      "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellt um"])},
      "created_cordless": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können Ihr API-Token unten abrufen."])},
      "csat_threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT-Schwellenwert"])},
      "delete_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Verbindung ", _interpolate(_named("connectionName")), " löschen"])},
      "deletion_hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie \"Verbindung löschen\" ein, um diese Verbindung zu entfernen"])},
      "deletion_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbindung löschen"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwalten und überwachen Sie die verbundenen Datenquellen."])},
      "edit_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbindung bearbeiten"])},
      "exclude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausschließen"])},
      "excluded_names": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feldnamen durch Kommas trennen"])},
      "failed_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler beim Aktualisieren"])},
      "give_unique_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legen Sie für die Integration einen eindeutigen Namen fest"])},
      "group_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppen"])},
      "hide_advanced_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erweiterte Optionen ausblenden"])},
      "hide_sensitive_fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertrauliche Datenfelder ausblenden"])},
      "ignore_attachment_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URLs von Anhängen nie speichern"])},
      "ignore_attachment_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anhänge ignorieren"])},
      "ignore_content_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversationsinhalte nie speichern (Sie können sie später bei Bedarf über das Help Desk abrufen)"])},
      "ignore_content_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversationsinhalt ignorieren"])},
      "ignore_subject_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betreff der Konversation nie speichern (Sie können ihn später bei Bedarf über das Help Desk abrufen)"])},
      "ignore_subject_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betreffzeile ignorieren"])},
      "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inaktiv"])},
      "integration_subdomain": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Ihre ", _interpolate(_list(0)), "-Subdomäne"])},
      "intercom_region_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intercom-Region"])},
      "intercom_team_id_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inbox-Team-ID abgleichen"])},
      "intercom_team_id_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numerische ID Ihres Intercom-Teams"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Läuft..."])},
      "mailbox_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mailboxes"])},
      "manual_connection_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbindung manuell aktualisieren"])},
      "mask_data_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundendaten maskieren"])},
      "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Arbeitsbereiche"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name des vertraulichen Felds"])},
      "placeholder_zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID des vertraulichen Felds"])},
      "renew_connection": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbindung erneuern"])}
      },
      "renew_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbindung erneuern"])},
      "salesforce_sandbox_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit Salesforce-Sandbox verbinden"])},
      "setting_up_with": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Einrichten einer Verbindung mit ", _interpolate(_list(0))])},
      "show_advanced_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erweiterte Optionen anzeigen"])},
      "show_csat_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nur Konversationen abrufen, deren Wert kleiner als der festgelegte Schwellenwert oder gleich diesem Schwellenwert ist"])},
      "show_csat_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT-Schwellenwert anwenden"])},
      "sync_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nur Konversationen synchronisieren, die ..."])},
      "sync_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neueste Konversationen abrufen"])},
      "tag_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stichwörter"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbindungen"])},
      "update_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbindung aktualisieren"])},
      "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbindung aktualisiert"])},
      "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzername"])},
      "your_help_desk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Help Desk"])}
    },
    "delete": {
      "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Um Ihr Abonnement zu kündigen und Ihr Konto zu löschen, wenden Sie sich an den ", _interpolate(_named("link")), "."])},
      "description_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundensupport"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto löschen"])}
    },
    "deleted_conversations": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Löschen eines Tickets in Zendesk Support (oder in einem anderen Help Desk) hat keine Auswirkungen auf die zugehörigen Konversationsüberprüfungen. Sie können sie weiterhin anzeigen und verwalten."])},
      "body_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Beim Löschen eines Tickets in Zendesk QA werden auch die Überprüfungen der zugehörigen Konversationen gelöscht.", _interpolate(_named("line_break")), "Das Löschen eines Tickets in Zendesk Support (oder in einem anderen Help Desk) hat keine Auswirkungen auf die Überprüfung der zugehörigen Konversation. Sie können sie weiterhin anzeigen und verwalten."])},
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überprüfungen verwalten"])},
      "delete_dialog": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Überprüfungen werden dauerhaft gelöscht. Dies hat Auswirkungen auf Daten in Dashboards."])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Überprüfungen löschen"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle löschen"])},
        "toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Überprüfungen gelöscht"])}
      },
      "list": {
        "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle löschen"])},
        "empty": {
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn mit diesen gelöschten Tickets Überprüfungen verknüpft sind, erscheinen sie hier."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noch keine Überprüfungen"])}
        },
        "search_box_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nach Ticket-ID suchen"])},
        "ticket_item_id": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ticket-ID: ", _interpolate(_named("id"))])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überprüfungen"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gelöschte Konversationen"])}
    },
    "general": {
      "danger_default_workspace": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Standard-Arbeitsbereiche können nicht gelöscht werden. Wenn Sie diesen Arbeitsbereich löschen möchten, wählen Sie unter ", _interpolate(_named("link")), " einen anderen Standard-Arbeitsbereich aus."])},
      "danger_default_workspace_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontoeinstellungen"])},
      "delete_workspace_dialog": {
        "action_cannot_be_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Vorgang kann nicht rückgängig gemacht werden."])},
        "remove_and_delete_all_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["alle Daten entfernt und gelöscht werden"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Mir ist klar, dass damit ", _interpolate(_named("removeAllData")), ", die mit diesem Arbeitsbereich verknüpft sind, einschließlich Arbeitsbereichsbenutzern und -verbindungen."])},
        "confirm_button_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeitsbereich löschen"])},
        "hint_for_confirm_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie \"Arbeitsbereich löschen\" ein, um diesen Arbeitsbereich zu löschen"])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Arbeitsbereich ", _interpolate(_named("workspaceName")), " löschen"])},
        "type_to_confirm_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeitsbereich löschen"])}
      },
      "danger_copy_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gehen Sie umsichtig vor und ziehen Sie einen Experten hinzu, falls Sie sich nicht sicher sind."])},
      "default_reviewee": {
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie aus, ob die standardmäßig geprüfte Person auf Grundlage des häufigsten Agenten oder des aktuellen Bearbeiters ausgewählt wird."])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitarbeiter"])},
        "dominant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivster Mitarbeiter"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standardmäßige geprüfte Person"])}
      },
      "self_reviews_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erlauben Sie, dass Personen ihre eigenen Konversationen überprüfen. Und ermöglichen Sie Agenten die Durchführung von Überprüfungen"])},
      "workspace_deleted_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeitsbereich wurde gelöscht"])},
      "workspace_updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeitsbereich wurde aktualisiert"])},
      "calibration_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blenden Sie die Überprüfungen aus, die andere Prüfer in der Vergangenheit abgegeben haben, um eine neutrale Bewertung zu ermöglichen"])},
      "calibration_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unvoreingenommene Bewertung"])},
      "color_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Farbe"])},
      "danger_copy_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Vorgang kann nicht rückgängig gemacht werden."])},
      "danger_copy_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beachten Sie, dass dadurch alle Daten, die mit diesem Arbeitsbereich verknüpft sind, entfernt und gelöscht werden, einschließlich der Benutzer und Verbindungen des Arbeitsbereichs."])},
      "delete_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeitsbereich löschen"])},
      "deleting_workspace_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeitsbereich wird gelöscht ..."])},
      "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "self_reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selbstüberprüfungen"])}
    },
    "logins": {
      "auth_providers": {
        "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google"])},
        "magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magischer Link"])},
        "okta_sso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okta SSO"])},
        "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kennwort"])},
        "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])}
      },
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magischer Link"])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre aktuelle Anmeldemethode kann nicht deaktiviert werden"])},
      "success_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentifizierungsmethoden wurden aktualisiert"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lassen Sie bestimmte Arten von Authentifizierungsmethoden für alle Benutzer zu."])},
      "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kennwort"])},
      "saml": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SAML/SSO"])},
      "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentifizierungen"])}
    },
    "navigation": {
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überprüfungen"])},
      "title_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KONTO"])},
      "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA"])},
      "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer und Arbeitsbereiche"])},
      "workspaces_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellen und verwalten Sie Arbeitsbereiche und Benutzer und laden Sie Benutzer ein oder verbinden Sie diese."])},
      "changes_in_progress": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auf Seite bleiben"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seite verlassen"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Seite verlassen und alle Änderungen verwerfen?"])}
      },
      "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbindungen"])},
      "logins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentifizierungen"])},
      "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benachrichtigungen"])},
      "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard"])},
      "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnement"])}
    },
    "no_conversations_found": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scheinbar sind keine Konversationen zum Importieren vorhanden. Führen Sie zunächst einige Konversationen mit Ihrem Help Desk und kehren Sie dann hierher zurück, um sie zu analysieren."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Konversationen gefunden"])}
    },
    "reviews": {
      "categories": {
        "delete_disabled_system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Systemkategorie kann nicht gelöscht werden."])},
        "delete_modal": {
          "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie löschen"])},
          "description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Löschen von Kategorien kann nicht rückgängig gemacht werden. Dadurch werden alle Kategoriedaten aus Berichten gelöscht."])},
          "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn Sie diese Kategorie wiederherstellen möchten, müssen Sie sie erneut erstellen."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie löschen"])}
        },
        "edit_drawer": {
          "custom_category_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom category type"])},
          "exact": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find phrases or keywords"])},
            "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You've reached the 20 active custom auto categories limit. To create a new category, mark another one as inactive or delete it."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exact text-match"])}
          },
          "manual": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review without automations"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manual"])}
          }
        },
        "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nach Kategorie suchen"])},
        "table": {
          "headings": {
            "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie"])},
            "scorecards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecards"])},
            "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ"])},
            "type_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bei automatischen Kategorien wird die Scorecard automatisch mit vorgeschlagenen Bewertungen versehen, damit Sie bei der Überprüfung Zeit sparen können. Manuelle Kategorien werden von den Prüfern von Grund auf neu ausgefüllt."])},
            "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letzte Aktualisierung"])}
          }
        },
        "types": {
          "auto": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autom."])}
          },
          "manual": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manuell"])}
          }
        }
      },
      "create_category_drawer": {
        "submit_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create category"])},
        "submit_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New category created"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create category"])}
      },
      "create_new": {
        "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard"])}
      },
      "edit_category_drawer": {
        "closing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abschluss"])},
        "empathy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empathie"])},
        "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechtschreibung und Grammatik"])},
        "greeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruß"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Making changes to general category settings applies to all scorecards this category is part of"])},
        "issue_understanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verständnis"])},
        "readability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lesbarkeit"])},
        "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angebotene Lösung"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit general category settings"])},
        "tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Gesprächston"])}
      },
      "edit_general_drawer": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit general category settings"])}
      },
      "no_results": {
        "categories": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Kategorien gefunden"])}
        },
        "root_causes": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Ursachen gefunden"])}
        },
        "scorecards": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Scorecards gefunden"])}
        },
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versuchen Sie es mit einer anderen Suche oder ändern Sie die Filter"])}
      },
      "pickers": {
        "categories": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), "-Kategorien"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), "-Kategorien"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), "-Kategorien"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Kategorien"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), "-Kategorien"])}
        },
        "scorecards": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " Scorecards"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " Scorecards"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " Scorecards"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Scorecards"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " Scorecards"])}
        },
        "statuses": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " Status"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " Status"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " Status"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Status"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " Status"])}
        },
        "types": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " Typen"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " Typen"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " Typen"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Arten"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " Typen"])}
        },
        "workspaces": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " Arbeitsbereiche"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " Arbeitsbereiche"])},
          "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Arbeitsbereiche gefunden"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " Arbeitsbereiche"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Arbeitsbereiche"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " Arbeitsbereiche"])}
        }
      },
      "root_causes": {
        "delete_modal": {
          "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete root cause"])},
          "description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deleting root causes is permanent. It will erase all root cause data from reporting."])},
          "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To restore this root cause, you'll need to create it again."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete root cause"])}
        },
        "edit_modal": {
          "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root cause name"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit root cause"])}
        },
        "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for root cause"])},
        "table": {
          "headings": {
            "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categories"])},
            "root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root cause"])},
            "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last update"])}
          }
        }
      },
      "scorecards": {
        "create": {
          "add_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add category"])},
          "add_group_section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add group section"])},
          "auto_workspaces_disabled_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currently AutoQA is inactive, you can activate it by turning on the main AutoQA toggle in Reviews. Once AutoQA is active, scorecards leave auto-reviews in each selected workspace."])},
          "auto_workspaces_enabled_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currently AutoQA is active, and this scorecard will leave auto-reviews in each selected workspace."])},
          "auto_workspaces_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enabled for auto-reviews in"])},
          "auto_workspaces_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No workspaces"])},
          "category_criticality_change_info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("category")), " now fails the whole review"])},
          "category_weight_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight determines the category's importance. Weight 0 means the category doesn't contribute to the Internal Quality Score (IQS)."])},
          "conditional_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only show on the scorecard under certain conditions"])},
          "critical_category_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fails the group only"])},
          "critical_category_info_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fails the whole review"])},
          "critical_category_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Critical category"])},
          "critical_category_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fails the whole review"])},
          "edit_category": {
            "category_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
            "category_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select category"])},
            "edit_general_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit general category settings"])},
            "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove from scorecard"])},
            "scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating scale"])},
            "weight_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight"])}
          },
          "edit_root_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit root causes"])},
          "errors": {
            "bad_weight": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Weight must be between ", _interpolate(_named("min")), " to ", _interpolate(_named("max"))])},
            "name_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name is required"])},
            "no_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add at least 1 category to the scorecard"])},
            "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select at least 1 workspace"])}
          },
          "group_critical_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["!"])},
          "group_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group section name"])},
          "manual_workspaces_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enabled for manual reviews in"])},
          "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Untitled scorecard name"])},
          "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish"])},
          "published_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard published successfully"])},
          "rating_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating"])},
          "review_critical_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["!!"])},
          "root_causes_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root causes"])},
          "root_causes_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add root causes to explain rating"])},
          "root_causes_multiple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multiple root causes"])},
          "root_causes_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["‘Other’ option and comment field"])},
          "root_causes_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select from the list or create a new root cause"])},
          "root_causes_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add root causes to identify reasons behind issues"])},
          "save_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save as Draft"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Scorecard"])},
          "total_weight_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total weight"])},
          "total_weight_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total weight"])},
          "weight_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Gewichtung von ", _interpolate(_named("weight")), " trägt ", _interpolate(_named("percentage")), " % zum Gesamtwert bei"])}
        },
        "delete_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must have at least 1 scorecard"])},
        "delete_modal": {
          "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard löschen"])},
          "description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Löschen von Scorecards kann nicht rückgängig gemacht werden. Dadurch werden sämtliche Scorecard-Daten aus Berichten gelöscht."])},
          "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um die Scorecard wiederherzustellen, müssen Sie sie erneut erstellen."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard löschen"])}
        },
        "edit": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Scorecard"])}
        },
        "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for scorecard"])},
        "table": {
          "headings": {
            "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorien"])},
            "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard"])},
            "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
            "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letzte Aktualisierung"])},
            "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeitsbereiche"])}
          }
        }
      },
      "settings_modal": {
        "allow_skip": {
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applies to all categories"])},
          "all_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All rating scales within existing scorecards will include N/A as an option"])},
          "specify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specify for each category in scorecard setup"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit review settings"])}
      },
      "status": {
        "types": {
          "active": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv"])}
          },
          "draft": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entwurf"])}
          },
          "inactive": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inaktiv"])}
          }
        }
      },
      "table": {
        "actions": {
          "mark_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark as active"])},
          "mark_inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark as inactive"])}
        }
      },
      "tabs": {
        "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorien"])},
        "root_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hauptursachen"])},
        "scorecards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecards"])}
      },
      "toast": {
        "category_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category deleted"])},
        "changes_applied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All changes applied"])},
        "root_cause_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root cause deleted"])},
        "scorecard_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard marked as active"])},
        "scorecard_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard deleted"])},
        "scorecard_duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard duplicated"])},
        "scorecard_inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard marked as inactive"])}
      }
    },
    "scorecard": {
      "option": {
        "not_applicable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])}
      },
      "toast_updated_message_with_action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bewertungsskala aktualisiert. ", _interpolate(_named("buttonStart")), "Überprüfung starten", _interpolate(_named("buttonEnd")), "."])},
      "binary_scale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA legt standardmäßig eine binäre Skala fest. Sie können aber auch eine detailliertere Skala auswählen:"])},
      "granular_scale": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wenn Sie verschiedene Optionen für die Bewertungsskala, nach Kategorie konfiguriert, anzeigen möchten, navigieren Sie zu ", _interpolate(_named("link"))])},
      "mocked_category_name_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empathie/Tonfall"])},
      "mocked_category_name_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produktwissen"])},
      "modal": {
        "by_updating_rating_scales_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durch Aktualisierung der Bewertungsskalen:"])},
        "by_updating_rating_scales_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre vorherige Bewertungsskala und die entsprechenden Kategorien werden archiviert (nicht gelöscht)."])},
        "by_updating_rating_scales_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einige Dashboard-Daten sind betroffen, da Ihre alte und Ihre neue Skala möglicherweise nicht vergleichbar sind."])},
        "clone_categories_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine vorhandene Einrichtung der Bewertungskategorien in die neue Skala klonen"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie die Bewertungsskalen wirklich aktualisieren?"])}
      },
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können das Format für die Scorecard-Anzeige (Nummern oder Emojis) ändern und Kategorien als obligatorisch oder optional definieren."])},
      "display_emojis_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emojis statt Nummern verwenden"])},
      "display_na_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zulassen, dass Kategorien übersprungen werden"])},
      "mocked_category_name_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lösung"])},
      "scale_options_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ der Bewertungsskala"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard"])},
      "toast_cloned": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Kategorien für 1 Arbeitsbereich wurden geklont"]), _normalize(["Kategorien für ", _interpolate(_named("workspacesAmount")), " Arbeitsbereiche wurden geklont"])])},
      "workspace_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeitsbereichseinstellungen"])}
    },
    "spotlight": {
      "create": {
        "message_type": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wenn ", _interpolate(_named("messageType"))])}
      },
      "deadair": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatisch Anrufe anzeigen, bei denen der Mitarbeiter den Kunden in der Konversation nicht darauf hinweist, dass es zu einer Gesprächspause kommen wird."])},
        "description_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In einer Konversation gibt es Gesprächspausen, die länger dauern als der festgelegte Schwellenwert."])},
        "filter_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximalen Schwellenwert für Gesprächspausen bei Anrufen festlegen"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesprächspause"])}
      },
      "delete": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gelöschte Insights erscheinen nicht in neuen Konversationen und Filtern. Historische Daten in Dashboards und Konversationen bleiben intakt."])},
        "failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler beim Löschen von Spotlight-Insights"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spotlight-Insight gelöscht"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spotlight-Insight löschen"])}
      },
      "description": {
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rufen Sie Konversationen auf, die bestimmte Wörter oder Ausdrücke in Transkripten und Nachrichten enthalten."])}
      },
      "recording_disclosure": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anrufe automatisch erkennen, bei denen der erforderliche Offenlegungshinweis fehlt, z. B. „Dieser Anruf wird aufgezeichnet“."])},
        "spotlight_setup_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ziel ist es, einen Hinweis zur Offenlegung von Aufzeichnungen im Anruf mithilfe eines LMLs (Large Language Model) zu erkennen, das den Kontext dynamisch erkennt und die Verwendung vordefinierter Formulierungen überflüssig macht."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offenlegung der Aufzeichnung fehlt"])}
      },
      "dead_air": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatisch Anrufe anzeigen, bei denen der Mitarbeiter den Kunden in der Konversation nicht darauf hinweist, dass es zu einer Gesprächspause kommen wird."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesprächspause"])},
        "tooltip_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesprächspausen in einem Anruf angeben"])}
      }
    },
    "subscription": {
      "change_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Möchten Sie Änderungen an Ihrem Abonnement vornehmen? ", _interpolate(_named("link")), " (", _interpolate(_named("email")), ") und wir helfen Ihnen gerne weiter."])},
      "plan": {
        "zendesk": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysieren Sie automatisch 100 % der Konversationen über Agenten, BPOs, Kanäle und Sprachen hinweg. KI erkennt Probleme, Wissenslücken und Coaching-Möglichkeiten, um den Service zu verbessern."])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA-Abdeckung von 100 %"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agentenfeedback und Coaching"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spotlight-KI-Insights"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erweiterte Berichte und Dashboards"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stimmungsanalyse und -filterung"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übersetzungen on demand"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überprüfung der Aufgaben und Zielsetzung"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coaching und Quiz"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrierung"])}
          },
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk Qualitätssicherung (QA)"])}
        },
        "advanced": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Vorteile von Professional plus"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erweitert (Legacy)"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSO SAML"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webhooks zur sofortigen Benachrichtigung bei kritischen Fehlern"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data-Warehouse-Integration"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salesforce Service Cloud-Integration"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entwicklung angepasster Integrationen"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dedizierter Customer Success Manager"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direkte Kontaktaufnahme mit unseren Produkt- und Engineering-Teams über Slack"])}
          }
        },
        "ai": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Vorteile von Growth Suite plus"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KI-Suite"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA für 100-prozentige Abdeckung"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KI-gesteuerte Konversations-Insights für ein besseres Verständnis der Daten"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durch KI erweiterte Anreicherung und Filterung von Help-Desk-Daten"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spotlight: Automatische Erkennung von Interaktionen, die unbedingt überprüft werden müssen"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stimmungsanalyse und -filterung"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KI-gestützte Leistungsberichte und -Insights"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On-Demand-Übersetzung von Konversationen"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeiterfassung überprüfen"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysen der Erfolgsraten"])}
          }
        },
        "enterprise": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Vorteile der KI Suite plus"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enterprise Suite"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enterprise-Implementierungspaket mit Integration von Salesforce und Genesys"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entwicklung angepasster Integrationen"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSO SAML"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data-Warehouse-Integration"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webhooks zur sofortigen Benachrichtigung bei kritischen Fehlern"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dedizierter Customer Success Manager"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direkte Kontaktaufnahme mit unseren Produkt- und Engineering-Teams"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Early Access und Beeinflussung unserer Roadmap"])}
          }
        },
        "growth": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Growth Suite"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatische Zuweisungen von Überprüfungen und Zielfestlegung"])},
            "item_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gezieltes Coaching und Analysen"])},
            "item_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz für das Lernmanagement"])},
            "item_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwaltung von Benutzerrollen und -berechtigungen"])},
            "item_13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maskierung vertraulicher Daten"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erweiterte Filteroptionen und Konversationssuche"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anpassbare Scorecards mit mehreren Bewertungen, anpassbaren Gewichtungen und Optionen für das automatische Nichtbestehen"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Browsererweiterung, um Konversationen überall zu überprüfen"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse der Hauptursachen"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrierung"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Streitfälle"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leistungsberichte zum Vergleich von Mitarbeitern, Teams und BPOs"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysen von CSAT-Umfragen und Kundenfeedback"])}
          },
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funktionen"])}
        },
        "professional": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Vorteile von Starter plus"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professional (Legacy)"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehrere Bewertungsskalen pro Konversation"])},
            "item_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack-Integration"])},
            "item_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KI-gesteuerte interaktive Konversations-Insights"])},
            "item_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überprüfungszuweisungen"])},
            "item_13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notizen für Coaching"])},
            "item_14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coaching-Sitzungen"])},
            "item_15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz"])},
            "item_16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stimmungsanalyse und -filterung"])},
            "item_17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtern nach Konversationskomplexität"])},
            "item_18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversations-Insights"])},
            "item_19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Streitfälle"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedingte Scorecards"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse der Hauptursachen"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erweiterte KI-gestützte CSAT-Analysen"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewertungskalibrierung"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Browsererweiterung, um Konversationen überall zu überprüfen"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spotlight, um Konversationen zu entdecken, die unbedingt überprüft werden müssen"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On-Demand-Übersetzung von Konversationen"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeiterfassung überprüfen"])}
          }
        },
        "flat_fee": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angepasster Plan"])}
        },
        "starter": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starter (Legacy)"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unbegrenzte Konversationsüberprüfungen"])},
            "item_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSV-Export"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überprüfungen von und durch Kollegen und Selbstüberprüfungen"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecards mit anpassbaren Gewichtungen und Optionen für das automatische Nichtbestehen"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Robuste Filteroptionen"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tägliche, wöchentliche oder monatliche Überprüfungsziele"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT-Umfragen und grundlegende Analysen"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboards"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berichte zum Vergleich von Mitarbeitern, Teams und BPOs"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwaltung von Benutzerrollen und -berechtigungen"])}
          },
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funktionen"])}
        }
      },
      "support_email": {
        "customer": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich möchte eine Änderung an meinem Zendesk-QA-Abonnement vornehmen.\n\nMarkieren oder kennzeichnen Sie eine Option und geben Sie die Anzahl der Lizenzen an, die Sie ändern möchten:\nIch möchte [X Lizenzen hinzufügen] [X Lizenzen entfernen] [ein Upgrade auf den Zendesk QA-Plan durchführen]\n\nBitte teilen Sie uns Ihren Unternehmensnamen und den Namen Ihres Zendesk QA-Kontos sowie alle anderen Details mit, die wir zur Bearbeitung dieser Änderung benötigen."])},
          "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnementänderung für Zendesk QA"])}
        },
        "trial": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich möchte ein Abonnement für Zendesk QA erwerben.\n\nBitte teilen Sie uns Ihren Unternehmensnamen und den Namen Ihres Zendesk QA-Kontos sowie alle anderen Details mit, die wir zur Bearbeitung dieser Anfrage benötigen:\n\nBitte teilen Sie uns auch alle konkreten Fragen mit, die Sie vor dem Kauf beantwortet haben möchten:\n\nVielen Dank!"])},
          "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnementkauf für Zendesk QA"])}
        }
      },
      "zendesk_subscription_end": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ihr Abonnement endet am ", _interpolate(_named("date")), " ( noch ", _interpolate(_named("days")), ")"])},
      "zendesk_trial_end": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ihr Test endet am ", _interpolate(_named("date")), " (", _interpolate(_named("days")), " übrig)"])},
      "zendesk_trial_end_days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 Tag"]), _normalize([_interpolate(_named("n")), " Tage"])])},
      "billed_monthly": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Abrechnung von ", _interpolate(_named("card")), " (läuft ab am ", _interpolate(_named("expires")), ") am ", _interpolate(_named("dayOfMonth")), ". eines jeden Monats."])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Abrechnung von ", _interpolate(_named("card")), " (läuft ab am ", _interpolate(_named("expires")), ") am ", _interpolate(_named("dayOfMonth")), ". eines jeden Monats."])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Abrechnung von ", _interpolate(_named("card")), " (läuft ab am ", _interpolate(_named("expires")), ") am ", _interpolate(_named("dayOfMonth")), ". eines jeden Monats."])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Abrechnung von ", _interpolate(_named("card")), " (läuft ab am ", _interpolate(_named("expires")), ") am ", _interpolate(_named("dayOfMonth")), ". eines jeden Monats."])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Abrechnung von ", _interpolate(_named("card")), " (läuft ab am ", _interpolate(_named("expires")), ") am ", _interpolate(_named("dayOfMonth")), ". eines jeden Monats."])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Abrechnung von ", _interpolate(_named("card")), " (läuft ab am ", _interpolate(_named("expires")), ") am ", _interpolate(_named("dayOfMonth")), ". eines jeden Monats."])}
      },
      "billed_to": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Abgerechnet auf ", _interpolate(_named("card")), " (gültig bis ", _interpolate(_named("expires")), ") am ", _interpolate(_named("billingDate")), "."])},
      "ending_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["endend in"])},
      "free_trial_expires_in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Abgelaufen"]), _normalize(["Läuft in 1 Tag ab"]), _normalize(["Läuft in ", _interpolate(_named("expiresIn")), " Tagen ab"])])},
      "min_seats_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Startpreis enthält ", _interpolate(_named("n")), " Benutzerlizenzen"])},
      "min_seats_montly_total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["beginnt bei ", _interpolate(_named("sum")), "/Monat"])},
      "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monat"])},
      "per_agent_per_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pro Benutzer/Monat"])},
      "per_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pro Jahr"])},
      "per_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pro Monat"])},
      "quarter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quartal"])},
      "remove_cancellation_warning": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["die geplante Kündigung entfernen"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wenn Sie Zendesk QA weiterhin verwenden möchten, ", _interpolate(_named("link"))])}
      },
      "subscription_cancelled_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ihr Abonnement wurde gekündigt am ", _interpolate(_named("date")), "."])},
      "subscription_cancels_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ihr Abonnement läuft planmäßig ab am ", _interpolate(_named("date")), "."])},
      "trial_pill": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), "-Tage-Testversion ohne Zusatzkosten!"])},
      "used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["verwendet"])},
      "used_seats_warning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sie haben ", _interpolate(_named("n")), " Benutzer. Die Anzahl der Lizenzen darf nicht niedriger sein."])},
      "will_resume_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Abonnement wird fortgesetzt am ", _interpolate(_named("time"))])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jahr"])},
      "call_transcription_upsell_alert": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ihr aktueller Zendesk QA-Plan (", _interpolate(_named("plan")), ") bietet keine KI-gestützten Funktionen. Um alle Spracherkennungsfunktionen zu nutzen: ", _interpolate(_named("link")), "."])}
      },
      "manual_cancellation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kontaktieren Sie uns ", _interpolate(_named("link")), " oder chatten Sie mit uns, um Ihr Abonnement zu kündigen."])},
      "plan_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qualitätsmanagementplan"])},
      "quality_management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qualitätsmanagement"])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnement aktualisiert"])},
      "add_credit_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kreditkarte hinzufügen"])},
      "all_plans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Pläne"])},
      "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abrechnung"])},
      "billing_info_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siehe Rechnungsdaten"])},
      "billing_interval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abrechnungsintervall"])},
      "cancel_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnement kündigen"])},
      "cancel_subscription_disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Abonnement wird zum Ende des Abrechnungszeitraums gekündigt."])},
      "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktieren Sie uns"])},
      "contact_us_to_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenden Sie sich zur Bearbeitung an uns"])},
      "current_plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktueller Plan"])},
      "custom_pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angepasste Preisinformationen"])},
      "downgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Downgraden"])},
      "dropdown_annual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jährlich (15 % sparen)"])},
      "dropdown_annual_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jährlicher"])},
      "dropdown_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monatlich"])},
      "duration_toggle": {
        "prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jährlich zahlen"])},
        "suffix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monatlich"])}
      },
      "edit_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnement bearbeiten"])},
      "explore_plans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pläne entdecken"])},
      "free_trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kostenlos testen"])},
      "invoiced_manually": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manuelle Abrechnung"])},
      "modal_review_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Im nächsten Schritt können Sie die endgültige Bestellzusammenfassung überprüfen und bestätigen."])},
      "most_popular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Am beliebtesten"])},
      "no_payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben keine aktive Zahlungsmethode."])},
      "on_trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Im Test"])},
      "pause_readonly_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setzen Sie Ihr aktuelles Abonnement fort, um den Plan zu ändern"])},
      "pause_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnement aussetzen"])},
      "period": {
        "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monatlich"])},
        "quarter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vierteljährlich"])},
        "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jährlicher"])}
      },
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preis"])},
      "reactivate_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnement erneut aktivieren"])},
      "resume_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnement fortsetzen"])},
      "seats_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lizenzen"])},
      "start_trial_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jetzt Trial starten"])},
      "status": {
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv"])},
        "cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Storniert"])},
        "non_renewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht verlängernd"])},
        "paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angehalten"])}
      },
      "subscription_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnementstatus"])},
      "trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test"])},
      "upgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgraden"])},
      "upgrade_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnement upgraden"])}
    },
    "survey": {
      "create": {
        "type": {
          "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CES"])},
          "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
          "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angepasst"])}
        },
        "draft": {
          "intro_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte nehmen Sie sich eine Minute Zeit, um Feedback zu Ihrer Erfahrung mit dem Kundensupport zu geben. Vielen Dank vorab – ", "{", "{", "agentName", "}", "}", " schätzt Ihr Feedback!"])},
          "mail_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hallo ", "{", "{", "customerName", "}", "}", " 👋!"])},
          "name": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["CSAT-Umfrage von ", _interpolate(_list(0))])},
          "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wie bewerten Sie den Support, den Sie von ", "{", "{", "agentName", "}", "}", " erhalten haben?"])},
          "thank_you_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Wir freuen uns über Ihr Feedback. Wir werden Ihr Feedback nutzen, um ", _interpolate(_list(0)), " für alle besser zu machen."])}
        },
        "link_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Infos zu Umfragen"])},
        "template_modal": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umfragetyp auswählen"])},
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dies kann später geändert werden."])}
        },
        "description_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messen und verbessern Sie die Kundenzufriedenheit mit automatisierten CSAT-Umfragen in mehreren Sprachen."])},
        "description_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Umfragen erstellt"])},
        "new_survey_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umfrage erstellen"])},
        "survey_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umfragename"])},
        "toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umfrage wurde erstellt"])}
      },
      "edit": {
        "add_language_modal": {
          "not_found": {
            "cta": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Versuchen Sie, das Schlüsselwort zu ändern, oder ", _interpolate(_named("contact_us")), ", wenn es hier aufgeführt werden soll."])},
            "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lassen Sie es uns wissen"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Sprache gefunden"])}
          },
          "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wählen Sie in der Liste unten eine oder mehrere Sprachen aus. ", _interpolate(_named("contact_us")), ", wenn die gewünschte Sprache hier nicht aufgeführt ist."])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprachen hinzufügen"])},
          "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lassen Sie es uns wissen"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprache hinzufügen"])}
        },
        "last_published": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Zuletzt veröffentlicht: ", _interpolate(_list(0))])},
        "last_saved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Zuletzt gespeichert: ", _interpolate(_list(0))])},
        "review_translations_modal": {
          "sections": {
            "question": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("type")), "-Frage"])},
            "intro_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einführungsfrage"])},
            "left_scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linke Skalenbeschriftung"])},
            "mail_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail-Betreff"])},
            "reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gründe"])},
            "right_scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechte Skalenbeschriftung"])},
            "thank_you_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vielen Dank"])}
          },
          "missing_text_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn in einer Umfrage Übersetzungen fehlen, werden die Texte in der Ausgangssprache angezeigt"])},
          "apply_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übersetzungen anwenden"])},
          "missing_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehlende Übersetzungen"])},
          "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Überprüfen Sie alle Übersetzungen, bevor Sie sie anwenden. Ändern Sie die heruntergeladene Datei, speichern Sie sie und laden Sie sie bei Bedarf erneut hoch. Wenn ein Token ", _interpolate(_named("token_example")), " nicht als Stichwort erscheint, besitzt es entweder das falsche Format oder es befindet sich in einem Feld, das keine Token unterstützt."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übersetzungen überprüfen"])},
          "translated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übersetzt"])}
        },
        "sections": {
          "content": {
            "ai_assist": {
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fasst die Konversation zusammen und zeigt Vorschläge für Kommentare an."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA KI-Unterstützung (Englisch)"])}
            },
            "define_type": {
              "no_types_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können Typen nach der Erstellung bearbeiten."])},
              "duplicate_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Typ ist bereits vorhanden. Wählen Sie einen anderen Namen aus."])},
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern Sie den angepassten Typ als Option in der Dropdownliste „Umfragetyp“ und gruppieren Sie alle erstellten Umfragen im Dashboard."])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wörter eingeben und durch Drücken der Eingabetaste speichern"])},
              "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angepasste Typen bearbeiten"])},
              "empty_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typname darf nicht leer sein"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ definieren"])},
              "not_saved_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angepasster Typ wurde nicht gespeichert. Drücken Sie zum Speichern die Eingabetaste."])}
            },
            "senders_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail-Adresse des Absenders"])},
            "senders_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name des Absenders"])},
            "ces": {
              "questions": {
                "1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Inwieweit stimmen Sie der folgenden Aussage zu: „Mit ", _interpolate(_named("agentName")), " konnte ich mein Problem auf einfache Weise lösen“"])},
                "2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wie einfach war es bislang, von ", _interpolate(_named("brandName")), " Unterstützung zu erhalten?"])},
                "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wie einfach war es insgesamt heute, Ihr Problem zu lösen?"])},
                "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wie einfach war es, mit unserem Team zu interagieren?"])}
              }
            },
            "privacy_policy_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL der Datenschutzrichtlinie (optional)"])},
            "tos_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL der Allgemeinen Geschäftsbedingungen (optional)"])},
            "csat": {
              "questions": {
                "1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wie würden Sie den von ", _interpolate(_named("agentName")), " erhaltenen Support bewerten?"])},
                "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wie war unser Service bei dieser Anfrage?"])},
                "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wie zufrieden waren Sie mit dem Supportmitarbeiter?"])},
                "4": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wie zufrieden sind Sie mit dem Service, den Sie heute von ", _interpolate(_named("agentName")), " erhalten haben?"])}
              }
            },
            "custom_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angepasste Frage"])},
            "custom_question_option": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Eigene angepasste ", _interpolate(_named("type")), "-Frage definieren ..."])},
            "custom_question_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angepasste Frage eingeben"])},
            "customize_urls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URLs anpassen"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail-Nachricht festlegen"])},
            "edit_types": {
              "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn Sie den Namen eines angepassten Fragentyps bearbeiten, wird er in allen Umfragen aktualisiert, die ihn verwenden, ohne dass sich dies auf die erfassten Daten auswirkt."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angepasste Typen bearbeiten"])}
            },
            "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E‑Mail"])},
            "email_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail-Betreff"])},
            "intro_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einführungsfrage"])},
            "question_label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("type")), "-Frage"])},
            "survey_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ"])},
            "survey_types": {
              "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CES"])},
              "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
              "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuen angepassten Typ erstellen"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inhalt"])},
            "tokens": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Personalisierungstoken"]), _normalize(["Personalisierungstoken"])])},
            "tokens_description": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Ziehen Sie das Personalisierungstoken in das Feld unten."]), _normalize(["Ziehen Sie die Personalisierungstoken in die Felder unten."])])}
          },
          "delivery": {
            "conversation_rule": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Konversation ", _interpolate(_list(0))])},
            "deliver_via_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gegenwärtig können Kundenzufriedenheitsumfragen nur per E-Mail gesendet werden. Wir arbeiten daran, Ihnen weitere Möglichkeiten zu bieten, darunter Help-Desk-Kundenzufriedenheitsumfragen und SMS-Umfragen."])},
            "errors": {
              "connection_disabled_action": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Synchronisieren oder erneuern Sie die Verbindung"]), _normalize(["Synchronisieren oder erneuern Sie die Verbindungen"])])},
              "connection_disabled_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synchronisieren oder erneuern Sie die Verbindung ↗"])},
              "connection_disabled_text": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Verbindung nicht korrekt synchronisiert"]), _normalize(["Verbindungen werden nicht korrekt synchronisiert."])])},
              "connection_masked_data_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entfernen Sie die Markierung im entsprechenden Kästchen ↗"])},
              "connection_permissions_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jetzt authentifizieren ↗"])},
              "connection_no_rules_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fügen Sie mindestens eine Regel für das Versenden der Umfrage hinzu, um die Verbindung zu aktivieren"])},
              "connection_disabled": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Diese Verbindung ist aktuell deaktiviert. ", _interpolate(_named("link"))])},
              "connection_masked_data": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Diese Verbindung maskiert vertrauliche Kundendaten. Um das Versenden der Umfrage zu aktivieren,\n", _interpolate(_named("link")), " in den Einstellungen."])},
              "connection_permissions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Für diese Verbindung sind zusätzliche Berechtigungen zum Versenden von Umfragen erforderlich.\n", _interpolate(_named("link"))])},
              "intercom_reauth_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jetzt authentifizieren"])},
              "intercom_reauth_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA benötigt mehr Berechtigungen für Intercom."])}
            },
            "follow_up_duration": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["(", _interpolate(_named("hours")), " und ", _interpolate(_named("minutes")), ")"])},
            "follow_up_hours": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 Stunde"]), _normalize([_interpolate(_named("n")), " Stunden"])])},
            "follow_up_minutes": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " Minuten"]), _normalize(["1 Minute"]), _normalize([_interpolate(_named("n")), " Minuten"])])},
            "follow_up_unit": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Stunde"]), _normalize(["Stunden"])])},
            "intercom_rules": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("link")), ", um Senderegeln für Umfragen zu konfigurieren."])},
            "links_expire": {
              "days": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize([" Tag"]), _normalize(["Tage"])])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umfrage-Links laufen ab"])},
              "expire_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Links laufen ab in"])},
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verhindern, dass Umfragen beantwortet werden, wenn zu viel Zeit vergangen ist"])}
            },
            "modal": {
              "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["und"])},
              "validation_error_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Umfrage ", _interpolate(_named("name")), " ↗"])},
              "brand_domain_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trennen Sie die Stichwörter durch Kommas oder drücken Sie nach der Eingabe die Eingabetaste. Prüfen Sie, ob alle exakt wie in Intercom aufgeführt werden."])},
              "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legen Sie die Bedingungen für den Versand der Umfrage fest."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regeln für das Versenden von Umfragen"])},
              "title_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regeln für das Versenden von Folgeumfragen"])},
              "validation_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Eine Umfrage ", _interpolate(_named("name")), " ", _interpolate(_named("rules")), " ist bereits vorhanden.\nLösen Sie den Konflikt, indem Sie die Regeln entweder hier oder in ", _interpolate(_named("link")), " anpassen."])},
              "validation_error_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sie haben bereits eine Verbindung mit Abgleichsregeln für ", _interpolate(_named("name")), " Umfrage eingerichtet. Lösen Sie den Konflikt, indem Sie die Regeln entweder hier oder in ", _interpolate(_named("link")), " anpassen."])},
              "add_another_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Regel hinzufügen"])},
              "brand_domain_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marke hinzufügen"])},
              "condition_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedingung auswählen"])},
              "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätigen"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einen Wert suchen"])},
              "tag_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stichwort hinzufügen"])},
              "validation_error_rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["die mit denselben Regeln übereinstimmt,"])},
              "values_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wert auswählen"])}
            },
            "email_delay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail versandt in"])},
            "email_delay_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folge-E-Mail versandt in"])},
            "embedded_snippet": {
              "delivery_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eingebettetes Snippet und Folge-E-Mail"])},
              "copy_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code kopieren"])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fügen Sie den Snippet-Code zu Ihrer Zendesk-Einrichtung hinzu, um Zendesk QA-Umfragen an Ihre E-Mails anzuhängen."])},
              "installation_guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installationsleitfaden"])},
              "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprache"])},
              "open_zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk öffnen"])},
              "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorschau"])},
              "preview_modal": {
                "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snippet-Vorschau"])}
              },
              "snippet_copied_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code-Snippet in Zwischenablage kopiert"])},
              "snippet_copy_error_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler beim Kopieren des Snippets"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eingebettetes Snippet"])},
              "unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Umfrage enthält nicht gespeicherte Änderungen. Speichern Sie sie erneut, um sicherzustellen, dass der Snippet-Code die neuesten Aktualisierungen enthält."])}
            },
            "empty_state": {
              "add_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbindung hinzufügen"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noch keine Verbindungen hinzugefügt"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rufen Sie mit Ihren Verbindungen E-Mails, Live-Chat, Telefonanrufe und mehr in Zendesk QA ab"])}
            },
            "intercom_help_article": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Infos zur Zustellung von Umfragen"])},
            "when_to_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regeln für das Versenden von Umfragen"])},
            "when_to_send_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regeln für das Versenden von Folgeumfragen"])},
            "add_brand_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Markenregel hinzufügen"])},
            "add_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regel hinzufügen"])},
            "authenticate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentifizieren"])},
            "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folgeanfrage per Messenger + E-Mail"])},
            "connect_intercom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Intercom-Konto mit der CSAT-Umfrage von Zendesk QA verbinden"])},
            "deliver_via": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zustellen über"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wer erhält die Umfrage und wann"])},
            "edit_rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regeln bearbeiten"])},
            "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E‑Mail"])},
            "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiviert"])},
            "intercom_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Über In-App-Umfragen können Kunden eine Umfrage beantworten, ohne den Chat verlassen zu müssen."])},
            "intercom_rules_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intercom starten"])},
            "list": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bereitstellungsregeln pro Verbindung"])},
              "validation_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Eine Umfrage ", _interpolate(_named("name")), " ", _interpolate(_named("rules")), " ist bereits vorhanden.\nLösen Sie den Konflikt, indem Sie die Verbindung deaktivieren oder die Regeln entweder hier oder in der ", _interpolate(_named("link")), " ändern"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bereitstellung"])},
            "when_to_send_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legen Sie die Bedingungen für den Versand der Umfrage fest"])}
          },
          "languages": {
            "created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Erstellt von ", _interpolate(_named("name")), " am ", _interpolate(_named("createdDate"))])},
            "remove_translation_modal": {
              "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("languageName")), " entfernen"])},
              "message_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn Sie diese Sprache entfernen, wird sie für die Befragten nicht mehr angezeigt und alle übersetzten Texte werden gelöscht."])},
              "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprache entfernen"])},
              "message_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Vorgang kann nicht rückgängig gemacht werden."])},
              "message_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können die Sprache auch deaktivieren, indem Sie sie in der Umfrage ausblenden. Auf diese Weise können Sie alle Übersetzungen beibehalten, ohne dass sie den Umfrageteilnehmern angezeigt werden."])}
            },
            "updated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Zuletzt bearbeitet von ", _interpolate(_named("name")), " am ", _interpolate(_named("createdDate"))])},
            "description": {
              "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Infos zu Umfragesprachen"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Stellen Sie vor dem Hinzufügen anderer Sprachen sicher, dass Sie die Änderungen in den Stil- und Inhaltsschritten abgeschlossen haben. ", _interpolate(_named("link"))])}
            },
            "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprache hinzufügen"])},
            "columns": {
              "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprache"])},
              "translations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übersetzte Felder"])},
              "visibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Für Umfrageteilnehmer sichtbar"])}
            },
            "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standard"])},
            "download_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übersetzungsdatei herunterladen"])},
            "remove_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprache entfernen"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprachen"])},
            "translation_complete": {
              "download_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Laden Sie eine Übersetzungsdatei herunter"])},
              "review_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übersetzungen überprüfen"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Übersetzung abgeschlossen. ", _interpolate(_named("review_link")), " oder ", _interpolate(_named("download_link")), ", um Änderungen vorzunehmen."])}
            },
            "translation_incomplete": {
              "download_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Laden Sie eine Übersetzungsdatei herunter"])},
              "review_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überprüfen Sie, was noch fehlt"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Einige Inhalte müssen noch übersetzt werden und werden durch die englische Version ersetzt. ", _interpolate(_named("review_link")), " oder ", _interpolate(_named("download_link")), ", um fehlende Übersetzungen hinzuzufügen."])}
            },
            "unsaved_changes_warninig": {
              "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umfrage speichern"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Die Umfrage enthält nicht gespeicherte Änderungen. ", _interpolate(_named("save")), ", um die Übersetzungsdatei mit den neuesten Änderungen herunterzuladen."])}
            },
            "upload_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übersetzungsdatei hochladen"])}
          },
          "rating_scales": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bevorzugte Bewertungsskala auswählen"])},
            "reasons_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeigt Kunden ein Dropdownmenü an, in dem sie einen Grund für die Bewertung auswählen können"])},
            "emojis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emojis"])},
            "left_scale_text_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linker Skalentext (optional)"])},
            "numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nummern"])},
            "reason_input_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grund hinzufügen"])},
            "reason_validation_message_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grund darf nicht leer sein"])},
            "reason_validation_message_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Grund ist bereits vorhanden"])},
            "reasons_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dropdown-Menü mit Gründen für den Wert anzeigen"])},
            "reasons_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn Sie einen Grund löschen und ihn unter demselben Namen erneut hinzufügen oder einen Grund umbenennen, wird immer ein neuer Eintrag erstellt. Dies wirkt sich auch auf die im Dashboard angezeigten Daten aus."])},
            "remove_reason_confirmation": {
              "confirmation_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grund löschen"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein gelöschter Grund bleibt in den Dashboard-Berichten enthalten, ist aber für neue Umfrageteilnehmer nicht sichtbar. Dies gilt auch für die zugehörigen Übersetzungen."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen Sie einen Grund"])},
              "title_with_name": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Löschen Sie einen Grund \"", _interpolate(_list(0)), "\""])}
            },
            "right_scale_text_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechter Skalentext (optional)"])},
            "scale_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auf einer 5-stufigen Skala gelten 1 und 2 als negative Werte, 3 als neutral und 4 und 5 als positiv"])},
            "scale_text_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skalentext hinzufügen"])},
            "scale_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("type")), "-Skala"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewertungsskala"])}
          },
          "styling": {
            "logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA importiert Ihre Konversationen. Dieser Vorgang kann bis zu 24 Stunden dauern."])},
            "brand_name": {
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dies wird in der gesamten Umfrage als Personalisierungstoken verwendet."])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name der Marke"])}
            },
            "buttons_and_links": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schaltflächen und Links"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passen Sie das Erscheinungsbild der Umfrage an"])},
            "logo_background": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logohintergrund"])},
            "logo_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JPG-, PNG- und GIF-Formate werden unterstützt"])},
            "logo_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stil"])},
            "upload_logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo hochladen"])}
          },
          "thank_you": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stilvoll abmelden"])},
            "message_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie eine Nachricht ein, die Personen sehen sollen, nachdem sie ihr Feedback eingereicht haben"])},
            "message_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Danke-Nachricht"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vielen Dank"])}
          }
        },
        "errors": {
          "incorrect_color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falscher Hexadezimal-Farbcode"])},
          "incorrect_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falsche E-Mail-Adresse"])},
          "no_empty_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieses Feld darf nicht leer sein"])},
          "incorrect_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falsche URL"])}
        },
        "list": {
          "list_item": {
            "not_visible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht sichtbar"])},
            "visible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sichtbar:"])}
          },
          "heading": {
            "created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellungsdatum"])},
            "languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprachen"])},
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
            "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
            "surveys_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umfragen gesendet"])},
            "surveys_sent_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In den letzten 30 Tagen gesendete Umfragen"])}
          }
        },
        "toasts": {
          "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umfrage wurde gelöscht"])},
          "duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umfrage wurde dupliziert"])},
          "preview_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorschau der E-Mail gesendet"])},
          "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umfrage wurde veröffentlicht"])},
          "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umfrage wurde aktualisiert"])},
          "paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umfrage wurde ausgesetzt"])}
        },
        "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplizieren"])},
        "pause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anhalten"])},
        "pause_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umfrage aussetzen"])},
        "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorschau"])},
        "preview_modal": {
          "email_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie die E-Mail-Adresse ein, um die Umfragevorschau zu senden"])},
          "email_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre", "@", "E-Mail-Adresse.de"])},
          "language_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprache für Vorschau auswählen"])},
          "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorschau-E-Mail senden"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senden Sie eine Beispielumfrage an Ihre E-Mail-Adresse. Die Bewertungen aus der Umfragevorschau werden nicht gespeichert."])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vorschau der ", _interpolate(_named("type")), "-Umfrage anzeigen"])},
          "web_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umfragevorschau aus E-Mail im Web anzeigen"])}
        },
        "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veröffentlichen"])},
        "resume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fortsetzen"])},
        "save_as_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als Entwurf speichern"])},
        "snippets": {
          "brand_name": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name der Marke"])},
            "tooltip_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein markenspezifischer Name kann im Stil-Schritt hinzugefügt werden"])}
          },
          "customer_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name des Kunden"])},
          "support_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support-ID"])},
          "support_rep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supportmitarbeiter"])}
        },
        "statuses": {
          "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv"])},
          "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gelöscht"])},
          "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entwurf"])},
          "paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angehalten"])}
        },
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["CSAT-Umfrage von ", _interpolate(_list(0))])},
        "unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorgenommene nicht gespeicherte Änderungen"])},
        "update_and_resume_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umfrage aktualisieren und fortsetzen"])}
      },
      "preview": {
        "open_in_web": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wenn diese E-Mail nicht korrekt angezeigt wird, ", _interpolate(_named("link"))])},
        "suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich war mit dem Support, den ich erhalten habe, nicht zufrieden, weil..."])},
        "powered_by_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powered by Zendesk QA"])},
        "reasons_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grund auswählen"])},
        "recap": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zusammenfassung der Konversation"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beispiel: Der Kunde hatte Probleme beim Aktualisieren der Zahlungsmethode. Der Mitarbeiter hat einen Beitrag geteilt, der hilfreich sein könnte. Das hat funktioniert."])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unterstützt durch Zendesk QA-KI "])}
        },
        "terms_of_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allgemeine Geschäftsbedingungen (AGB)"])},
        "thanks_for_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vielen Dank für Ihr Feedback!"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E‑Mail"])},
        "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback"])},
        "feedback_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Was ist der Hauptgrund für Ihren Wert?"])},
        "feedback_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teilen Sie uns mit, warum Sie diese Bewertung abgegeben haben ..."])},
        "high_effort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoher Aufwand"])},
        "low_effort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geringer Aufwand"])},
        "open_in_web_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hier klicken, um die Umfrage zu öffnen"])},
        "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datenschutz"])},
        "question_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre angepasste Frage wird hier angezeigt"])},
        "score_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Sie gaben Ihrem ", _interpolate(_list(0)), "-Erlebnis einen Wert von"])},
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback einreichen"])},
        "thank_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vielen Dank"])},
        "unsubscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbestellen"])},
        "very_dissatisfied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sehr unzufrieden"])},
        "very_satisfied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sehr zufrieden"])}
      },
      "modal": {
        "duplicate_modal": {
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beachten Sie, dass der aktuelle Umfragestatus und die Zustellungsregeln nicht in das Duplikat der Umfrage übernommen werden."])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Umfrage \"", _interpolate(_list(0)), "\" duplizieren"])}
        },
        "pause": {
          "maybe_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn Sie das eingebettete E-Mail-Snippet verwenden, müssen Sie es aus Zendesk entfernen, bevor Sie die Umfrage anhalten."])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn die Umfrage ausgesetzt wird, werden keine Antworten erfasst."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umfrage aussetzen"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie müssen das Snippet aus Zendesk entfernen, bevor Sie die Umfrage aussetzen."])}
        },
        "delete": {
          "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umfrage löschen"])},
          "maybe_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn Sie das eingebettete E-Mail-Snippet verwenden, müssen Sie es aus Zendesk entfernen, bevor Sie die Umfrage löschen."])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durch diese Aktion wird die Umfrage endgültig gelöscht. Benutzer, die die Umfrage erhalten haben, können sie weiterhin beantworten. Alle Daten im Zusammenhang mit der Umfrage bleiben in Zendesk QA erhalten."])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Umfrage ", _interpolate(_named("surveyName")), " löschen"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie müssen das Snippet aus Zendesk entfernen, bevor Sie die Umfrage löschen."])}
        },
        "publish": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre vorgenommenen Änderungen sind für neue Umfrageteilnehmer sichtbar."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umfrage veröffentlichen"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie müssen nach der Veröffentlichung der Umfrage die neueste Version des eingebetteten Snippets kopieren."])}
        },
        "resume": {
          "maybe_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn Sie das eingebettete E-Mail-Snippet verwenden, müssen Sie es nach Fortsetzen der Umfrage in Zendesk kopieren."])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fahren Sie mit dem Versenden der Umfrage und dem Erfassen von Antworten fort."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umfrage aktualisieren und fortsetzen"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie müssen das eingebettete Snippet zu Zendesk hinzufügen, nachdem Sie die Umfrage fortgesetzt haben."])}
        },
        "update": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umfrage aktualisieren"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie müssen nach dem Aktualisieren der Umfrage die neueste Version des eingebetteten Snippets kopieren."])}
        }
      },
      "delete_modal": {
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie diese Umfrage wirklich löschen? Dieser Vorgang kann nicht rückgängig gemacht werden."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umfrage löschen?"])}
      }
    },
    "trial_ended": {
      "continue_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Testzeitraum ist abgelaufen, aber Sie können Zendesk QA weiterhin nutzen, wenn Sie auf ein Konto mit vollständigem Zugriff upgraden."])},
      "continue_zd_only_non_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Testzeitraum ist abgelaufen, aber Sie können Zendesk QA weiterhin nutzen, wenn Sie auf ein Konto mit vollständigem Zugriff upgraden. Sprechen Sie mit Ihrem lokalen Administrator, um es einzurichten."])},
      "title_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testzeitraum abgelaufen"])},
      "upgrade_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jetzt upgraden"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der kostenlose Testzeitraum ist abgelaufen."])},
      "continue_using": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um die Vollversion von Zendesk QA weiterhin zu verwenden, geben Sie Ihre Zahlungsdetails ein."])},
      "continue_using_non_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Damit Sie die Vollversion von Zendesk QA weiterhin verwenden können, muss Ihr Kontoadministrator Zahlungsdetails eingeben."])},
      "manage_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlung verwalten"])}
    },
    "workspaces": {
      "assignments": {
        "read_more_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.klausapp.com/en/articles/4943707-assignments"])},
        "a_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["einem Benutzer"])},
        "details": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Erstellt am ", _interpolate(_named("date")), " von ", _interpolate(_named("authorName"))])},
        "form": {
          "assignment_period": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Zuweisung ", _interpolate(_list(0))])},
          "discard_changes_dialog": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie scheinen noch daran zu arbeiten. Wenn Sie die Seite verlassen, ohne die Änderungen zu speichern, gehen alle bis dahin hinzugefügten Informationen verloren."])}
          },
          "duration": {
            "label": {
              "recurring": {
                "bi_weekly": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Diese Zuweisung wird jeden zweiten ", _interpolate(_named("weekDay")), " wiederholt"])},
                "weekly": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Diese Zuweisung wird immer am ", _interpolate(_named("weekDay")), " wiederholt"])}
              }
            }
          },
          "steps_summary": {
            "all_covered_in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Alle geprüften Personen in einer Periode"]), _normalize(["Alle geprüften Personen in ", _interpolate(_named("nr")), " Perioden"])])},
            "nr_conversations": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " Konversationen"]), _normalize([_interpolate(_named("n")), " Konversation"]), _normalize([_interpolate(_named("n")), " Konversationen"])])},
            "of_agents": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["von ", _interpolate(_named("nr")), " Agenten"]), _normalize(["von ", _interpolate(_named("nr")), " Agent"]), _normalize(["von ", _interpolate(_named("nr")), " Agenten"])])},
            "participants": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Teilnehmer"]), _normalize(["Teilnehmer"])])},
            "per_cycle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " Konversationen pro Periode"]), _normalize([_interpolate(_named("n")), " Konversation pro Periode"]), _normalize([_interpolate(_named("n")), " Konversationen pro Periode"])])},
            "per_reviewee_in_row": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("reviewsCount")), " pro geprüfter Person in Folge"])},
            "reviewees": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Geprüfte Person"]), _normalize(["Geprüfte Personen"])])},
            "reviewers": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Prüfer"]), _normalize(["Prüfer"])])}
          },
          "reviewees_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geprüfte Personen"])},
          "reviewers_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prüfer"])},
          "steps": {
            "participants": {
              "all_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Arbeitsbereich-Benutzer"])},
              "all_users_except_agents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Arbeitsbereich-Benutzer außer Mitarbeitern"])}
            }
          }
        },
        "preview_cycle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Periode ", _interpolate(_named("cycleNumber")), " • ", _interpolate(_named("startDate")), " - ", _interpolate(_named("endDate"))])},
        "preview_cycle_non_recurring": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("startDate")), " – ", _interpolate(_named("endDate"))])},
        "preview_non_recurring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nicht wiederkehrend"])},
        "preview_subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Zuweisung ", _interpolate(_list(0))])},
        "reviewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wird überprüft"])},
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Infos zu Zuweisungen"])},
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zuweisung erstellen"])},
        "delete_dialog_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durch diese Aktion wird die Zuweisung dauerhaft gelöscht und die zugehörigen Daten werden vom Dashboard entfernt. Alle zugehörigen Überprüfungen verbleiben in Zendesk QA."])},
        "delete_dialog_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Zuweisung ", _interpolate(_named("assignmentName")), " löschen"])},
        "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zuweisung gelöscht"])},
        "duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zuweisung dupliziert"])},
        "empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noch keine Zuweisungen hinzugefügt"])},
        "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler beim Erstellen der Zuweisung"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name der Zuweisung"])}
      },
      "assignments_v_2": {
        "header": {
          "changes": {
            "immediate": {
              "help_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Periode wird aktualisiert und eine neue Aufgabenliste wird erstellt. Bereits abgegebene Überprüfungen werden weiterhin für das Ziel berücksichtigt."])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sofort"])}
            },
            "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veröffentlichen"])},
            "next": {
              "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Zu Beginn der nächsten Periode am ", _interpolate(_named("startDate"))])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wann sollen Änderungen veröffentlicht werden?"])}
          },
          "inactive_changes": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An dieser Zuweisung vorgenommene Änderungen werden mit dieser Zuweisung veröffentlicht"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zuweisung als aktiv festlegen?"])}
          },
          "title_base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zuweisungen"])}
        },
        "steps": {
          "general": {
            "cycle_settings": {
              "repeats": {
                "recurring_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wiederkehrend am Tag des Zuweisungsbeginns"])},
                "friday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erster Freitag im Monat"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zweiter Freitag im Monat"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dritter Freitag im Monat"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vierter Freitag im Monat"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letzter Freitag im Monat"])}
                },
                "monday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erster Montag im Monat"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zweiter Montag im Monat"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dritter Montag im Monat"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vierter Montag im Monat"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letzter Montag im Monat"])}
                },
                "saturday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erster Samstag im Monat"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zweiter Samstag im Monat"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dritter Samstag im Monat"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vierter Samstag im Monat"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letzter Samstag im Monat"])}
                },
                "sunday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erster Sonntag im Monat"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zweiter Sonntag im Monat"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dritter Sonntag im Monat"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vierter Sonntag im Monat"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letzter Sonntag im Monat"])}
                },
                "thursday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erster Donnerstag im Monat"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zweiter Donnerstag im Monat"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dritter Donnerstag im Monat"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vierter Donnerstag im Monat"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letzter Donnerstag im Monat"])}
                },
                "tuesday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erster Dienstag im Monat"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zweiter Dienstag im Monat"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dritter Dienstag im Monat"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vierter Dienstag im Monat"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letzter Dienstag im Monat"])}
                },
                "wednesday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erster Mittwoch im Monat"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zweiter Mittwoch im Monat"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dritter Mittwoch im Monat"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vierter Mittwoch im Monat"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letzter Mittwoch im Monat"])}
                },
                "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angepasste Wiederholung"])},
                "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag"])},
                "first_of_every_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeder 1. eines Monats"])},
                "indicator": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anfang"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sekunde"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vierte"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ende"])}
                },
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wiederholungen"])},
                "weekday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wochentag"])}
              },
              "end_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ende"])},
              "start_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anfang"])},
              "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Ergebnisse"])},
              "timezone_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeitzone"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perioden-Einstellungen"])}
            },
            "basic_info": {
              "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name der Zuweisung"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abschnitt „Basic information“"])}
            },
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zu Beginn jeder Zuweisungsperiode wird eine vordefinierte Liste mit Konversationen zur Bewertung erstellt."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allgemein"])}
          },
          "goal": {
            "advanced_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erweiterte Einstellungen"])},
            "fill_goal": {
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatisches Abrufen neuer Konversationen, wenn das Überprüfungsziel nicht erreicht wird"])},
              "help_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatisches Abrufen ist bei prozentualen (%) Überprüfungszielen nicht möglich"])}
            },
            "integer_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muss eine ganze Zahl sein, keine Dezimalzahl"])},
            "replacing_conversations": {
              "text_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ersetzen von Konversationen zulassen (Rolle „Prüfer“ und höher)"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ersetzen von Konversationen"])}
            },
            "self_reviews": {
              "description_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erlauben, dass Prüfern eigene Gespräche zur Prüfung zugewiesen werden"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selbstüberprüfungen"])}
            },
            "minimum": {
              "append": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewertungen"])},
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Mindestanzahl an Überprüfungen muss mindestens 1 betragen"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mindestanzahl an Überprüfungen für jede geprüfte Person festlegen"])}
            },
            "review_goal": {
              "options": {
                "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversationen"])},
                "percent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prozent (%)"])},
                "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewertungen"])}
              },
              "reviewer": {
                "append": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewertungen insgesamt"])},
                "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeder Prüfer muss eine bestimmte Anzahl von Überprüfungen durchführen"])}
              },
              "conversation": {
                "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prüfer müssen insgesamt eine bestimmte Anzahl an Konversationen überprüfen"])}
              },
              "errors": {
                "at_least": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Mindestanzahl an Überprüfungen muss mindestens 1 betragen"])},
                "between": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Ziel für Überprüfungen muss zwischen 1 und 100 % liegen"])}
              },
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ziel für Überprüfungen"])},
              "reviewee": {
                "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bei jeder geprüften Person muss eine Mindestanzahl an Konversationen überprüft werden"])}
              }
            },
            "approach": {
              "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prüfer können Konversationen aus dem gemeinsamen Pool auswählen"])},
              "assigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prüfern werden Konversationen aus dem gemeinsamen Pool zugewiesen"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zuweisungsansatz"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ziel"])}
          },
          "participants": {
            "reviewees": {
              "all_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Bots"])},
              "all_participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Teilnehmer des Arbeitsbereichs"])},
              "bot_reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bots"])},
              "user_reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer"])},
              "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Arbeitsbereich-Benutzer"])},
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geprüfte Personen hinzufügen"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wer wird geprüft?"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geprüfte Personen auswählen"])}
            },
            "reviewees_type": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie aus, wen Sie überprüfen möchten"])}
            },
            "exclude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausschlüsse sind nur zulässig, wenn alle Benutzer des Arbeitsbereichs oder eine Gruppe ausgewählt sind"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prüfer und geprüfte Personen"])},
            "type_of_users": {
              "workspace_default": {
                "help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Aktuell: ", _interpolate(_list(0))])},
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeitsbereich-Standard"])}
              },
              "assignee": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitarbeiter"])}
              },
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welche Benutzertypen sollten als geprüfte Personen ausgewählt werden?"])},
              "most_active_agent": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivster Mitarbeiter"])}
              },
              "participant": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teilnehmer"])}
              }
            },
            "dropdown_sections": {
              "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bots"])},
              "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppen"])},
              "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer"])}
            },
            "exclude_reviewees": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geprüfte Personen ausschließen (optional)"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer auswählen, die ausgeschlossen werden sollen"])}
            },
            "exclude_reviewers": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prüfer ausschließen (optional)"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer auswählen, die ausgeschlossen werden sollen"])}
            },
            "reviewers": {
              "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Arbeitsbereich-Benutzer außer Mitarbeitern"])},
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prüfer hinzufügen"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wer führt die Überprüfungen durch?"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prüfer auswählen"])}
            },
            "self_reviews": {
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selbstüberprüfungen sind in diesem Arbeitsbereich nicht zulässig"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selbstüberprüfungen zulassen"])},
              "not_allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selbstüberprüfungen sind in diesem Arbeitsbereich nicht zulässig."])}
            }
          },
          "preview": {
            "matching_conversations": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Passende Konversation heute"]), _normalize(["Passende Konversationen heute"])])},
            "description": {
              "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wird angezeigt, als hätte die Zuweisung heute begonnen."])},
              "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zukünftige Werte können abweichen."])}
            },
            "no_reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine geprüften Personen ausgewählt"])},
            "no_reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Prüfer ausgewählt"])},
            "reviewees": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Geprüfte Person"]), _normalize(["Geprüfte Personen"])])},
            "reviewers": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Prüfer"]), _normalize(["Prüfer"])])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorschau"])}
          },
          "summary": {
            "auto_fetching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatisches Abrufen: Zulässig, wenn nicht genügend Konversationen vorhanden sind"])},
            "cycle_friday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " freitags ab ", _interpolate(_named("startDateTime"))])},
            "cycle_monday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " montags ab ", _interpolate(_named("startDateTime"))])},
            "cycle_saturday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " samstags ab ", _interpolate(_named("startDateTime"))])},
            "cycle_sunday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " sonntags ab ", _interpolate(_named("startDateTime"))])},
            "cycle_thursday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " donnerstags ab ", _interpolate(_named("startDateTime"))])},
            "cycle_tuesday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " dienstags ab ", _interpolate(_named("startDateTime"))])},
            "cycle_wednesday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " mittwochs ab ", _interpolate(_named("startDateTime"))])},
            "cycle_daily": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Täglich von ", _interpolate(_named("startDateTime"))])},
            "cycle_never": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Startet um ", _interpolate(_named("startDateTime")), " und endet um ", _interpolate(_named("endDateTime")), "."])},
            "date_conditions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " Datumsbedingungen"])},
            "other_conditions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " Konversations- und Help-Desk-Bedingungen"])},
            "other_conditions_no_conversation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Keine Konversation und ", _interpolate(_named("count")), " Help-Desk-Bedingung"]), _normalize(["Keine Konversation und ", _interpolate(_named("count")), " Help-Desk-Bedingungen"])])},
            "other_conditions_no_helpdesk_condition": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " Konversation und keine Help-Desk-Bedingung"]), _normalize([_interpolate(_named("count")), " Konversationen und keine Help-Desk-Bedingung"])])},
            "approach": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Ansatz: Prüfer können Konversationen auswählen"]), _normalize(["Ansatz: Prüfern werden Konversationen zugewiesen"])])},
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beheben Sie die in der Zusammenfassung markierten Fehler, um fortzufahren."])},
            "goal": {
              "conversation": {
                "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ziel: Prüfer müssen alle Konversationen überprüfen"])},
                "conversations": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Ziel: Prüfer müssen eine Konversation überprüfen"]), _normalize(["Ziel: Prüfer müssen ", _interpolate(_named("count")), " Konversationen überprüfen"])])},
                "percent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ziel: Prüfer müssen ", _interpolate(_named("count")), " % der Konversationen überprüfen"])}
              },
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ziel: Kein Überprüfungsziel festgelegt"])},
              "reviewee": {
                "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ziel: Alle Konversationen aller geprüften Personen müssen überprüft werden"])},
                "conversations": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ziel: ", _interpolate(_named("count")), " von allen Konversationen aller geprüften Personen müssen überprüft werden"])},
                "percent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ziel: ", _interpolate(_named("count")), " % aller Konversationen aller geprüften Personen müssen überprüft werden"])}
              },
              "reviewer": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Ziel: Jeder Prüfer muss eine Überprüfung durchführen"]), _normalize(["Ziel: Jeder Prüfer muss ", _interpolate(_named("count")), " Überprüfungen durchführen"])])}
            },
            "replacing": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Ersetzen: Prüfer können keine Konversationen ersetzen"]), _normalize(["Ersetzen: Prüfer können Konversationen ersetzen"])])},
            "self_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selbstüberprüfung: Prüfer können eigenen Konversationen zugewiesen werden"])},
            "no_date_conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Datumsbedingungen festgelegt"])},
            "no_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kein Name für die Zuweisung festgelegt"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zusammenfassung"])}
          },
          "conditions": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedingungen"])}
          }
        },
        "date_weekday_from_at_time": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("weekday")), " von ", _interpolate(_named("dateTime"))])},
        "form": {
          "cycle_label_bi_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle zwei Wochen"])},
          "cycle_label_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Täglich"])},
          "cycle_label_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monatlich"])},
          "cycle_label_one_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie"])},
          "cycle_label_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wöchentlich"])},
          "cycle_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Periodenlänge"])}
        },
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auf „Aktiv“ setzen"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als Entwurf speichern"])},
        "drafted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zuweisung wurde als Entwurf gespeichert"])},
        "inactivated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zuweisung wurde auf „Inaktiv“ gesetzt"])},
        "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auf „Inaktiv“ setzen"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zuweisung erstellen"])},
        "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zuweisung erstellt"])},
        "mark_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als aktiv markieren"])},
        "mark_inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als inaktiv markieren"])},
        "next_cycle_warning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Die ausstehenden Aktualisierungen der Zuweisung treten in der nächsten Periode in Kraft, beginnend am ", _interpolate(_list(0)), "."])},
        "publish_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Änderungen veröffentlichen"])},
        "status": {
          "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv"])},
          "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entwurf"])},
          "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inaktiv"])},
          "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wartend"])}
        },
        "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zuweisung aktualisieren"])},
        "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zuweisung aktualisiert"])}
      },
      "rating_categories": {
        "edit": {
          "auto_categories": {
            "closing": {
              "klaus_model_description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysiert, ob der Agent oder Bot die Konversation abgeschlossen hat"])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysiert, ob der Agent die Konversation freundlich abgeschlossen hat"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysiert, ob die Konversation abgeschlossen wurde"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abschluss"])}
            },
            "grammar": {
              "klaus_model_description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysiert Grammatik-, Rechtschreib- und Stilfehler in Agenten- und Bot-Interaktionen"])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysiert Grammatik-, Rechtschreib- und Stilfehler des Agenten"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysiert Grammatik-, Rechtschreib- und Stilfehler des Agenten"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechtschreibung und Grammatik"])}
            },
            "greeting": {
              "klaus_model_description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysiert die Konversation auf Begrüßungen durch Agenten und Bots"])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysiert die gesamte Konversation auf typische Begrüßungssätze"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysiert die gesamte Konversation auf typische Grußformeln"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruß"])}
            },
            "empathy": {
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysiert, ob der Agent die Gefühle des Kunden versteht und anerkennt"])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifiziert Fälle, in denen der Agent die Kundenstimmung gespiegelt oder zugewandte Begriffe zur Bestätigung und Anerkennung verwendet hat."])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empathie"])}
            },
            "issue_understanding": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysiert, ob der Agent die Frage oder das Anliegen des Kunden versteht"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysiert, ob der Agent die Frage oder das Anliegen des Kunden versteht"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verständnis"])}
            },
            "readability": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysiert, wie leicht ein Text unter Berücksichtigung der Wortkomplexität und der Satzlänge verstanden werden kann"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysiert, wie leicht ein Text unter Berücksichtigung der Wortkomplexität und der Satzlänge verstanden werden kann"])},
              "long_sentences_count": {
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Nachricht enthält 1 zu langen Satz, was das Lesen erschwert"])},
                "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Diese Nachricht enthält ", _interpolate(_list(0)), " zu lange Sätze, was das Lesen erschwert"])}
              },
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lesbarkeit"])}
            },
            "solution": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysiert die gesamte Konversation im Hinblick auf eine angebotene Lösung"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysiert die gesamte Konversation im Hinblick auf eine angebotene Lösung"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angebotene Lösung"])}
            },
            "tone": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysiert den Ton des Agenten während der Konversation"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysiert den Ton des Agenten während der Konversation"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Gesprächston"])}
            }
          },
          "auto_qa_rating_descriptions": {
            "klaus_model": {
              "grammar": {
                "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Fehler in den Nachrichten des Agenten erkannt"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es konnten keine Grammatikfehler festgestellt werden"])}
              },
              "greeting": {
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Begrüßung erkannt. Fügen Sie bei Bedarf über Ihren Administrator in den Einstellungen neue hinzu."])}
              },
              "closing": {
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Mitarbeiter hat die Konversation höflich abgeschlossen"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Abschlussphrase erkannt. Fügen Sie bei Bedarf über den Administrator in den Einstellungen neue hinzu"])}
              },
              "empathy": {
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Mitarbeiter hat die Stimmung des Kunden gespiegelt oder mitfühlende Worte verwendet"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es konnte nicht erkannt werden, ob der Mitarbeiter die Stimmung des Kunden gespiegelt oder mitfühlende Worte verwendet hat"])}
              },
              "readability": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Mitarbeiter verwendete lange Sätze und komplexe Wörter, was für manche Kunden möglicherweise schwer zu verstehen war"])},
                "1": {
                  "complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Mitarbeiter verwendete komplexe Wörter, was für manche Kunden möglicherweise schwer zu verstehen war"])},
                  "long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Mitarbeiter verwendete lange Sätze, was für manche Kunden möglicherweise schwer zu verstehen war"])}
                },
                "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Mitarbeiter verwendete eine leicht verständliche Sprache"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht genügend Daten zum Bewerten dieser Kategorie"])}
              }
            },
            "open_ai": {
              "issue_understanding": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Agent hatte Schwierigkeiten, die Frage/Anfrage des Kunden zu verstehen"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Agent hat die Frage/Anfrage des Kunden gut verstanden"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es konnte nicht erkannt werden, ob der Agent die Frage/Anfrage des Kunden verstanden hat"])}
              },
              "tone": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ton des Mitarbeiters bei der Konversation"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ton des Mitarbeiters bei der Konversation"])},
                "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ton des Mitarbeiters bei der Konversation"])},
                "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ton des Mitarbeiters bei der Konversation"])},
                "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ton des Mitarbeiters bei der Konversation"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Ton des Mitarbeiters konnte nicht festgestellt werden"])}
              },
              "closing": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Konversation wurde nicht abgeschlossen"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Konversation wurde abgeschlossen"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es konnte nicht erkannt werden, ob die Konversation abgeschlossen wurde"])}
              },
              "empathy": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dem Mitarbeiter fehlte es an Empathie für den Kunden und seine Probleme"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Mitarbeiter zeigte Empathie für den Kunden und seine Probleme"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es konnte nicht erkannt werden, ob der Mitarbeiter Empathie für den Kunden und seine Probleme zeigte "])}
              },
              "greeting": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Kunde wurde bei der Konversation nicht begrüßt"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Kunde wurde bei der Konversation begrüßt"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es konnte keine typische Begrüßung erkannt werden"])}
              },
              "solution": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Mitarbeiter hat dem Kunden keine Lösung angeboten"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Mitarbeiter hat dem Kunden eine Lösung angeboten"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es konnte nicht erkannt werden, ob der Mitarbeiter dem Kunden eine Lösung angeboten hat"])}
              }
            }
          },
          "auto_root_cause": {
            "complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komplexe Wörter"])},
            "long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lange Sätze"])},
            "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grammatik"])},
            "misspelling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechtschreibfehler"])},
            "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negativ"])},
            "neutral_sorry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entschuldigend"])},
            "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonstiges"])},
            "pos_calm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ruhig"])},
            "pos_cheerful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fröhlich"])},
            "pos_inquisitive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neugierig"])},
            "pos_professional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professionell"])},
            "pos_supportive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hilfsbereit"])},
            "style": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erscheinungsbild"])}
          },
          "account_settings_info": {
            "closing": {
              "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 Abschluss"]), _normalize([_interpolate(_named("n")), " Abschlüsse"])])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA prüft nur genehmigte Abschlüsse. Wenn diese Kategorie als kritisch eingestuft ist, schlägt die Überprüfung fehl, wenn keine genehmigten Abschlüsse verwendet werden."])},
              "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genehmigte Abschlüsse"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genehmigte Abschlüsse"])}
            },
            "grammar": {
              "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 Ausnahme"]), _normalize([_interpolate(_named("n")), " Ausnahmen"])])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wörter und Phrasen, die AutoQA nicht als Rechtschreib- oder Grammatikfehler einstuft."])},
              "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausnahmen für Rechtschreibung und Grammatik"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA-Ausnahmen"])}
            },
            "greeting": {
              "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 Begrüßung"]), _normalize([_interpolate(_named("n")), " Begrüßungen"])])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA prüft nur auf genehmigte Begrüßungen. Wenn diese Kategorie als kritisch eingestuft ist, schlägt die Überprüfung fehl, wenn keine genehmigten Begrüßungen verwendet werden."])},
              "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genehmigte Begrüßungen"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genehmigte Begrüßungen"])}
            }
          },
          "auto_qa_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA-Kategorie"])},
          "auto_qa_category_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie eine AutoQA-Kategorie aus"])},
          "auto_qa_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatisch zugewiesene Hauptursachen:"])},
          "auto_qa_consent_hint": {
            "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto → Einstellungen"])},
            "approved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Einige Kategorien nutzen MS Azure und bieten eine erweiterte Palette von Funktionen und unterstützten Sprachen. Gehen Sie zu Ihren ", _interpolate(_named("link")), ", um weitere Informationen zu erhalten, oder wenden Sie sich dazu an einen Administrator."])},
            "removed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Nutzen Sie dank MS Azure eine erweiterte Liste von Kategorien und mehr unterstützte Sprachen. Gehen Sie zu Ihren ", _interpolate(_named("link")), " oder wenden Sie sich dazu an einen Administrator."])}
          },
          "auto_qa_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA verwenden"])},
          "auto_qa_toggle_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["AutoQA-Kategorien wird anhand einer festen Bewertungsskala automatisch eine Bewertung zugewiesen. Sie können die Bewertung später manuell anpassen. ", _interpolate(_named("link"))])},
          "choose_auto_qa_category_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie eine AutoQA-Kategorie aus"])},
          "auto_qa_causes_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ein automatisch zugewiesener Wert hängt vom Schweregrad und der Anzahl der Fehler ab. ", _interpolate(_named("link"))])},
          "manual_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hauptursachen"])}
        },
        "categories_type_modal": {
          "explanation_dynamic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Für Prüfer werden nur relevante Bewertungskategorien angezeigt. Bewertungskategorien werden basierend auf den Bedingungen angezeigt, die Sie beim Erstellen einer Kategorie festgelegt haben."])},
          "scorecard": {
            "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chatten"])},
            "onboarding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onboarding"])},
            "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon"])}
          },
          "cta": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wählen Sie den Typ der Bewertungskategorie für den Arbeitsbereich „", _interpolate(_named("workspace")), "“ aus."])},
          "explanation_static": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prüfer müssen die richtige Scorecard manuell auswählen, um relevante Bewertungskategorien für jede Konversation zu sehen."])},
          "prompt": {
            "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie den Typ der Bewertungskategorie wirklich ändern?"])},
            "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorien ändern"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ der Bewertungskategorie ändern?"])}
          },
          "radio_label_dynamic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedingte Bewertungskategorie"])},
          "radio_label_static": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statische Bewertungskategorie"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ der Bewertungskategorie"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einstellungen für Bewertungskategorien"])}
        },
        "category_delete_confirmation": {
          "action_cannot_be_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Vorgang kann nicht rückgängig gemacht werden."])},
          "delete_all_the_ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["alle Bewertungen löscht"])},
          "keep_statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können die Statistiken auch beibehalten, ohne Daten zu löschen, indem Sie eine Kategorie archivieren."])},
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Mir ist klar, dass dies ", _interpolate(_named("deleteAllRatings")), ", die für diese Kategorie angegeben sind."])}
        },
        "description_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["z. B. Gesprächston ist perfekt"])},
        "filter": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie nur anzeigen, wenn"])},
          "always_conflict": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"Immer\" wird ignoriert. Die Bedingung ist nicht mit anderen Bedingungen kombinierbar."])},
          "option": {
            "always": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immer"])},
            "helpdesk_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help-Desk-Stichwörter"])},
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedingung auswählen"])},
            "satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zufriedenheitswert (CSAT)"])},
            "source_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quellentyp"])},
            "ticket_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversationskanal"])}
          }
        },
        "category_needs_name_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Kategorien müssen einen Namen besitzen"])},
        "category_needs_scorecard_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie zu einer Scorecard hinzufügen"])},
        "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie erstellen"])},
        "add_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie erstellen"])},
        "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivieren"])},
        "category_added_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewertungskategorie hinzugefügt"])},
        "category_archive_confirmation_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine archivierte Kategorie ist in der Scorecard nicht sichtbar, ermöglicht aber das Beibehalten von Statistiken, ohne dass Daten gelöscht werden."])},
        "category_archive_confirmation_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie archivieren"])},
        "category_archive_confirmation_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Kategorie ", _interpolate(_list(0)), " archivieren"])},
        "category_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewertungskategorie archiviert"])},
        "category_delete_confirmation_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie löschen"])},
        "category_delete_confirmation_hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie \"Kategorie löschen\" ein, um diese Kategorie zu löschen"])},
        "category_delete_confirmation_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie löschen"])},
        "category_delete_confirmation_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Kategorie ", _interpolate(_list(0)), " löschen"])},
        "category_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewertungskategorie gelöscht"])},
        "category_duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewertungskategorie dupliziert"])},
        "category_group_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewertungskategorie-Gruppe hinzugefügt"])},
        "category_group_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewertungskategorie-Gruppe gelöscht"])},
        "category_group_order_changed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reihenfolge der Bewertungskategorie-Gruppen geändert"])},
        "category_group_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewertungskategorie-Gruppe aktualisiert"])},
        "category_unarchived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewertungskategorie wieder aus Archiv entfernt"])},
        "category_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewertungskategorie aktualisiert"])},
        "critical_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn ein Mitarbeiter eine kritische Kategorie nicht besteht, gelten automatisch alle Kategorien in dieser Überprüfung als nicht bestanden."])},
        "critical_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kritische Bewertungskategorie"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibung"])},
        "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplizieren"])},
        "empty_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Kategorien in dieser Gruppe"])},
        "form_title_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Bewertungskategorie erstellen"])},
        "form_title_base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard"])},
        "form_title_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Kategorie bearbeiten"])},
        "group_delete_confirmation_checkbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Kategorien löschen und Daten in dieser Gruppe überprüfen"])},
        "group_delete_confirmation_intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn Sie die Kategoriegruppe löschen, wird die Gruppierung aller damit verknüpften Kategorien aufgehoben."])},
        "group_delete_confirmation_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kategoriegruppe ", _interpolate(_named("categoryName")), " löschen"])},
        "group_delete_confirmation_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn Sie die Kategorien in der Gruppe löschen, gehen alle mit diesen Kategorien verknüpften Überprüfungsdaten verloren."])},
        "group_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppenname"])},
        "multiple_reasons_checkbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prüfer können mehrere Gründe als Hauptursache auswählen"])},
        "multiple_reasons_explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prüfer können mehrere Gründe als Hauptursache auswählen"])},
        "multiple_reasons_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehrere Gründe"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["z. B. Ton"])},
        "new_group_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Kategoriegruppe hinzufügen"])},
        "new_group_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Gruppe"])},
        "reason_add_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"Sonstige\" hinzufügen"])},
        "reason_add_root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hauptursachen hinzufügen"])},
        "reason_duplicate_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Hauptursache ist bereits vorhanden"])},
        "reason_empty_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hauptursachen hinzufügen"])},
        "reason_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hauptursachen hinzufügen, um Bewertung zu erklären"])},
        "reason_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonstige ..."])},
        "scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewertungsskala"])},
        "tabs": {
          "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv"])},
          "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archiviert"])}
        },
        "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecards"])},
        "tags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecards"])},
        "tags_label_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecards zu dieser Bewertungskategorie hinzufügen"])},
        "unarchive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wieder aus dem Archiv entfernen"])},
        "update_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie aktualisieren"])},
        "visibility": {
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Bewertungen"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angezeigt für"])},
          "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negative Bewertungen"])},
          "negative_and_neutral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negative und neutrale Bewertungen"])}
        },
        "warnings": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja, aktualisieren"])}
        },
        "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gewichtung"])},
        "weight_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorien, die für Sie wichtiger sind, können bei der Berechnung des Ticketwerts stärker gewichtet werden."])},
        "weight_label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Gewichtung (", _interpolate(_list(0)), ")"])}
      },
      "scorecard": {
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to account"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can view and edit your scorecards in the <strong>Account</strong> section."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecards are now managed in your account"])}
      },
      "calibration": {
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Infos zur Kalibrierung"])},
        "upsell": {
          "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Infos zur Kalibrierung"])},
          "advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erweitert"])},
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durch regelmäßige Kalibrierungssitzungen können die Prüfer ihre Bewertungsmethoden abstimmen und sicherstellen, dass die Mitarbeiter konsistentes und unvoreingenommenes Feedback erhalten."])},
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pläne anzeigen"])},
          "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrierung"])},
          "introduction": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Die ", _interpolate(_list(0)), " hilft Ihrem Team, immer zu wissen, wie alle Kundeninteraktionen gehandhabt und bewertet werden sollten."])},
          "professional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professionell"])},
          "summary": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Die Kalibrierung ist für unsere ", _interpolate(_list(0)), "- und ", _interpolate(_list(1)), "-Pläne verfügbar."])}
        },
        "copy_existing_review": {
          "no": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bereits abgegebene Überprüfungen werden nicht angezeigt, wenn eine Konversation zur Sitzung hinzugefügt wird."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht sichtbar"])}
          },
          "yes": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bereits abgegebene Überprüfungen werden als Kalibrierungsüberprüfungen betrachtet. Nur eine Konversationsüberprüfung pro Prüfer wird zur Sitzung hinzugefügt. Nachrichtenspezifische Überprüfungen werden nicht angezeigt."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sichtbar"])}
          }
        },
        "copy_existing_review_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sichtbarkeit bereits abgegebener Überprüfungen"])},
        "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Die Kalibrierung hilft Ihrem Team, immer zu wissen, wie alle Kundeninteraktionen gehandhabt und bewertet werden sollten. ", _interpolate(_named("link"))])},
        "enabled_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrierung"])},
        "lead": {
          "all": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer haben unbegrenzten Zugriff auf alle Überprüfungen, die bei einer Kalibrierungssitzung abgegeben werden."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immer alle Überprüfungen anzeigen"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lead"])},
          "own": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nach dem Einreichen einer Überprüfung werden Benutzern die Überprüfungen anderer Prüfer angezeigt, die bei einer Kalibrierungssitzung abgegeben wurden."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Kalibrierungsüberprüfungen nach dem Einreichen einer Überprüfung anzeigen"])}
          }
        },
        "manager": {
          "all": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer haben unbegrenzten Zugriff auf alle Überprüfungen, die bei einer Kalibrierungssitzung abgegeben werden."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immer alle Überprüfungen anzeigen"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manager"])},
          "own": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nach dem Einreichen einer Überprüfung werden Benutzern die Überprüfungen anderer Prüfer angezeigt, die bei einer Kalibrierungssitzung abgegeben wurden."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Kalibrierungsüberprüfungen nach dem Einreichen einer Überprüfung anzeigen"])}
          }
        },
        "reviewer": {
          "all": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nach dem Einreichen einer Überprüfung werden Benutzern die Überprüfungen anderer Prüfer angezeigt, die bei einer Kalibrierungssitzung abgegeben wurden."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Kalibrierungsüberprüfungen nach dem Einreichen einer Überprüfung anzeigen"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prüfer"])},
          "own": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer sehen nur ihre eigenen Überprüfungen, die sie bei einer Kalibrierungssitzung abgegeben haben."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nur eigene Überprüfungen anzeigen"])}
          }
        },
        "settings_updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrierungseinstellungen aktualisiert"])},
        "visibility_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sichtbarkeit von Kalibrierungsüberprüfungen"])}
      },
      "threshold_explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legen Sie den Schwellenwert für Dashboard-Metriken fest, indem Sie die Lücken gemäß dem internen Metriksystem Ihres Teams füllen. Überprüfen Sie den Schwellenwert im Dashboard."])},
      "settings_titles": {
        "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zuweisungen"])},
        "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrierung"])},
        "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbindungen"])},
        "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allgemein"])},
        "hashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtags"])},
        "members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitglieder"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard"])},
        "threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schwellenwert"])}
      },
      "threshold_updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schwellenwert aktualisiert"])}
    },
    "integrate": {
      "error": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einen Moment..."])},
        "retry_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Um weitere Hilfe zu erhalten, müssen Sie über Administratorberechtigungen und ", _interpolate(_named("retry")), " oder ", _interpolate(_named("contact")), " verfügen."])},
        "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktieren Sie uns einfach"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir konnten nicht auf die API Ihres Help Desks zugreifen."])},
        "retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erneut versuchen"])}
      },
      "callback_redirect_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir sind dabei, Ihren Help Desk einzurichten. Sie werden gleich weitergeleitet."])},
      "callback_success_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " autorisiert"])},
      "invalid": {
        "action_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Kehren Sie zu ", _interpolate(_list(0)), " zurück, um eine neue Help-Desk-Verbindung hinzuzufügen."])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben womöglich einen oder zwei Schritte ausgelassen."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine Organisation mit der gleichen E-Mail-Domäne existiert bereits"])},
        "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbindungen-Seite"])}
      },
      "callback_waiting_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In der Zwischenzeit ..."])},
      "loading": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte warten Sie einen Augenblick, während wir Ihre Berechtigungen überprüfen."])},
        "meanwhile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In der Zwischenzeit ..."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integration läuft"])}
      }
    },
    "unconfigured_account": {
      "to_continue_using_klaus_manager": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["einen Arbeitsbereich erstellen"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Um Zendesk QA weiterhin zu verwenden, ", _interpolate(_named("link")), "."])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fast einsatzbereit"])},
      "no_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kein Arbeitsbereich gefunden"])},
      "switch_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto wechseln"])},
      "to_continue_using_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Damit Sie Zendesk QA weiterhin verwenden können, muss Ihr Manager Sie einem Arbeitsbereich zuweisen."])}
    },
    "danger": {
      "description": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wenn Sie Ihr Konto löschen, werden alle Daten, die wir zu Ihnen besitzen, dauerhaft gelöscht – ", _interpolate(_named("bold"))])},
        "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["alle angegebenen Bewertungen, Konversationsinhalte, Arbeitsbereiche usw."])}
      }
    },
    "notifications": {
      "slack": {
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zu Slack hinzufügen"])},
        "disconnect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trennen"])},
        "disconnect_dialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn die Integration getrennt wird, werden alle Zendesk QA-Benachrichtigungen über Slack deaktiviert"])},
        "disconnect_slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack trennen"])},
        "disconnect_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Slack-Integration wurde von Zendesk QA getrennt."])}
      },
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Definieren Sie standardmäßige Benachrichtigungseinstellungen für alle neuen Kontobenutzer."])},
      "override_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einstellungen für alle Benutzer überschreiben"])},
      "override_settings_dialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie sind im Begriff, die Benachrichtigungseinstellungen für alle Kontobenutzer zu überschreiben"])},
      "override_settings_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer können keine eigenen Benachrichtigungseinstellungen definieren."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benachrichtigungen"])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benachrichtigungseinstellungen aktualisiert"])},
      "webhooks": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webhooks"])},
        "trigger_critical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warnung, wenn eine kritische Kategorie fehlschlägt"])},
        "trigger_csat_answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warnung bei Antwort auf CSAT"])},
        "triggers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auslöser"])},
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])}
      }
    },
    "reset_demo": {
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demoarbeitsbereich und -daten erstellen"])},
      "refresh_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellen Sie erneut Demodaten und behalten Sie den Demoarbeitsbereich bei."])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demoarbeitsbereich und -daten entfernen"])},
      "refresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demodaten aktualisieren"])},
      "refresh_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daten aktualisieren"])},
      "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Demomodus von Konto \"", _interpolate(_named("accountName")), "\" verwalten"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demomodus"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einstellungen"])}
  },
  "support_links": {
    "about_open_ai": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/de/articles/6059285322522-%C3%9Cberblick-%C3%BCber-die-generativen-KI-Funktionen-in-Zendesk"])},
    "advanced_connection_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/de/articles/7043712839450-Konfigurieren-erweiterter-Sicherheitseinstellungen-f%C3%BCr-Help-Desk-Verbindungen"])},
    "autoqa_autoscoring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/de/articles/7043747123354-Einrichten-des-automatischen-Scoring-in-Zendesk-QA-mit-AutoQA"])},
    "bots_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/de/articles/7418648293018-Beurteilen-der-Leistung-von-AI-Agents-mit-Zendesk-QA"])},
    "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/de/articles/7043724530842-Einrichten-der-Kalibrierung-in-Zendesk-QA"])},
    "category_scores_over_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/de/articles/7043759436954-Analysieren-der-Kategoriewerte-im-Zeitverlauf"])},
    "community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/de/community/topics"])},
    "coversation_insights_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/de/articles/7043747231642-Verwenden-der-Ansicht-Konversations-Insights"])},
    "creating_multiple_scorecards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/de/articles/7043747649690-Erstellen-mehrerer-Scorecards"])},
    "dashboard_calculations_breakdown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/de/articles/7043701093786-%C3%9Cberblick-%C3%BCber-die-Berechnungen-im-Zendesk-QA-Dashboard-%C3%9Cberpr%C3%BCfungen"])},
    "find_conversations_to_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/de/articles/7043759449114-Auffinden-von-Konversationen-zur-%C3%9Cberpr%C3%BCfung-mithilfe-angepasster-Filter"])},
    "managing_users_and_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/de/articles/7043760162074-Verwalten-von-Benutzern-und-Arbeitsbereichen"])},
    "pins_for_coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/de/articles/7043759543194-Verwenden-angehefteter-Notizen-zum-Coaching-von-Kundendienst-Mitarbeitern"])},
    "rating_scale_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043724860826"])},
    "scores_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043668738970"])},
    "scores_over_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759440794"])},
    "setting_up_and_using_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/de/articles/7043747142938-Verwalten-von-Gruppen-in-Zendesk-QA"])},
    "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/de/articles/7043759586074-Filtern-von-Konversationen-mithilfe-von-Spotlight-Insights-in-Zendesk-QA"])},
    "tracking_review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/de/articles/7043759396250-Erfassen-der-%C3%9Cberpr%C3%BCfungszeit-in-Zendesk-QA"])},
    "whats_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/de/community/topics/6585157168154-What-s-New"])},
    "zendesk_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/de/sections/6999625340058-Using-Zendesk-QA?help_widget=true"])},
    "zendesk_help_widget": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/de/sections/6999625340058?help_widget=true"])}
  },
  "tasks": {
    "assignments": {
      "discard_changes_dialog": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auf Seite bleiben"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seite verlassen"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Seite verlassen und alle Änderungen verwerfen?"])}
      },
      "empty": {
        "subtitle_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben die Zuweisung abgeschlossen"])},
        "subtitle_no_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zuweisung erstellen"])},
        "title_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zuweisung abgeschlossen"])},
        "title_no_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Zuweisungen gefunden"])},
        "subtitle_base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Für Sie stehen momentan keine Überprüfungen an."])},
        "subtitle_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passen Sie die Zuweisung an oder warten Sie, bis der nächste Zyklus beginnt."])},
        "subtitle_adjusted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versuchen Sie, die Filter anzupassen"])},
        "subtitle_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zuweisung bearbeiten"])},
        "subtitle_reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Behalten Sie diese Seite im Auge, um keine Aktualisierung zu verpassen"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Aufgaben in dieser Periode"])},
        "title_adjusted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Konversationen zum Anzeigen vorhanden"])}
      },
      "goal_date": {
        "not_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])}
      },
      "link_review": {
        "mark_as_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufgabe als abgeschlossen markieren"])},
        "reviewed_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben eine Überprüfung dieses Benutzers durchgeführt."])}
      },
      "self_reviews_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Selbstüberprüfungen in dieser Zuweisung"])}
    },
    "calibrations": {
      "time_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])},
      "empty": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klicken Sie zum Hinzufügen auf das Kalibrierungssymbol in der Kopfzeile der Konversationsansicht."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Konversationen hinzugefügt"])}
      },
      "due_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fälligkeitsdatum"])}
    },
    "navbar": {
      "add_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zuweisung erstellen"])},
      "add_calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrierungssitzung erstellen"])},
      "personal_assignments": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Zuweisungen"])}
      },
      "workspace_assignments": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andere Zuweisungen"])}
      }
    },
    "no_active_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine aktiven Zuweisungen"])},
    "no_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Zuweisungen"])},
    "pickers": {
      "reviewees": {
        "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bots"])},
        "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle geprüften Personen"])},
        "all_except_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle geprüften Personen außer mir"])},
        "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine geprüften Personen gefunden"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geprüfte Person suchen"])}
      },
      "status": {
        "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fertig"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entwurf"])},
        "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entfernt"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Status"])},
        "to_do": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufgabe"])}
      },
      "reviewers": {
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Prüfer"])},
        "all_except_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Prüfer außer mir"])},
        "assigned_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mir zugewiesen"])},
        "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Prüfer gefunden"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prüfer suchen"])}
      }
    },
    "time_left_duration": {
      "days": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " Tage"])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " Tage"])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " Tag"])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " Tage"])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " Tage"])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " Tage"])}
      },
      "hours": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " Stunden"])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " Stunden"])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " Stunde"])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " Stunden"])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " Stunden"])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " Stunden"])}
      },
      "minutes": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " Minuten"])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " Minuten"])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " Minute"])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " Minuten"])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " Minuten"])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " Minuten"])}
      }
    },
    "title_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])},
    "info": {
      "reassign": {
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neu zuweisen"])},
        "all_workspace_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Arbeitsbereich-Benutzer"])},
        "current_reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktueller Prüfer"])},
        "new_goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Ziel für diese Periode"])},
        "new_reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuer Prüfer"])},
        "select_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prüfer auswählen"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversationen neu zuweisen"])},
        "title_single": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversation neu zuweisen"])},
        "to_be_moved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zu verschiebende Konversationen"])}
      },
      "cycle": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuelle Perioden"])}
      },
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zuweisung bearbeiten"])},
      "reviewers": {
        "goal_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ziel"])},
        "no_reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Prüfer gefunden"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prüfer suchen"])},
        "reassign_to_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversationen mir neu zuweisen"])},
        "reassign_to_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversationen einem anderen Benutzer neu zuweisen"])},
        "reviewer_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prüfer"])},
        "reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewertungen"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prüfer"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übersicht"])}
    },
    "time_left_days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " Tage"]), _normalize([_interpolate(_named("n")), " Tag"]), _normalize([_interpolate(_named("n")), " Tage"])])},
    "time_left_hours": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " Stunden"]), _normalize([_interpolate(_named("n")), " Stunde"]), _normalize([_interpolate(_named("n")), " Stunden"])])},
    "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ziel"])},
    "hide_completed_cycles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abgeschlossene Perioden ausblenden"])},
    "hide_completed_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abgeschlossene Sitzungen ausblenden"])},
    "personal_goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Ziel"])},
    "remove": {
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grund hinzufügen, um diese Konversation zu entfernen"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erklären Sie, warum Sie diese Konversation entfernen"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entfernen"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversation entfernen"])}
    },
    "replace": {
      "cancel_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie die Änderungen beibehalten?"])},
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grund hinzufügen, um diese Konversation zu ersetzen"])},
      "discard_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Änderungen verwerfen"])},
      "keep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beibehalten"])},
      "keep_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Änderungen beibehalten"])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ersatz nicht gefunden"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erklären Sie, warum Sie diese Konversation ersetzen"])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entfernen"])},
      "replace_remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie diese Konversation nach wie vor aus der Zuweisung entfernen? Dadurch verringert sich das Ziel um 1."])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ersetzen"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversation ersetzen"])}
    },
    "review_actions": {
      "delete_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entwurf löschen"])},
      "edit_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entwurf bearbeiten"])},
      "reassign_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mir erneut zuweisen"])},
      "reassign_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einem anderen Benutzer zuweisen"])},
      "reassign_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neu zuweisen und überprüfen"])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversation entfernen"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine Überprüfung abgeben"])},
      "view_less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weniger anzeigen"])},
      "view_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehr anzeigen"])}
    },
    "review_goal": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ziel: ", _interpolate(_named("count")), "/", _interpolate(_named("goal")), " überprüft"])},
    "reviews_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abgeschlossene Überprüfungen"])},
    "show_completed_cycles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abgeschlossene Perioden anzeigen"])},
    "show_completed_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abgeschlossene Sitzungen anzeigen"])},
    "time_left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbleibende Zeit"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufgaben"])},
    "toast": {
      "replaced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversation ersetzt"])}
    },
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufgabenliste umschalten"])}
  },
  "universal": {
    "create_dropdown_tag": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Create \"", _interpolate(_named("tag")), "\""])},
    "duration": {
      "later_with_time": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("time")), " später"])},
      "second": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("value")), " Sekunde"])},
      "seconds": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("value")), " Sekunden"])},
      "days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " T"])},
      "days_hours": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " T ", _interpolate(_named("hours")), " Std."])},
      "days_hours_minutes": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " T ", _interpolate(_named("hours")), " Std. ", _interpolate(_named("minutes")), " Min."])},
      "days_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " T"])},
      "days_long": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " Tage"])},
      "days_minutes": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " T ", _interpolate(_named("minutes")), " Min."])},
      "hours_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), " Std."])},
      "hours_minutes_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), " Std. ", _interpolate(_named("minutes")), " Min."])},
      "hours_minutes_seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), " Std. ", _interpolate(_named("minutes")), " Min. ", _interpolate(_named("seconds")), " Sek."])},
      "hours_seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), " Std. ", _interpolate(_named("seconds")), " Sek."])},
      "minutes_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("minutes")), " Min."])},
      "minutes_seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("minutes")), " Min. ", _interpolate(_named("seconds")), " Sek."])},
      "months_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("months")), " Mon."])},
      "seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("seconds")), " Sek."])},
      "years_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("years")), " J"])},
      "now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerade eben"])}
    },
    "errors": {
      "403": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihnen fehlen die Berechtigungen. Wenden Sie sich an Ihren Administrator, wenn Sie Hilfe benötigen."])},
      "404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die von Ihnen gesuchte Seite existiert nicht."])},
      "500": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unerwarteter Fehler aufgetreten. Wenden Sie sich an den Support, wenn Sie Hilfe brauchen."])},
      "401_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zugriff verweigert. Darf diese Aktion nicht durchführen."])},
      "access_denied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die gesuchte Seite ist nicht vorhanden oder Sie sind nicht zum Zugriff berechtigt."])},
      "slow_down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie gehen etwas zu schnell vor. Wir haben Probleme, mitzuhalten."])}
    },
    "role_none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine"])},
    "save_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Änderungen gespeichert"])},
    "unsaved_changes_made": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorgenommene nicht gespeicherte Änderungen"])},
    "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["und"])},
    "applied": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " angewendet"]), _normalize([_interpolate(_named("n")), " angewendet"]), _normalize([_interpolate(_named("n")), " angewendet"])])},
    "connected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbunden"])},
    "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellt um"])},
    "created_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellt von"])},
    "critical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kritisch"])},
    "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fertig"])},
    "equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ist"])},
    "former_member": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Ehemaliges Mitglied"]), _normalize(["ein ehemaliges Mitglied"])])},
    "here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hier"])},
    "items_selected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " ausgewählt"]), _normalize([_interpolate(_named("n")), " ausgewählt"]), _normalize([_interpolate(_named("n")), " ausgewählt"])])},
    "last_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zuletzt aktualisiert"])},
    "n_more": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["... und +1 weitere/r/s"]), _normalize(["... und +", _interpolate(_named("n")), " weitere"])])},
    "n_more_simple": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["+1 weitere/r/s"]), _normalize(["+", _interpolate(_named("n")), " weitere"])])},
    "new_feature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["neu"])},
    "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine"])},
    "not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ist nicht"])},
    "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oder"])},
    "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entfernt"])},
    "replies": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " Antworten"]), _normalize([_interpolate(_named("n")), " Antwort"]), _normalize([_interpolate(_named("n")), " Antworten"])])},
    "scale_emoji_labels": {
      "scale_2": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thumbs down"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thumbs up"])}
      },
      "scale_3": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disappointed face"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slightly smiling face"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Star-struck"])}
      },
      "scale_4": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disappointed face"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confused face"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smiling face"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Star-struck"])}
      },
      "scale_5": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disappointed face"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confused face"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slightly smiling face"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smiling face"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Star-struck"])}
      }
    },
    "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["heute"])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer"])},
    "weekdays": {
      "friday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Freitag"]), _normalize(["Freitags"])])},
      "monday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Montag"]), _normalize(["Montags"])])},
      "saturday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Samstag"]), _normalize(["Samstags"])])},
      "sunday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Sonntag"]), _normalize(["Sonntags"])])},
      "thursday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Donnerstag"]), _normalize(["Donnerstags"])])},
      "tuesday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Dienstag"]), _normalize(["Dienstags"])])},
      "wednesday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Mittwoch"]), _normalize(["Mittwochs"])])}
    },
    "yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gestern"])},
    "account_role": {
      "admin": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Admin"]), _normalize(["ein Administrator"])])},
      "manager": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Kontomanager"]), _normalize(["ein Kontomanager"])])},
      "user": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Benutzer"]), _normalize(["ein Benutzer"])])}
    },
    "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA"])},
    "copy_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopiert"])},
    "learn_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Infos"])},
    "login_expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Sitzung ist abgelaufen."])},
    "logins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmeldungen"])},
    "no_empty_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieses Feld darf nicht leer sein."])},
    "no_items_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nichts gefunden."])},
    "no_users_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine übereinstimmenden Personen."])},
    "owner": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Inhaber"]), _normalize(["ein Inhaber"])])},
    "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Infos"])},
    "read_more_arrow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Infos"])},
    "read_more_dot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Infos."])},
    "register_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bevor wir beginnen, müssen Sie unsere Nutzungsbedingungen lesen und akzeptieren."])},
    "sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesendet"])},
    "sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sicher?"])},
    "team_overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insgesamt durchgeführte Überprüfungen"])},
    "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualisiert"])},
    "workspace_role": {
      "agent": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Agent"]), _normalize(["ein Agent"])])},
      "lead": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Lead"]), _normalize(["ein Lead"])])},
      "manager": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Manager"]), _normalize(["ein Manager"])])},
      "reviewer": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Prüfer"]), _normalize(["ein Prüfer"])])}
    },
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinzufügen"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle"])},
    "anonymous_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht registrierter Benutzer"])},
    "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivieren"])},
    "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitarbeiter"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
    "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CES"])},
    "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ändern"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schließen"])},
    "coming_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bald"])},
    "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbinden"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])},
    "copy_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zum Kopieren klicken"])},
    "copy_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link kopieren"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellen"])},
    "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
    "danger_zone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gefahrenbereich"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])},
    "deny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ablehnen"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibung (optional)"])},
    "dialog_default_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätigung erforderlich"])},
    "disable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deaktivieren"])},
    "discard_unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht gespeicherte Änderungen verwerfen?"])},
    "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplizieren"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bearbeiten"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E‑Mail"])},
    "enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivieren"])},
    "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportieren"])},
    "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])},
    "frequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Häufigkeit"])},
    "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ziel"])},
    "got_it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
    "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help Desk"])},
    "invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einladen"])},
    "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
    "languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprachen"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "never": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])},
    "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benachrichtigungen"])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
    "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonstiges"])},
    "pass_rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolgsrate"])},
    "personal_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persönliche Einstellungen"])},
    "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])},
    "read_less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weniger lesen"])},
    "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entfernen"])},
    "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erneut senden"])},
    "restore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wiederherstellen"])},
    "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geprüfte Person"])},
    "reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erhaltene/angezeigte Überprüfungen"])},
    "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prüfer"])},
    "reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Von Arbeitsbereich-Mitglied durchgeführte Überprüfungen"])},
    "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rolle"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
    "save_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Änderungen speichern"])},
    "scale_labels": {
      "scale_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Binärskala"])},
      "scale_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3-Punkte-Skala"])},
      "scale_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4-Punkte-Skala"])},
      "scale_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5-Punkte-Skala"])}
    },
    "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suche"])},
    "select_frequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Häufigkeit auswählen"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einstellungen"])},
    "show_less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weniger anzeigen"])},
    "show_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehr anzeigen"])},
    "skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überspringen"])},
    "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quelle"])},
    "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spotlight"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schritt"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senden"])},
    "survey_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umfragefeedback"])},
    "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umfragen"])},
    "time_range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeitraum"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamt"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ"])},
    "unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorgenommene nicht gespeicherte Änderungen"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualisieren"])},
    "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer"])},
    "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gewichtung"])},
    "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeitsbereiche"])},
    "you_invited": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " hat Sie dazu eingeladen, Zendesk QA beizutreten."])}
  },
  "user_management": {
    "account_users": {
      "zendesk_manage_users_body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Die Benutzer werden automatisch über das Admin Center verbunden. ", _interpolate(_named("link"))])},
      "zendesk_manage_users_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer verwalten"])},
      "bubble": {
        "link_scim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Infos zur Benutzerverwaltung"])},
        "content_scim": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Einige Benutzer werden automatisch von Ihrem Identitätsanbieter bereitgestellt und synchronisiert. ", _interpolate(_named("link"))])}
      },
      "permission_changed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Kontoberechtigung von „", _interpolate(_list(0)), "” wurde geändert"])},
      "permission_modal": {
        "learn_more_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Infos zu Benutzerberechtigungen"])},
        "do_not_ask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht mehr fragen"])},
        "help_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Alle auf der Benutzerseite vorgenommenen Änderungen wirken sich auf die Arbeitsbereiche aus. ", _interpolate(_named("help2")), ". ", _interpolate(_named("link"))])},
        "help_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dies bedeutet, dass sich der Zugriff von Benutzern auf bestimmte Bereiche von Zendesk QA ändern kann"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berechtigung auf Kontoebene ändern"])}
      },
      "remove": {
        "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " Benutzer entfernt"]), _normalize([_interpolate(_named("n")), " Benutzer entfernt"])])},
        "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wenn Sie einen Benutzer entfernen, kann er nicht mehr auf Ihr Konto zugreifen. Seine Überprüfungen, Kommentare und Bewertungen bleiben in Zendesk QA vorhanden. ", _interpolate(_named("linebreak")), "Das Entfernen eines Benutzers wirkt sich nicht auf die Anzahl von Lizenzen in Ihrem Abonnement aus."])},
        "description_ws_manager": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Dieser Benutzer wird aus allen von Ihnen verwalteten Arbeitsbereichen entfernt. ", _interpolate(_named("linebreak")), " Seine Überprüfungen, Kommentare und Bewertungen bleiben intakt."]), _normalize(["Er wird aus allen von Ihnen verwalteten Arbeitsbereichen entfernt. ", _interpolate(_named("linebreak")), " Alle seine Überprüfungen, Kommentare und Bewertungen bleiben intakt."])])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("name")), " entfernen"]), _normalize(["Benutzer ", _interpolate(_named("name")), " entfernen"])])}
      },
      "account_permissions": {
        "payment_manage_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kann alles sehen und alle Einstellungen sowie die Abrechnung verwalten"])},
        "teams_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kann alles sehen und persönliche Einstellungen und Arbeitsbereichseinstellungen verwalten, mit Ausnahme der Abrechnung"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontoberechtigungen"])},
        "user_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeitsbereich-spezifische Berechtigungen ermöglichen es Benutzern, Daten in den Arbeitsbereichen anzuzeigen (und) zu bearbeiten, denen sie angehören."])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer"])}
    },
    "all_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All workspaces"])},
    "bots": {
      "empty_state": {
        "learn_more_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Infos zu BotQA"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Bots erscheinen hier, da die Konversationen, an denen sie teilgenommen haben, synchronisiert werden"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Bots synchronisiert"])}
      },
      "mark_as_user_modal": {
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn Sie einen Bot als Benutzer markieren, kann er sich anmelden. Seine historischen Bot-Daten sind weiterhin in Dashboards verfügbar."])}
      },
      "no_bots_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine passenden Bots gefunden"])},
      "no_bots_found_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versuchen Sie es erneut mit einem anderen Schlüsselwort oder einer anderen Schreibweise."])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nach Bots suchen"])},
      "search_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nach Bot-Namen suchen"])},
      "table": {
        "actions": {
          "mark_as_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als Benutzer markieren"])},
          "mark_as_user_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nur Administratoren und Account-Manager können Bots als Benutzer markieren"])}
        },
        "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot-Name"])},
        "last_chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letzter Chat"])},
        "manually_marked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manuell markiert"])},
        "reviewable": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überprüfbar"])},
          "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nein"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Bot wird aus der automatischen Analyse und von allen Zuweisungen entfernt. Neue Daten werden auf den Dashboards nicht angezeigt, was eine präzise Berichterstellung ermöglicht und die Bot-Verwaltung vereinfacht."])},
          "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])}
        },
        "type": {
          "generative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generativer Bot"])},
          "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unbekannt"])},
          "workflow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workflow-Bot"])}
        }
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bots"])}
    },
    "bulk_edit": {
      "add_to_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zu Gruppe(n) hinzufügen"])},
      "all_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Gruppen"])},
      "no_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Gruppe"])},
      "remove_from_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aus Gruppe(n) entfernen"])},
      "select_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppe auswählen"])}
    },
    "columns": {
      "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppen"])},
      "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeitsbereiche"])},
      "account_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontoberechtigung"])},
      "add_to_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zu Arbeitsbereich(en) hinzufügen"])},
      "full_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vor- und Nachname"])},
      "last_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zuletzt aktiv"])},
      "permission_tooltip_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rolle auf Kontoebene"])},
      "permission_tooltip_generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jedem Benutzer wird eine Berechtigung auf Konto- und/oder Arbeitsbereichsebene zugewiesen. Administratorberechtigungen gehören jetzt zur Kontoebene, während andere Berechtigungen auf Arbeitsbereichsebene erteilt werden."])},
      "remove_from_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aus Arbeitsbereich(en) entfernen"])},
      "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quelle"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer"])},
      "workspace_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeitsbereich-Berechtigung"])},
      "workspace_permission_managed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Gruppe verwaltete Berechtigungen"])}
    },
    "edit_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details bearbeiten"])},
    "mark_as_bot": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als Bot markieren"])},
      "modal": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn Sie einen Benutzer als Bot markieren, kann er sich nicht mehr anmelden. Seine historischen Benutzerdaten sind weiterhin auf Dashboards verfügbar."])}
      },
      "review_checkbox": {
        "helper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entfernen Sie den Bot aus der automatischen Analyse und von allen Zuweisungen. Neue Daten werden auf den Dashboards nicht angezeigt, was eine präzise Berichterstellung ermöglicht und die Bot-Verwaltung vereinfacht."])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot aus Überprüfungen ausschließen"])}
      },
      "toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer als Bot markiert"])},
      "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nur der Berechtigungstyp „Benutzer“ kann als Bot markiert werden"])},
      "tooltip_multi_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer mit mehreren Konten können nicht als Bot markiert werden"])}
    },
    "search_by_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nach Benutzer suchen"])},
    "sidebar": {
      "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bots"])},
      "title_bots_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer, Bots und Arbeitsbereiche"])},
      "workspaces_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ARBEITSBEREICHE"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer und Arbeitsbereiche"])},
      "account_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer"])},
      "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppen"])}
    },
    "user_state": {
      "status": {
        "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrator"])},
        "no_permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Berechtigungen"])},
        "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer"])},
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle"])},
        "connected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbunden"])},
        "manual_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manuell hinzugefügt"])},
        "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wartend"])},
        "pending_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausstehende Einladung"])},
        "scim_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCIM-Benutzer"])}
      },
      "action_needed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktion erforderlich"])}
    },
    "edit_user": {
      "more": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["... und +1 weitere/r/s"]), _normalize(["... und ", _interpolate(_named("n")), " weitere"])])},
      "change_permission_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berechtigung ändern in"])},
      "reinvite": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Entfernen und erneut einladen, um die Rolle von ", _interpolate(_named("n")), " Benutzer mit ausstehender Einladung zu ändern."]), _normalize(["Entfernen und erneut einladen, um die Rolle von ", _interpolate(_named("n")), " Benutzern mit ausstehender Einladung zu ändern"])])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Details für ", _interpolate(_named("name")), " bearbeiten"]), _normalize(["Details für ", _interpolate(_named("n")), " ausgewählte Benutzer bearbeiten"])])},
      "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " Benutzer aktualisiert."]), _normalize([_interpolate(_named("n")), " Benutzer aktualisiert."])])}
    },
    "groups": {
      "group": {
        "other_members_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Zu dieser Gruppe gehört 1 Benutzer aus einem anderen Arbeitsbereich."]), _normalize(["Diese Gruppe enthält ", _interpolate(_named("n")), " Benutzer aus einem anderen Arbeitsbereich."])])},
        "activate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivieren"])},
        "archived": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Gruppe \"", _interpolate(_named("name")), "\" archiviert"])},
        "archived_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivierte Gruppe"])},
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppe erstellen"])},
        "created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Gruppe \"", _interpolate(_named("name")), "\" erstellt"])},
        "default_group_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unbenannte Gruppe"])},
        "delete_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Gruppe dauerhaft löschen?"])},
        "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppe löschen"])},
        "delete_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppe löschen"])},
        "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppe bearbeiten"])},
        "group_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppenleiter"])},
        "group_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppenrolle"])},
        "hidden": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Gruppe \"", _interpolate(_named("name")), "\" ausgeblendet"])},
        "hidden_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Gruppe ist nirgendwo sonst in Zendesk QA sichtbar"])},
        "hidden_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nur Arbeitsbereichs-Berechtigungen verwalten"])},
        "hidden_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppe in Zendesk QA nicht sichtbar"])},
        "lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lead"])},
        "member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitglied"])},
        "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unbenannte Gruppe"])},
        "name_taken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine andere Gruppe trägt bereits diesen Namen"])},
        "name_taken_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine archivierte Gruppe trägt bereits diesen Namen"])},
        "other_members_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Rolle lässt nur die Verwaltung von Mitgliedern dieser Gruppe zu. Wenden Sie sich an einen Benutzer mit höheren Berechtigungen, um weitere Änderungen vorzunehmen."])},
        "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])},
        "remove_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als Gruppenleiter entfernen"])},
        "restored": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Gruppe \"", _interpolate(_named("name")), "\" wiederhergestellt"])},
        "saved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Gruppe \"", _interpolate(_named("name")), "\" gespeichert"])},
        "set_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als Gruppenleiter festlegen"])},
        "unarchived": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Gruppe \"", _interpolate(_named("name")), "\" wieder aus dem Archiv entfernt"])},
        "unsaved_badge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht gespeicherte Änderungen"])},
        "user_input_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorhandene Benutzer zu dieser Gruppe hinzufügen"])},
        "user_picker_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nach Namen oder E-Mail-Adresse suchen"])},
        "users_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitglieder hinzufügen"])},
        "visible": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Gruppe \"", _interpolate(_named("name")), "\" sichtbar"])},
        "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeitsbereiche"])}
      },
      "permission_picking_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Jedes Mitglied ist ", _interpolate(_named("workspace_role"))])},
      "empty_state_readonly": {
        "content_link_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Infos zu Gruppen"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einige Attribute von Gruppen werden automatisch von Ihrem Identitätsanbieter bereitgestellt und synchronisiert."])}
      },
      "groups_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Infos zu Gruppen"])},
      "create_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppe erstellen"])},
      "empty_state": {
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisieren Sie mühelos Ihre Teammitglieder und ahmen Sie Ihre Organisationsstruktur nach."])}
      },
      "errors": {
        "empty_members_input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fügen Sie mindestens 1 Mitglied hinzu"])},
        "empty_name_input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppennamen hinzufügen"])},
        "existing_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine Gruppe mit diesem Namen ist bereits vorhanden"])}
      },
      "group_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppenmitglieder"])},
      "group_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppenname"])},
      "help_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisieren Sie Ihre Teammitglieder und ahmen Sie Ihre Organisationsstruktur nach."])},
      "members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitglieder"])},
      "n_groups": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Keine Gruppen"]), _normalize(["1 Gruppe"]), _normalize([_interpolate(_named("count")), " Gruppen"])])},
      "no_active_groups_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellen Sie eine neue Gruppe oder aktivieren Sie stattdessen eine archivierte Gruppe."])},
      "no_active_groups_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine aktiven Gruppen verfügbar"])},
      "no_archived_groups_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine archivierten Gruppen"])},
      "no_groups_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine solchen Gruppen gefunden"])},
      "no_groups_found_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versuchen Sie es erneut mit einem anderen Schlüsselwort oder einer anderen Schreibweise."])},
      "no_groups_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Gruppen hinzugefügt"])},
      "permission_picking_2": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["in diesen"]), _normalize(["in"]), _normalize(["in"])])},
      "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nach Gruppennamen suchen"])},
      "tabs": {
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv"])},
        "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archiviert"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppen"])}
    },
    "resend_invite_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Erneutes Senden möglich in ", _interpolate(_list(0))])},
    "seats_left": {
      "pill": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " verbleibende Lizenzen"]), _normalize([_interpolate(_named("n")), " verbleibende Lizenz"]), _normalize([_interpolate(_named("n")), " verbleibende Lizenzen"])])},
      "paused_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Anzahl Ihrer Lizenzen ist derzeit begrenzt, da Ihr Abonnement angehalten wurde. Aktivieren Sie es erneut, um weitere Benutzer hinzuzufügen."])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben die maximale Anzahl an Lizenzen für Ihr Konto erreicht. Sie können Benutzer ersetzen, indem Sie einen aktiven Benutzer deaktivieren oder weitere Lizenzen hinzufügen."])},
      "view_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsdetails anzeigen"])}
    },
    "selected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " ausgewählt"]), _normalize([_interpolate(_named("n")), " ausgewählt"])])},
    "selected_users": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " Benutzer ausgewählt"]), _normalize([_interpolate(_named("n")), " Benutzer ausgewählt"])])},
    "user_filter": {
      "rows": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " Zeilen"]), _normalize([_interpolate(_named("n")), " Zeile"]), _normalize([_interpolate(_named("n")), " Zeilen"])])},
      "bulk_search_modal": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suchen Sie mehrere Benutzer gleichzeitig nach Namen oder E-Mail-Adressen."])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durch Komma oder Zeilenumbruch getrennte Daten hier einfügen"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suche"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Massensuche nach Benutzern"])}
      }
    },
    "workspaces": {
      "edit_permissions": {
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Arbeitsbereichsberechtigungen bearbeiten für ", _interpolate(_named("name"))]), _normalize(["Arbeitsbereichsberechtigungen bearbeiten für ", _interpolate(_named("n")), " ausgewählte Mitglieder"])])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bearbeitungsberechtigungen"])},
        "change_permission_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeitsbereich-Berechtigung auswählen"])}
      },
      "remove": {
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("name")), " aus Arbeitsbereich entfernen"]), _normalize([_interpolate(_named("name")), " Mitglieder aus dem Arbeitsbereich entfernen"])])},
        "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " Mitglied entfernt"]), _normalize([_interpolate(_named("n")), " Mitglieder entfernt"])])},
        "confirm_button": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Mitglied entfernen"]), _normalize(["Mitglieder entfernen"])])},
        "description": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Dieser Benutzer wird aus dem Arbeitsbereich entfernt."]), _normalize(["Diese Benutzer werden aus dem Arbeitsbereich entfernt."])])}
      },
      "permission_changed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Arbeitsbereichsberechtigung von „", _interpolate(_list(0)), "” wurde geändert."])},
      "add_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitglieder hinzufügen"])},
      "permission_picker": {
        "agent_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversationen und zugehörige Überprüfungen anzeigen"])},
        "lead_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alles im Arbeitsbereich anzeigen, aber nur Quiz, Zuweisungen, Streitfälle und Kalibrierungssitzungen verwalten"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeitsbereich-Berechtigungen"])},
        "workspace_manager_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alles im Arbeitsbereich anzeigen und verwalten"])},
        "workspace_reviewer_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alles im Arbeitsbereich anzeigen, mit Ausnahme der Arbeitsbereichseinstellungen"])}
      }
    },
    "add_members": {
      "no_users": {
        "link_connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer verbinden"])},
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Benutzer aus den von Ihnen verwalteten Arbeitsbereichen wurden zu diesem Arbeitsbereich hinzugefügt."])},
        "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitten Sie einen Administrator, dies zu tun."])},
        "cta_manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laden Sie neue Benutzer direkt selbst ein oder bitten Sie einen Administrator, dies zu tun."])},
        "link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["„", _interpolate(_named("link_invite")), "“ oder „", _interpolate(_named("link_connect")), "“"])},
        "link_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einladen"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Benutzer zum Anzeigen vorhanden"])}
      },
      "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([" ", _interpolate(_named("n")), " Benutzer hinzugefügt"]), _normalize([_interpolate(_named("n")), " Benutzer hinzugefügt"])])},
      "add_users": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Mitglieder hinzufügen"]), _normalize(["Mitglied hinzufügen"]), _normalize([_interpolate(_named("n")), " Mitglieder hinzufügen"])])},
      "select_workspace_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeitsbereich-Berechtigung auswählen"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer für den Arbeitsbereich auswählen"])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Mitglieder zu ", _interpolate(_list(0)), " hinzufügen"])}
    },
    "connect_users": {
      "bulk_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mails hier einfügen und durch Zeilenumbrüche oder Kommas trennen"])},
      "bulk_match": {
        "bold": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Stimmt mit ", _interpolate(_list(0)), "/", _interpolate(_list(1)), " E-Mails überein"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" mit bereits verbundenen Benutzern:"])}
      },
      "bulk_mismatch": {
        "bold": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Keine Übereinstimmung mit ", _interpolate(_list(0)), "/", _interpolate(_list(1)), " E-Mails"])},
        "footer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überprüfen Sie die Rechtschreibung oder verbinden Sie sie später einzeln"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" mit Help-Desk-Benutzern:"])}
      },
      "toasts": {
        "users_connected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " Benutzer verbunden"]), _normalize([_interpolate(_named("n")), " Benutzer verbunden"])])},
        "users_invited": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " Benutzer eingeladen"]), _normalize([_interpolate(_named("n")), " Benutzer eingeladen"])])}
      },
      "bulk_connect_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Massenverbindung"])},
      "bulk_invite_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masseneinladung"])},
      "bulk_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beispiel:\n\nkate", "@", "example.com, john", "@", "example.com\n\nkate", "@", "example.com\nkate", "@", "example.com"])},
      "bulk_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stellen Sie eine Verbindung über Ihr(e) Help Desk(s) her, um deren Interaktionen zu überprüfen"])},
      "connect_btn": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Benutzer verbinden"]), _normalize(["Benutzer verbinden"])])},
      "emails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mails"])},
      "individually_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einzeln verbinden"])},
      "invite": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn verbundene Benutzer Zugriff auf Zendesk QA haben sollen, können Sie sie hier einladen."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbundene Benutzer einladen"])}
      },
      "invite_users": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Benutzer einladen"]), _normalize(["Benutzer einladen"])])},
      "skip_inviting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einladung überspringen"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie Benutzer aus, die über Ihr(e) Help Desk(s) verbunden werden sollen, um deren Interaktionen zu überprüfen"])},
      "tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Abonnement verwalten"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help-Desk-Benutzer verbinden"])}
    },
    "create_workspace": {
      "hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie einen eindeutigen Namen für Ihren Arbeitsbereich ein"])},
      "access_checkbox": {
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn diese Option aktiviert ist, werden alle vorhandenen Kontoverbindungen automatisch zum neuen Arbeitsbereich hinzugefügt."])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Verbindungen zu diesem Arbeitsbereich hinzufügen"])}
      },
      "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeitsbereich erstellen"])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name des Arbeitsbereichs"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeitsbereich erstellen"])}
    },
    "invite_email": {
      "account_permission_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzern, die direkt zum Konto hinzugefügt werden (ohne Zuweisung eines Arbeitsbereichs), wird eine Rolle auf Kontoebene zugewiesen. Sie können diese später zu Arbeitsbereichen hinzufügen."])},
      "add_users_to_workspace_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eingeladenen Benutzern wird automatisch eine Benutzerrolle auf Kontoebene zugewiesen. Sie können diese später auf eine Administratorrolle anheben."])},
      "bulk_text_placeholder": {
        "these_all_work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All das funktioniert"])},
        "paste_emails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fügen Sie durch Zeilenumbrüche oder Kommas getrennte E-Mail-Adressen hier ein."])}
      },
      "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " per E-Mail eingeladener Benutzer"]), _normalize([_interpolate(_named("n")), " per E-Mail eingeladene Benutzer"])])},
      "workspace_permission_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzern, die zu einem Arbeitsbereich hinzugefügt werden, wird automatisch eine Benutzerrolle auf Kontoebene zugewiesen. Sie können diese später auf eine Administratorrolle anheben."])},
      "add_another": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere hinzufügen"])},
      "add_users_to_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer nur zum Konto einladen, ohne sie zu einem Arbeitsbereich hinzuzufügen"])},
      "add_users_to_account_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eingeladenen Benutzern wird nur eine Kontoberechtigung zugewiesen und sie werden nicht zu Arbeitsbereichen hinzugefügt."])},
      "add_users_to_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer direkt zu einem Arbeitsbereich einladen und Arbeitsbereich-Berechtigungen zuweisen"])},
      "add_users_to_workspace_picker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer zum Arbeitsbereich hinzufügen"])},
      "bulk_invite_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masseneinladung"])},
      "individually_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einzeleinladung"])},
      "invite_toggle_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nur zum Konto einladen"])},
      "invite_toggle_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zu einem Arbeitsbereich einladen"])},
      "invite_users": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Benutzer einladen"]), _normalize(["Benutzer einladen"]), _normalize([_interpolate(_named("n")), " Benutzer einladen"])])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masseneinladung von Benutzern per E-Mail"])}
    },
    "delete_users": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Benutzer löschen"]), _normalize(["Benutzer löschen"])])},
    "deselect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auswahl aufheben"])},
    "invite_via_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer per E-Mail einladen"])},
    "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Arbeitsbereiche"])},
    "permission_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berechtigung auswählen"])},
    "resend_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einladung erneut senden"])},
    "user_item": {
      "connected_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbundener Benutzer"])},
      "pending_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer mit ausstehender E-Mail-Einladung"])},
      "scim_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCIM-Benutzer"])}
    },
    "workspace_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeitsbereich auswählen"])}
  },
  "zendesk_product_tray": {
    "ai_agents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI Agents"])},
    "central_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin Center"])},
    "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat"])},
    "explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explore"])},
    "gather": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gather"])},
    "guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guide"])},
    "lotus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])},
    "quality_assurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qualitätssicherung durchführen"])},
    "sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sell"])},
    "talk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Talk"])},
    "workforce_management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workforce Management"])}
  },
  "assignments_dashboard": {
    "completed_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Abgeschlossen ", _interpolate(_named("n")), "/", _interpolate(_named("total"))]), _normalize(["Abgeschlossen ", _interpolate(_named("n")), "/", _interpolate(_named("total"))]), _normalize(["Abgeschlossen ", _interpolate(_named("n")), "/", _interpolate(_named("total"))])])},
    "labels": {
      "cycle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Periode ", _interpolate(_named("cycleNr"))])},
      "period": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("startDate")), " – ", _interpolate(_named("endDate"))])},
      "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prüfer"])}
    },
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamt"])},
    "buttons": {
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zuweisung bearbeiten"])}
    },
    "empty": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um Zeit zu sparen, können Sie automatische Zuweisungen für Überprüfungen einrichten, um Konversationen nicht mehr manuell überprüfen und zuweisen zu müssen."])}
    }
  },
  "calibration_dashboard": {
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar"])},
    "due_at": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Fällig am ", _interpolate(_named("date"))])},
    "no_sessions": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sitzungen können im Seitenmenü der Konversationsansicht im Abschnitt „Kalibrierung“ hinzugefügt werden."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noch keine Sitzungen hinzugefügt"])}
    },
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamt"])},
    "baseline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baseline"])},
    "calibration_session_picker": {
      "no_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Sitzungen"])}
    },
    "no_conversations": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um Konversationen zu einer Sitzung hinzuzufügen, klicken Sie in der Kopfzeile der Konversationsansicht auf das Kalibrierungssymbol."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noch keine Konversationen hinzugefügt"])}
    },
    "no_reviews_can_see": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Noch keine Überprüfungen abgegeben. ", _interpolate(_named("link"))])},
    "no_reviews_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine Überprüfung der Kalibrierung hinterlassen"])},
    "root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hauptursache"])}
  },
  "components": {
    "command_palette": {
      "pre_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wechseln zu"])},
      "failed_to_load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Befehlspalette konnte nicht geladen werden."])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Ergebnisse."])},
      "found_by_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nach ID gefunden"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Befehlspalette wird geladen …"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seiten, Filter, Konversationen, Einstellungen …"])},
      "type_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversation"])},
      "type_private_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privater Filter"])},
      "type_public_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öffentlicher Filter"])}
    },
    "helpdesk_icon": {
      "generic_source": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Von ", _interpolate(_list(0))])},
      "manual_import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Von Ihrer API"])}
    },
    "pagination": {
      "wrapper": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Angezeigt ", _interpolate(_named("rangeStart")), " bis ", _interpolate(_named("rangeEnd")), " von ", _interpolate(_named("total")), " ", _interpolate(_named("label"))]), _normalize(["Angezeigt ", _interpolate(_named("rangeStart")), " bis ", _interpolate(_named("rangeEnd")), " von ", _interpolate(_named("total")), " ", _interpolate(_named("label"))])])}
    },
    "subnav": {
      "hide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seitenleiste ausblenden"])},
      "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seitenleiste anzeigen"])},
      "toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seitenleiste umschalten"])}
    }
  },
  "disputes_dashboard": {
    "data_column_label": {
      "disputes_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Streitfälle akzeptiert"])},
      "disputes_rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Streitfälle abgelehnt"])},
      "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Streitfälle"])},
      "disputes_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offene Streitfälle"])},
      "disputes_partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teilweise angenommene Streitfälle"])},
      "reviews_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abgeschlossene Überprüfungen"])},
      "reviews_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erhaltene Überprüfungen"])},
      "reviews_seen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angezeigte Überprüfungen"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer"])}
    },
    "legend": {
      "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["angenommen"])},
      "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["geöffnet"])},
      "partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["teilweise angenommen"])},
      "percent_of_resolved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " von gesamt"])},
      "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["abgelehnt"])},
      "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Streitfälle"])},
      "disputes_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angenommen"])},
      "disputes_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offen"])},
      "disputes_partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teilweise angenommen"])},
      "disputes_rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abgelehnt"])},
      "reviews": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Überprüfung"]), _normalize(["Überprüfungen"])])}
    },
    "tooltip": {
      "reviews_seen": {
        "disputed_reviewers_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl ihrer Überprüfungen, die von geprüften Personen angesehen wurden"])},
        "disputers_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl ihrer Überprüfungen, die angesehen wurden"])}
      }
    },
    "card": {
      "disputed_reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angefochtene Prüfer"])},
      "disputers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anfechter"])},
      "disputes_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Streitfälle nach Kategorie"])},
      "disputes_over_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Streitfälle im Zeitverlauf"])},
      "disputes_overview_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offen"])},
      "disputes_overview_resolution_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durchschnittliche Lösungszeit"])},
      "disputes_overview_resolution_time_hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durchschnittliche Lösungszeit (Std.)"])},
      "disputes_overview_resolution_time_minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durchschnittliche Lösungszeit (Min.)"])},
      "disputes_overview_resolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gelöst"])},
      "disputes_overview_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Streitfälle gesamt"])}
    },
    "hashtag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtag auswählen"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamt"])}
  },
  "pickers": {
    "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angenommen"])},
    "csat_languages": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antwortsprache"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Sprachen gefunden"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Sprachen"])}
    },
    "csat_predicted_drivers": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prognostizierte Treiber"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Treiber gefunden"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Treiber"])}
    },
    "csat_wordcloud_words": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wortwolken-Wörter"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Wörter gefunden"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Wörter"])}
    },
    "mentions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erwähnungen"])},
    "newest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neueste"])},
    "oldest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Älteste"])},
    "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offen"])},
    "partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teilweise angenommen"])},
    "reacted_with": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " hat reagiert mit ", _interpolate(_list(1))])},
    "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abgelehnt"])},
    "resolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gelöst"])},
    "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewertungen"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suche"])},
    "skin_tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hautton auswählen"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle"])},
    "calibration_session": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sitzungen"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Sitzungen gefunden"])}
    },
    "categories": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorien"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Kategorien gefunden"])}
    },
    "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentare"])},
    "csat_predicted_dimentions": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentargröße"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Größen gefunden"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Größen"])}
    },
    "filters": {
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Filter gefunden"])}
    },
    "groups": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppen"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Gruppen gefunden"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Gruppen"])}
    },
    "hashtags": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtags"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Hashtags gefunden"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Hashtags"])}
    },
    "helpdesk_custom_field_values": {
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Werte gefunden"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Werte"])}
    },
    "helpdesk_custom_fields": {
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine angepassten Felder gefunden"])}
    },
    "helpdesk_tags": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help-Desk-Stichwörter"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Help Desk-Stichwörter gefunden"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Help Desk-Stichwörter"])}
    },
    "loading_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ergebnisse werden geladen ..."])},
    "question_type": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fragetypen"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Fragetypen gefunden"])}
    },
    "reaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reaktion hinzufügen"])},
    "scorecards": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecards"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Scorecards gefunden"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Scorecards"])}
    },
    "scores": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Werte"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Werte gefunden"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Werte"])}
    },
    "source": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quellen"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Quellen gefunden"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Quellen"])}
    },
    "survey_reasons": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grund des Feedbacks"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Gründe gefunden"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Gründe"])}
    },
    "surveys": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umfragen"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Umfragen gefunden"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Umfragen"])}
    },
    "ticket_channel": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Channels"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Channels gefunden"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Kanäle"])}
    },
    "users": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Benutzer gefunden"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Benutzer"])}
    },
    "workspaces": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeitsbereiche"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Arbeitsbereiche gefunden"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Arbeitsbereiche"])}
    }
  },
  "quizzes": {
    "error": {
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück zu Quiz"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Quiz wurde gelöscht oder noch nicht veröffentlicht. Überprüfen Sie den Link noch einmal oder kehren Sie zur Quizliste zurück."])},
      "no_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieses Quiz wurde nicht mit dem Arbeitsbereich geteilt, dem Sie angehören. Bitten Sie einen Administrator, Sie zu einem Arbeitsbereich hinzuzufügen, auf den dieses Quiz Zugriff hat."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz ist nicht verfügbar"])}
    },
    "form": {
      "errors": {
        "workspace_access_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zugriff auf Quiz für Arbeitsbereich(e), zu dem/denen Sie nicht gehören. Quiz kann nicht gespeichert und veröffentlicht werden."])},
        "workspace_access_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitten Sie einen Administrator, das Quiz zu speichern und zu veröffentlichen."])},
        "no_correct_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie sollten mindestens eine der Antworten als korrekte Antwort markieren."])},
        "no_empty_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieses Feld darf nicht leer sein."])}
      },
      "header": {
        "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie sehen gegenwärtig eine Vorschau für das Quiz."])},
        "access_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie die Arbeitsbereiche aus, die auf das Quiz zugreifen können. Benutzer, die mehreren Arbeitsbereichen angehören, können das Quiz nur einmal beantworten. Wenn Sie „Alle Arbeitsbereiche“ auswählen, werden Arbeitsbereiche, die nach der Veröffentlichung des Quiz neu erstellt werden, nicht einbezogen."])},
        "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz-Zugriff"])},
        "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivieren"])},
        "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplizieren"])},
        "exit_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorschaumodus verlassen"])},
        "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportieren"])},
        "navbar": {
          "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorschau"])},
          "questions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fragen"])},
          "responses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antworten"])}
        },
        "participate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz machen"])},
        "publish_quiz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz veröffentlichen"])},
        "quiz_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz-Einstellungen"])},
        "save_as_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als Entwurf speichern"])},
        "title_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Quiz erstellen"])},
        "unarchive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wieder aus dem Archiv entfernen"])}
      },
      "options": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optionen"])},
        "add_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Option hinzufügen"])}
      },
      "questions_preview_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teilen"])},
      "questions_preview_share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das sieht gut aus, oder?"])},
      "toasts": {
        "answer_submitted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Antwort wurde eingereicht"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Quiz wurde gespeichert"])},
        "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Quiz wurde veröffentlicht"])},
        "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Quiz wurde entfernt"])}
      },
      "add_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frage hinzufügen"])},
      "alerts": {
        "archive_quiz": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz archivieren"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein archiviertes Quiz und seine Ergebnisse erscheinen nicht mehr in der Quizliste. Sie können weiterhin auf die Ergebnisse eines archivierten Quiz zugreifen und es bei Bedarf wieder aus dem Archiv entfernen."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz archivieren"])}
        },
        "publish_quiz": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veröffentlichen"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nach der Veröffentlichung kann ein Quiz nicht mehr geändert oder bearbeitet werden. Alle Benutzer in Ihrem Konto können auf alle veröffentlichten Quiz zugreifen."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz veröffentlichen?"])}
        },
        "remove_quiz": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie dieses Quiz wirklich löschen? Dieser Vorgang kann nicht rückgängig gemacht werden."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz löschen?"])}
        },
        "submit_answer": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nach dem Absenden können Ihre Antworten nicht mehr geändert werden."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz senden?"])}
        },
        "unarchive_quiz": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz wieder aus dem Archiv entfernen"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn Sie das Quiz aus dem Archiv entfernen, wird es in der Quizübersicht angezeigt. Dadurch können frühere Teilnehmer auf ihre Ergebnisse zugreifen und neue Teilnehmer das Quiz beantworten."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz wieder aus dem Archiv entfernen"])}
        }
      },
      "clone_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doppelte Frage"])},
      "description_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibung (optional)"])},
      "move_question_down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frage nach unten verschieben"])},
      "move_question_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frage nach oben verschieben"])},
      "question_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frage"])},
      "questions_preview_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nur Fragen mit Antworten werden in der Vorschau angezeigt."])},
      "remove_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frage entfernen"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz senden"])},
      "title_fallback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz ohne Titel"])},
      "title_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titel des Quiz"])}
    },
    "list": {
      "delete_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dadurch werden das Quiz und seine Ergebnisse endgültig gelöscht."])},
      "delete_prompt_access_by_archiving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können aber auch weiterhin auf die Ergebnisse zugreifen, indem Sie das Quiz archivieren."])},
      "answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beantwortet"])},
      "delete_button_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz löschen"])},
      "delete_prompt_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dadurch werden das Quiz und seine Ergebnisse endgültig gelöscht."])},
      "delete_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Quiz ", _interpolate(_list(0)), " löschen"])},
      "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplizieren"])},
      "empty": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz helfen dabei, das Selbstvertrauen und die Fertigkeiten des Teams zu stärken, einheitliche Erlebnisse zu bieten und neue Teammitglieder einzuarbeiten."])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können Quiz nach dem Quiz-Namen oder dem Namens des Autors suchen."])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noch keine Quiz erstellt"])}
      },
      "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz-Liste exportieren"])},
      "heading": {
        "avg_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durchschnittl. Wert"])},
        "completed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum der Beantwortung"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz/Autor"])},
        "name_with_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz/Autor/Arbeitsbereiche"])},
        "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teilnehmer"])},
        "published_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veröffentlicht"])},
        "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wert"])},
        "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeitsbereiche"])}
      },
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nach Quiz, Autor suchen"])},
      "tabs": {
        "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archiviert"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entwurf"])},
        "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Quiz"])},
      "toast": {
        "quiz_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz wurde archiviert."])},
        "quiz_unarchived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz wurde wieder aus dem Archiv entfernt."])}
      }
    },
    "responses": {
      "no_response": {
        "archived_message_filtered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auf dieses Quiz gibt es noch keine Antworten von Benutzern in den ausgewählten Arbeitsbereichen."])},
        "archived_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieses Quiz hat vor der Archivierung keine Antworten erhalten."])},
        "archived_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Antworten"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier sehen Sie eine Aufschlüsselung der Quiz-Antworten.\nIhr Quiz hat noch niemand beantwortet. Um das zu ändern, können Sie es mit anderen teilen."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noch keine Antworten"])}
      },
      "count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antworten:"])},
      "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durchschnittlicher Wert:"])},
      "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individuell"])},
      "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übersicht"])}
    },
    "title_cloned": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("quizName")), " – Kopieren"])},
    "copy_link_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link kopiert"])},
    "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Ergebnisse gefunden"])},
    "create_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz"])},
    "leaderboard": {
      "agent_quizzes_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Quiz von ", _interpolate(_list(0))])},
      "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können das Leaderboard nach dem Namen des Teilnehmers durchsuchen."])},
      "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leaderboard exportieren"])},
      "heading": {
        "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer"])},
        "quiz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz"])},
        "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wert"])}
      },
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nach Teilnehmer suchen"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leaderboard"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz"])},
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leaderboard umschalten"])}
  },
  "team": {
    "hashtags": {
      "delete_dialog_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dies bedeutet, dass Sie in den Dashboards nicht mehr nach diesem Hashtag filtern können und in Kommentaren keine automatische Vervollständigung mehr erfolgt. Diese Aktion kann nicht rückgängig gemacht werden."])},
      "add_hashtags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtag, Hashtag, Hashtag ..."])},
      "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtags erstellen"])},
      "add_hashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtags erstellen"])},
      "add_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtags erstellen"])},
      "allow_creation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zulassen, dass Mitglieder neue Hashtags erstellen"])},
      "column_header_hashtag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtag"])},
      "column_header_used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bereits genutzt"])},
      "delete_dialog_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtag löschen"])},
      "delete_dialog_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Hashtag ", _interpolate(_list(0)), " löschen"])},
      "hashtag_creation_toast_locked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtag-Erstellung ist gesperrt"])},
      "hashtag_creation_toast_unlocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtag-Erstellung ist entsperrt"])},
      "hashtag_deleted_toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Hashtag \"", _interpolate(_list(0)), "\" gelöscht"])},
      "hashtag_renamed_toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Hashtag \"", _interpolate(_list(0)), "\" in \"", _interpolate(_list(1)), "\" umbenannt"])},
      "hashtags_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 Hashtag hinzugefügt"]), _normalize([_interpolate(_named("count")), " Hashtags hinzugefügt"])])},
      "new_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuer Name"])},
      "no_hashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Hashtags erstellt"])},
      "rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umbenennen"])},
      "rename_hashtag": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hashtag \"", _interpolate(_named("tagRenamed")), "\" umbenennen"])},
      "separate_by_commas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtags durch Kommas oder Zeilenumbrüche trennen"])},
      "tag_filter_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einen Hashtag suchen"])}
    },
    "connection": {
      "integrate": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Keine Sorge. Sie können dies tun: ", _interpolate(_named("link_copy"))])},
        "link_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["genau hier"])}
      },
      "linked_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbindung mit Arbeitsbereich verknüpft"])},
      "attach_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbindung anhängen"])},
      "attach_connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbindung zu diesem Arbeitsbereich anhängen"])},
      "connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbindung"])},
      "contact_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenden Sie sich an Ihren Administrator, um hier Änderungen vorzunehmen"])},
      "contact_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenden Sie sich an Zendesk QA, um hier Änderungen vorzunehmen"])},
      "no_connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Verbindungen unter Konto"])},
      "no_integrations_yet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben offenbar noch keine Kundenservice-Plattform integriert."])},
      "remove_dialog": {
        "body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wenn Sie eine Verbindung löschen, werden Konversationen aus dem Arbeitsbereich ", _interpolate(_named("workspaceName")), " entfernt und alle dazugehörigen Daten gelöscht, einschließlich Überprüfungen und Streitfälle."])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbindung löschen"])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Verbindung ", _interpolate(_named("connectionName")), " löschen"])}
      },
      "select_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbindung auswählen"])}
    }
  },
  "auto_qa": {
    "time_period_warning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Im ausgewählten Zeitraum waren bestimmte aktive AutoQA-Kategorien nicht auf Ihrer Scorecard enthalten. Konversationen, die vor Ihrer letzten Änderung an ", _interpolate(_named("date")), " stattgefunden haben, wurden für diese Kategorien möglicherweise nicht analysiert."])}
  },
  "reviews": {
    "received_empty_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier wird das gesamte Feedback angezeigt, das Sie erhalten. Aktuell sind noch keine Inhalte vorhanden."])},
    "received_empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine erhaltenen Überprüfungen."])},
    "comments_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nur Kommentar-Aktivität anzeigen"])},
    "given_empty_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier wird das gesamte Feedback aufgeführt, das Sie geben. Erste Schritte in der Konversationsansicht."])},
    "given_empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine abgegebenen Überprüfungen."])},
    "given_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abgegebene Überprüfungen"])},
    "received_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erhaltene Überprüfungen"])}
  }
}