import { useMutation } from '@tanstack/vue-query'
import { ref } from 'vue'
import { createSharedComposable } from '@vueuse/core'
import { setUserAsBot } from '../api'

export default createSharedComposable(() => {
  const seatsLeft = ref(-1)
  const userQuery = ref('')

  const { mutateAsync: markUserAsBot } = useMutation({
    mutationFn: ({ userId, reviewable }: { userId: string; reviewable: boolean }) => setUserAsBot(userId, reviewable),
  })

  return {
    seatsLeft,
    userQuery,
    markUserAsBot,
  }
})
