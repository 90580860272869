import { LineController } from 'chart.js'
import { createTypedChart } from 'vue-chartjs'

class LineWithLine extends LineController {
  static override id = 'line-with-line'

  draw() {
    const { ctx, tooltip, scales } = this.chart
    const meta = this.getMeta()

    const isLast = meta?.index === this.chart.data.datasets.length - 1

    const activeElements = tooltip?.getActiveElements()
    if (activeElements && activeElements.length && tooltip && isLast) {
      const activePoint = tooltip.getActiveElements()[0]
      const x = activePoint.element.tooltipPosition(true).x
      const topY = scales.y.top
      const bottomY = scales.y.bottom

      // draw bar
      ctx.save()
      ctx.globalCompositeOperation = 'destination-over'
      ctx.beginPath()
      ctx.moveTo(x, topY)
      ctx.lineTo(x, bottomY)
      ctx.lineWidth = 8
      ctx.strokeStyle = '#EDF2F7'
      ctx.stroke()
      ctx.restore()
    }

    ctx.save()
    ctx.lineCap = 'round'

    const data = meta.data.slice()

    const points: [number, number, boolean, any, any][] = []

    data.forEach((point, index) => {
      const currentDataSet = this.getDataset()
      const { borderColor, borderWidth } = currentDataSet
      const value = currentDataSet.data[index]
      const prevElement = currentDataSet.data[index - 1]
      if (value === null) return
      const dashed = prevElement === null && value !== null
      points.push([point.x, point.y, dashed, borderColor, borderWidth])
    })

    points.forEach(([x, y], index) => {
      ctx.beginPath()
      ctx.moveTo(x, y)

      const next = points[index + 1]
      if (next) {
        const [nX, nY, dashed, borderColor, borderWidth] = next
        ctx.setLineDash(dashed ? [1, 6] : [])
        ctx.globalAlpha = dashed ? 0.64 : 1
        ctx.strokeStyle = borderColor
        ctx.lineWidth = borderWidth || 2
        ctx.lineTo(nX, nY)
        ctx.stroke()
      }
    })

    ctx.restore()
    super.draw()
  }
}

const LineWithLineChart = createTypedChart('line-with-line' as 'line', LineWithLine)

export default LineWithLineChart
