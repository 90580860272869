import { type RouteParams } from 'vue-router'
import i18n from '@/i18n'
import { type RouteRecord } from '@/modules/application/components/CommandPalette/utils/useRoutes'

interface RouteTranslations {
  label: string
  keywords?: string[]
  breadcrumbs?: string[]
  type?: string
}

type RouteTranslationsMap = Record<string, RouteTranslations>

export const getRouteTranslations = (r: RouteRecord, params?: RouteParams): RouteTranslations | null => {
  if (!r.name) return null

  let key = r.name.toString()
  if (key === 'dashboard.looker' && params?.id) {
    key += `.${params.id.toString()}`
  }

  return routeTranslationsMap[key]
}

const routeTranslationsMap: RouteTranslationsMap = {
  'coaching.sessions': {
    label: i18n.t('routes.coaching.label.sessions'),
    type: i18n.t('routes.coaching.type'),
  },
  conversations: {
    label: i18n.t('routes.conversations.label'),
    keywords: [
      i18n.t('routes.conversations.keyword.conversations'),
      i18n.t('routes.conversations.keyword.review'),
      i18n.t('routes.conversations.keyword.filters'),
      i18n.t('routes.conversations.keyword.calibration'),
    ],
  },
  pins: {
    label: i18n.t('routes.coaching.label.pins'),
    type: i18n.t('routes.coaching.type'),
  },
  assignments: {
    label: i18n.t('routes.assignments.label'),
  },
  'activity.reviewsReceived': {
    label: i18n.t('routes.activity.label.reviews'),
    keywords: [i18n.t('routes.activity.keyword.my_reviews')],
    breadcrumbs: [i18n.t('routes.activity.breadcrumb.received')],
    type: i18n.t('routes.activity.type'),
  },
  'activity.reviewsGiven': {
    label: i18n.t('routes.activity.label.reviews'),
    keywords: [i18n.t('routes.activity.keyword.my_reviews')],
    breadcrumbs: [i18n.t('routes.activity.breadcrumb.given')],
    type: i18n.t('routes.activity.type'),
  },
  'activity.commentsReceived': {
    label: i18n.t('routes.activity.label.comments'),
    keywords: [i18n.t('routes.activity.keyword.my_comments')],
    breadcrumbs: [i18n.t('routes.activity.breadcrumb.received')],
    type: i18n.t('routes.activity.type'),
  },
  'activity.commentsGiven': {
    label: i18n.t('routes.activity.label.comments'),
    keywords: [i18n.t('routes.activity.keyword.my_comments')],
    breadcrumbs: [i18n.t('routes.activity.breadcrumb.given')],
    type: i18n.t('routes.activity.label.activity'),
  },
  'activity.reactionsReceived': {
    label: i18n.t('routes.activity.label.reactions'),
    breadcrumbs: [i18n.t('routes.activity.breadcrumb.received')],
    type: i18n.t('routes.activity.type'),
  },
  'activity.feedbackReceived': {
    label: i18n.t('routes.activity.label.survey_feedback'),
    breadcrumbs: [i18n.t('routes.activity.breadcrumb.received')],
    type: i18n.t('routes.activity.type'),
  },
  'activity.disputesAll': {
    label: i18n.t('routes.activity.label.all'),
    breadcrumbs: [i18n.t('routes.activity.breadcrumb.disputes')],
    type: i18n.t('routes.activity.type'),
  },
  'activity.disputesStarted': {
    label: i18n.t('routes.activity.label.started'),
    keywords: [i18n.t('routes.activity.keyword.my_disputes')],
    breadcrumbs: [i18n.t('routes.activity.breadcrumb.disputes')],
    type: i18n.t('routes.activity.type'),
  },
  'activity.disputesReceived': {
    label: i18n.t('routes.activity.label.received'),
    keywords: [i18n.t('routes.activity.keyword.my_disputes')],
    breadcrumbs: [i18n.t('routes.activity.breadcrumb.disputes')],
    type: i18n.t('routes.activity.type'),
  },
  dashboard: {
    label: i18n.t('routes.dashboard.label.reviews'),
    keywords: [
      i18n.t('routes.dashboard.keyword.statistics'),
      i18n.t('routes.dashboard.keyword.iqs'),
      i18n.t('routes.dashboard.keyword.scores'),
      i18n.t('routes.dashboard.keyword.metrics'),
    ],
    type: i18n.t('routes.dashboard.type'),
  },
  'dashboard.looker.manual_reviews::reviews': {
    label: i18n.t('routes.dashboard.label.reviews'),
    type: i18n.t('routes.dashboard.type'),
  },
  'dashboard.looker.manual_reviews_agent::reviews': {
    label: i18n.t('routes.dashboard.label.reviews'),
    type: i18n.t('routes.dashboard.type'),
  },
  'dashboard.looker.manual_reviews::reviews_limited': {
    label: i18n.t('routes.dashboard.label.reviews'),
    type: i18n.t('routes.dashboard.type'),
  },
  'dashboard.looker.manual_reviews_agent::reviews_limited': {
    label: i18n.t('routes.dashboard.label.reviews'),
    type: i18n.t('routes.dashboard.type'),
  },
  'dashboard.looker.autoqa::autoqa': {
    label: i18n.t('routes.dashboard.label.auto_qa'),
    type: i18n.t('routes.dashboard.type'),
  },
  'dashboard.looker.botqa::botqa': {
    label: i18n.t('routes.dashboard.label.bot_qa'),
    type: i18n.t('routes.dashboard.type'),
  },
  'dashboard.category': {
    label: i18n.t('routes.dashboard.label.categories'),
    type: i18n.t('routes.dashboard.type'),
  },
  'dashboard.assignments': {
    label: i18n.t('routes.dashboard.label.assignments'),
    type: i18n.t('routes.dashboard.type'),
  },
  'dashboard.surveys': {
    label: i18n.t('routes.dashboard.label.surveys'),
    type: i18n.t('routes.dashboard.type'),
  },
  'dashboard.disputes': {
    label: i18n.t('routes.dashboard.label.disputes'),
    type: i18n.t('routes.dashboard.type'),
  },
  'dashboard.calibration': {
    label: i18n.t('routes.dashboard.label.calibration'),
    type: i18n.t('routes.dashboard.type'),
  },
  quizzes: {
    label: i18n.t('routes.coaching.label.quizzes'),
    type: i18n.t('routes.coaching.type'),
  },
  'settings.connections': {
    label: i18n.t('routes.settings.label.connections'),
    keywords: [i18n.t('routes.settings.keyword.integrations'), i18n.t('routes.settings.keyword.helpdesk')],
    breadcrumbs: [i18n.t('routes.settings.breadcrumb.account')],
    type: i18n.t('routes.settings.type'),
  },
  'settings.notifications': {
    label: i18n.t('routes.settings.label.notifications'),
    breadcrumbs: [i18n.t('routes.settings.breadcrumb.account')],
    type: i18n.t('routes.settings.type'),
  },
  'settings.profile': {
    label: i18n.t('routes.settings.label.general'),
    breadcrumbs: [i18n.t('routes.settings.breadcrumb.account')],
    keywords: [
      i18n.t('routes.settings.keyword.organization'),
      i18n.t('routes.settings.keyword.account'),
      i18n.t('routes.settings.keyword.company'),
    ],
    type: i18n.t('routes.settings.type'),
  },
  'settings.subscription': {
    label: i18n.t('routes.settings.label.subscription'),
    keywords: [
      i18n.t('routes.settings.keyword.payment'),
      i18n.t('routes.settings.keyword.seats'),
      i18n.t('routes.settings.keyword.billing'),
    ],
    breadcrumbs: [i18n.t('routes.settings.breadcrumb.account')],
    type: i18n.t('routes.settings.type'),
  },
  'settings.spotlight': {
    label: i18n.t('routes.settings.label.spotlight'),
    breadcrumbs: [i18n.t('routes.settings.breadcrumb.account')],
    keywords: [i18n.t('routes.settings.keyword.settings')],
    type: i18n.t('routes.settings.type'),
  },
  'settings.auto_qa.create': {
    label: i18n.t('routes.settings.label.new_category'),
    breadcrumbs: [i18n.t('routes.settings.breadcrumb.account'), i18n.t('routes.settings.breadcrumb.auto_qa')],
    keywords: [i18n.t('routes.settings.keyword.settings')],
    type: i18n.t('routes.settings.type'),
  },
  'settings.auto_qa': {
    label: i18n.t('routes.settings.label.auto_qa'),
    breadcrumbs: [i18n.t('routes.settings.breadcrumb.account')],
    keywords: [i18n.t('routes.settings.keyword.settings')],
    type: i18n.t('routes.settings.type'),
  },
  'settings.survey': {
    label: i18n.t('routes.settings.label.surveys'),
    type: i18n.t('routes.settings.type'),
  },
  'settings.authentications': {
    label: i18n.t('routes.settings.label.authentications'),
    breadcrumbs: [i18n.t('routes.settings.breadcrumb.account')],
    type: i18n.t('routes.settings.type'),
  },
  'settings.scorecard': {
    label: i18n.t('routes.settings.label.scorecard'),
    breadcrumbs: [i18n.t('routes.settings.breadcrumb.account')],
    type: i18n.t('routes.settings.type'),
  },
  'profile.general': {
    label: i18n.t('routes.profile_settings.label.general'),
    keywords: [
      i18n.t('routes.profile_settings.keyword.email'),
      i18n.t('routes.profile_settings.keyword.e_mail'),
      i18n.t('routes.profile_settings.keyword.user'),
    ],
    breadcrumbs: [i18n.t('routes.profile_settings.breadcrumb.personal')],
    type: i18n.t('routes.profile_settings.type'),
  },
  'profile.templates': {
    label: i18n.t('routes.profile_settings.label.templates'),
    keywords: [i18n.t('routes.profile_settings.keyword.templates')],
    breadcrumbs: [i18n.t('routes.profile_settings.breadcrumb.personal')],
    type: i18n.t('routes.profile_settings.type'),
  },
  'profile.templates.create': {
    label: i18n.t('routes.profile_settings.label.create_template'),
    keywords: [i18n.t('routes.profile_settings.keyword.templates')],
    breadcrumbs: [
      i18n.t('routes.profile_settings.breadcrumb.personal'),
      i18n.t('routes.profile_settings.breadcrumb.templates'),
    ],
    type: i18n.t('routes.profile_settings.type'),
  },
  'profile.goal': {
    label: i18n.t('routes.profile_settings.label.goal'),
    keywords: [i18n.t('routes.profile_settings.keyword.review_goal')],
    breadcrumbs: [i18n.t('routes.profile_settings.breadcrumb.personal')],
    type: i18n.t('routes.profile_settings.type'),
  },
  'profile.logins': {
    label: i18n.t('routes.profile_settings.label.login'),
    keywords: [
      i18n.t('routes.profile_settings.keyword.google'),
      i18n.t('routes.profile_settings.keyword.slack'),
      i18n.t('routes.profile_settings.keyword.email'),
      i18n.t('routes.profile_settings.keyword.reset'),
      i18n.t('routes.profile_settings.keyword.create_password'),
      i18n.t('routes.profile_settings.keyword.login'),
      i18n.t('routes.profile_settings.keyword.credentials'),
    ],
    breadcrumbs: [i18n.t('routes.profile_settings.breadcrumb.personal')],
    type: i18n.t('routes.profile_settings.type'),
  },
  'profile.notifications': {
    label: i18n.t('routes.profile_settings.label.notifications'),
    breadcrumbs: [i18n.t('routes.profile_settings.breadcrumb.personal')],
    type: i18n.t('routes.profile_settings.type'),
  },
}
