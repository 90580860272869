import { getLocale } from '@/i18n'

const mapDisplayNameLocales = {
  'zh-cn': 'zh-Hans',
  'zh-tw': 'zh-Hant',
  'en-001': 'en-GB',
} as Record<string, string>

export const getLanguageDisplayName = ({
  locale,
  translateTo = getLocale(),
  options = { type: 'language', languageDisplay: 'standard' },
}: {
  locale: string
  translateTo?: string
  options?: Intl.DisplayNamesOptions
}) => {
  if (!locale) return ''
  const translateToLocale = mapDisplayNameLocales[translateTo] || translateTo
  const localeToTranslate = mapDisplayNameLocales[locale] || locale

  return new Intl.DisplayNames([translateToLocale], options).of(localeToTranslate) || localeToTranslate
}
