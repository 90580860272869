import type { FieldContext } from 'vee-validate'

type InputEvent = 'input' | 'change'
type InteractionEventGetter = (ctx: Pick<FieldContext, 'errorMessage'>) => InputEvent[]

export enum InteractionMode {
  Passive = 'passive',
  Lazy = 'lazy',
  Aggressive = 'aggressive',
  Eager = 'eager',
}

// Validates on submit only
const passive = () => []

const lazy: InteractionEventGetter = () => ['change']

const aggressive: InteractionEventGetter = () => ['input']

const eager: InteractionEventGetter = ({ errorMessage }) => (errorMessage.value ? ['input'] : ['change'])

export const modes: Record<InteractionMode, InteractionEventGetter> = {
  [InteractionMode.Passive]: passive,
  [InteractionMode.Lazy]: lazy,
  [InteractionMode.Aggressive]: aggressive,
  [InteractionMode.Eager]: eager,
}
