import analytics from '@/utils/analytics'

import { createChargebeePortalSession } from '../api'

export const loadChargebeeSDK = (site: string) => {
  const createInstance = () =>
    window.Chargebee.inited ? window.Chargebee.getInstance() : window.Chargebee.init({ site })

  return new Promise<any>((resolve, reject) => {
    if (window.Chargebee) return resolve(createInstance())

    const cb = document.createElement('script')
    cb.setAttribute('src', 'https://js.chargebee.com/v2/chargebee.js')
    document.head.appendChild(cb)
    cb.onload = () => resolve(createInstance())
    cb.onerror = reject
  })
}

interface PortalParams {
  site: string
  subscriptionId: string
  closeCb: () => void
  section?: 'cancellation' | 'subscription' | 'payment'
}

export const openChargebeePortal = async ({ site, subscriptionId, closeCb, section }: PortalParams) => {
  const chargebee = await loadChargebeeSDK(site)
  chargebee.setPortalSession(createChargebeePortalSession)

  const type = window.Chargebee.getPortalSections()

  const params = { subscriptionId }
  let config
  if (section === 'cancellation') config = { sectionType: type.SUBSCRIPTION_CANCELLATION, params }
  if (section === 'subscription') config = { sectionType: type.SUBSCRIPTION_DETAILS, params }
  if (section === 'payment') config = { sectionType: type.ADD_PAYMENT_SOURCE, params }

  const close = () => {
    closeCb()
    chargebee.closeAll()
  }

  const callbacks = {
    close: analytics.paymentManagePortalClosed,
    subscriptionReactivated: close,
    subscriptionResumed: close,
    subscriptionCancelled: close,
    scheduledCancellationRemoved: close,
    paymentSourceAdd: close,
    paymentSourceUpdate: close,
  }

  const portal = chargebee.createChargebeePortal()
  if (section === 'subscription') return portal.openSection(config, callbacks)
  portal.open(callbacks, config)
}
