export type PasswordStrengthCondition = 'lowercase' | 'uppercase' | 'numeric' | 'length'

export const calculatePasswordStrength = (password: string) => {
  const p = (rgx: RegExp) => rgx.test(password)
  return {
    lowercase: p(/[a-zà-öø-ÿ]/),
    uppercase: p(/[A-ZÀ-ÖØ-Ý]/),
    numeric: p(/\d/),
    length: password.length >= 8,
  }
}

export const isPasswordStrong = (password: string) => {
  const strength = calculatePasswordStrength(password)
  return Object.values(strength).every(Boolean)
}
