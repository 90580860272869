import { computed } from 'vue'
import { createSharedComposable } from '@vueuse/core'
import { useQuery, useMutation, useQueryClient, keepPreviousData } from '@tanstack/vue-query'
import useReviewSidebar from '@/composables/useReviewSidebar'
import useTicketRouteParams from '@/composables/useTicketRouteParams'
import { getSessionLocale } from '@/i18n'

import {
  getTranscriptionsForTicket,
  updateTranscriptionSpeaker,
  type Speaker,
  type Transcription,
  type TranscriptionsList,
} from '../api/transcripts'
import { getTranscriptionSpeakerId } from '../utils/transcription-speaker'
import { useTicketContent } from './useTicketContent'

interface UpdateSpeakerPayload {
  speaker: Speaker
  transcriptionId: string
}

const selectedLanguage = getSessionLocale()

export default createSharedComposable(() => {
  const queryClient = useQueryClient()
  const { ticketExternalId, state: ticketContentState } = useTicketContent()
  const { transcriptionId, transcriptionMessageId } = useTicketRouteParams()
  const { state: sidebarState } = useReviewSidebar()

  const connectionId = computed(() =>
    ticketContentState.ticket.paymentTokenId ? String(ticketContentState.ticket.paymentTokenId) : undefined,
  )
  const queryEnabled = computed(() => !!ticketExternalId.value && !!connectionId.value)
  const participatingAgentIds = computed(() => getAgentIds(transcripts?.value ?? []))
  const selectedSpeakerId = computed(() =>
    findSpeakerByTranscriptIds(transcriptionId.value, transcriptionMessageId.value, transcripts.value),
  )

  const translationLanguage = computed(() =>
    sidebarState.metadata.translatedConversation ? selectedLanguage : undefined,
  )
  const params = computed(() => ({
    connectionId: connectionId.value,
    ticketId: ticketExternalId.value,
    translationLanguage: translationLanguage.value,
  }))
  const queryKey = computed(() => ['ticket-call-transcripts', ...Object.values(params.value)])

  const { data: transcripts, isFetching } = useQuery({
    queryKey: ['ticket-call-transcripts', connectionId, ticketExternalId, translationLanguage],
    queryFn: ({ signal }) => getTranscriptionsForTicket(params.value, signal),
    select: (response) => response?.transcriptions,
    placeholderData: keepPreviousData,
    enabled: queryEnabled,
  })

  const { mutate: updateSpeaker } = useMutation({
    mutationFn: ({ speaker, transcriptionId }: UpdateSpeakerPayload) =>
      updateTranscriptionSpeaker({
        transcriptionId,
        connectionId: connectionId.value,
        aliasOriginal: speaker.aliasOriginal,
        internalUserId: speaker.internalId,
      }),
    onMutate: (payload: UpdateSpeakerPayload) => {
      const previousTranscripts = queryClient.getQueryData<TranscriptionsList>(queryKey.value)

      queryClient.setQueryData(queryKey.value, () => ({
        transcriptions: getOptimisticSpeakerUpdate(payload, transcripts.value),
      }))

      return previousTranscripts
    },
    onSettled: (responseData, error, _variables, originalData) => {
      const transcriptionsList = error ? originalData : responseData
      queryClient.setQueryData(queryKey.value, () => transcriptionsList)
    },
  })

  return {
    transcripts,
    isFetching: computed(() => isFetching.value),
    participatingAgentIds,
    updateSpeaker,
    selectedSpeakerId,
  }
})

function getAgentIds(transcripts: Transcription[]) {
  const agentIdentifiers = transcripts.flatMap((transcript) => {
    return transcript.messages
      ?.filter((message) => message.speaker?.alias === 'agent')
      ?.map((message) =>
        getTranscriptionSpeakerId({ transcriptionId: transcript.transcriptionId, speaker: message.speaker }),
      )
  })

  return Array.from(new Set(agentIdentifiers))
}

const getOptimisticSpeakerUpdate = (
  { speaker, transcriptionId }: UpdateSpeakerPayload,
  transcripts: Transcription[],
) => {
  if (!transcripts) return []

  const targetId = transcripts.findIndex((t) => t.transcriptionId === transcriptionId)
  const targetTranscript = { ...transcripts[targetId] }
  const messages = targetTranscript?.messages.map((msg) =>
    msg.speaker.aliasOriginal === speaker.aliasOriginal ? { ...msg, speaker } : msg,
  )

  const updatedTranscripts = [...transcripts]
  updatedTranscripts[targetId] = { ...targetTranscript, messages }
  return updatedTranscripts
}

const findSpeakerByTranscriptIds = (
  transcriptionId: string,
  transcriptionMessageId: string,
  transcripts: Transcription[],
) => {
  if (!transcriptionId || !transcriptionMessageId || !transcripts) return undefined

  return transcripts
    .find((t) => t.transcriptionId === transcriptionId)
    ?.messages.find((m) => m.transcriptionMessageId === transcriptionMessageId)?.speaker.internalId
}
