const lineColors = [
  '#3432BF',
  '#2F9997',
  '#078CB9',
  '#805AD5',
  '#413EEF',
  '#38B2AC',
  '#08A8DE',
  '#9F7AEA',
  '#7A78F4',
  '#51CFC4',
  '#3BC8F8',
  '#B794F4',
  '#B3B2F9',
  '#8FE5DA',
  '#90CDF4',
  '#D6BCFA',
]

export const getLineColor = (idx: number) => lineColors[idx % lineColors.length]
