import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_form_field = _resolveComponent("ui-form-field")!
  const _component_q_radio = _resolveComponent("q-radio")!
  const _component_ui_pill_toggle = _resolveComponent("ui-pill-toggle")!
  const _component_view_settings_wrapper = _resolveComponent("view-settings-wrapper")!

  return (_openBlock(), _createBlock(_component_view_settings_wrapper, null, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.section)
      }, [
        _createVNode(_component_ui_form_field, {
          label: _ctx.$t('dashboard.view_settings.date_type')
        }, null, 8 /* PROPS */, ["label"]),
        _createVNode(_component_q_radio, {
          "model-value": _ctx.modelValue.ticketDates,
          options: [
          { label: _ctx.$t('dashboard.view_settings.date_type_review'), value: false },
          { label: _ctx.$t('dashboard.view_settings.date_type_ticket'), value: true },
        ],
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', { ..._ctx.modelValue, ticketDates: $event })))
        }, null, 8 /* PROPS */, ["model-value", "options"])
      ], 2 /* CLASS */),
      (_ctx.canHideCards)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(_ctx.$style.section)
          }, [
            _createVNode(_component_ui_form_field, {
              label: _ctx.$t('dashboard.view_settings.cards')
            }, null, 8 /* PROPS */, ["label"]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cards, (card) => {
              return (_openBlock(), _createBlock(_component_ui_pill_toggle, {
                key: card.id,
                class: _normalizeClass(_ctx.$style.toggle),
                "model-value": _ctx.viewSettings.cardsVisible[card.id],
                label: card.label,
                small: "",
                "onUpdate:modelValue": ($event: any) => (_ctx.handleSettingsUpdate(card.id, $event))
              }, null, 8 /* PROPS */, ["class", "model-value", "label", "onUpdate:modelValue"]))
            }), 128 /* KEYED_FRAGMENT */))
          ], 2 /* CLASS */))
        : _createCommentVNode("v-if", true),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.section)
      }, [
        _createVNode(_component_ui_form_field, {
          label: _ctx.$t('dashboard.view_settings.other')
        }, null, 8 /* PROPS */, ["label"]),
        _createVNode(_component_ui_pill_toggle, {
          class: _normalizeClass(_ctx.$style.toggle),
          "model-value": _ctx.modelValue.decimals === 2,
          label: _ctx.$t('dashboard.view_settings.show_decimal_places'),
          small: "",
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:modelValue', { ..._ctx.modelValue, decimals: _ctx.modelValue.decimals === 0 ? 2 : 0 })))
        }, null, 8 /* PROPS */, ["class", "model-value", "label"])
      ], 2 /* CLASS */)
    ]),
    _: 1 /* STABLE */
  }))
}