import { computed, watch, reactive, type ComputedRef } from 'vue'
import { createSharedComposable } from '@vueuse/core'
import { orderBy, uniqBy } from 'lodash-es'

import type {
  GetSurveysResponse_Type as TicketSurvey,
  GetSurveysResponse_Response as TicketSurveyResponse,
} from '@zendesk/zqa-services/tickets'
import type { Bot } from '@zendesk/zqa-services/users'
import type { AutoQaReviewForConversationResponse } from '@zendesk/zqa-services/autoqa'
import i18n from '@/i18n'
import { session } from '@/composables/useSession'
import { updateUserSetting } from '@/api/user-settings'
import { getCategories } from '@/modules/workspace/api/categories'
import type { Scorecard } from '@/modules/shared/TicketContent/types/scorecard'
import type { User as ManagementUser } from '@/modules/user-management/types'
import { isDynamicScorecard } from '@/modules/shared/TicketContent/utils/scorecard'
import { type Category, type CategoryGroup, type Rating, isRated } from '@/modules/shared/Review/utils'
import type { CommentTag, Feedback } from '@/modules/shared/TicketContent/types/feedback'
import { getLoadingRevieweeOption } from '@/modules/shared/TicketContent/utils/defaultReviewee'
import {
  getFeedback,
  getScorecards,
  getInternalCommentTags,
  getAutoQaConversationReviews,
} from '@/modules/shared/TicketContent/api'
import { getCalibrationFeedback } from '@/modules/shared/TicketContent/api/calibration'
import type { TicketData, ExternalCommentExt } from '@/modules/conversations/types'
import useDispute from '@/modules/shared/TicketContent/composables/useDispute'
import useTicketRouteParams from '@/composables/useTicketRouteParams'
import { hasAccountRole, hasWorkspaceRole } from '@/utils/roleUtils'
import timeTracking from '@/modules/time-tracking/timeTracking'
import type { StopParams } from '@/modules/time-tracking/types'
import { DisplayType } from '@/data/scales'
import { searchUsers } from '@/modules/dashboard/api'
import { getTicketSurveys } from '@/modules/shared/TicketContent/components/csat/api'
import isBot from '@/modules/shared/Review/utils/isBot'

export interface User {
  id?: number
  name: string
  avatar?: string
  email?: string
  selfDisabled?: boolean
}

export interface FeedbackState {
  user: User | Bot
  ratings: Record<number, Rating>
  comment: string
  commentTags?: CommentTag[]
  scorecardId?: string | null
}

export interface MessagePreview {
  label: string
  type: 'message' | 'call' | 'attachment'
}

export interface Survey extends TicketSurveyResponse {
  surveyName: TicketSurvey['name']
  averageScore: TicketSurvey['averageScore']
}

export interface PusherUser extends ManagementUser {
  isSpy: 'true' | 'false'
}

export interface State {
  isFooterCollapsed?: boolean
  scorecards: Scorecard[]
  selectedScorecard?: Scorecard
  selectedUser: User | Bot
  settings: {
    unbiasedGrading: boolean
    threshold: number
    selfReviews: boolean
    internalTagsLocked: boolean
  }
  // Sent to BE for tracking via the POST /reviews endpoint
  metadata: {
    sort?: string
    reviewedViaVisualFilters: boolean
    translatedConversation?: boolean
  }
  categories: Category[]
  workspaceUsers: User[]
  workspaceUsersLoading: boolean
  feedbackList: Feedback[]
  feedbackScore: number
  autoQaFeedbackScore: number
  internalTags: string[]
  loadingFeedback: boolean
  defaultReviewee?: number | string
  assignmentRevieweeIds?: number[]
  replacingConversation: boolean
  removingConversation: boolean
  ticket?: TicketData
  ticketContentNotFound: boolean
  autoQaReviews?: AutoQaReviewForConversationResponse
  surveys?: Survey[]
  usersReviewing: PusherUser[]
  usersSpying: PusherUser[]
}

const SCORECARD_STORAGE_KEY = 'klausSelectedScorecard.v2'

export const defaultState: State = {
  isFooterCollapsed: true,
  scorecards: [],
  selectedScorecard: undefined,
  selectedUser: getLoadingRevieweeOption(),
  settings: {
    unbiasedGrading: false,
    threshold: 50,
    selfReviews: false,
    internalTagsLocked: true,
  },
  metadata: {
    sort: undefined,
    reviewedViaVisualFilters: false,
    translatedConversation: false,
  },
  categories: [],
  workspaceUsers: [],
  workspaceUsersLoading: false,
  feedbackList: [],
  feedbackScore: undefined,
  autoQaFeedbackScore: undefined,
  internalTags: [],
  loadingFeedback: false,
  defaultReviewee: undefined,
  assignmentRevieweeIds: [],
  replacingConversation: false,
  removingConversation: false,
  ticket: undefined,
  ticketContentNotFound: false,
  autoQaReviews: undefined,
  surveys: [],
  usersReviewing: [],
  usersSpying: [],
}

export default createSharedComposable(() => {
  const { state: disputeState, resetDispute, loadDisputes, disputeTitle } = useDispute()
  const { connectionId, conversationId, transcriptionMessageId, transcriptionId, messageId, calibrationSessionId } =
    useTicketRouteParams()

  const generateScorecardStorageKey = (workspaceId: number) => `${workspaceId}.${SCORECARD_STORAGE_KEY}`

  const state = reactive(defaultState)
  const sectionStates = reactive({
    reviews: false,
    insights: false,
    surveys: false,
    helpdesk: false,
  })

  const allowSkip = computed(() => session.account.ratingCategory.skippable)
  const displayAsEmoji = computed(() => session.account.ratingCategory.displayType === DisplayType.Emotes)

  const validReviews = computed(() =>
    state.feedbackList.filter(
      (feedback) => feedback.ratings.length && !feedback.deleted && feedback.ratings.some(isRated),
    ),
  )

  // Scorecards
  function setScorecard(selectedScorecard: Scorecard) {
    state.selectedScorecard = selectedScorecard
  }

  const _setDefaultScorecard = (workspaceId: number) => {
    const key = generateScorecardStorageKey(workspaceId)
    const persisted: string | undefined = localStorage[key]
    const defaultScorecardId = persisted || state.selectedScorecard?.id

    if (defaultScorecardId) {
      const scorecard = state.scorecards.find((sc) => sc.id === defaultScorecardId)

      // Outdated scorecard persisted, remove it
      if (!scorecard) localStorage.removeItem(key)
      setScorecard(scorecard || state.scorecards[0])
    } else {
      setScorecard(state.scorecards[0])
    }
  }

  const activeScorecardCategories = computed(() => state.selectedScorecard?.ratingCategoryIds || [])

  const emptyDynamicScorecard = computed(() => {
    const scorecard = state.selectedScorecard
    return isDynamicScorecard(scorecard) && !scorecard?.ratingCategoryIds?.length
  })

  const categoryOptions = computed<Record<number, Category>>(() =>
    state.categories.reduce((mappedCategories, cat) => {
      mappedCategories[cat.categoryId] = cat
      return mappedCategories
    }, {}),
  )

  const categoryGroups = computed(() => {
    return uniqBy(Object.values(categoryOptions.value), 'groupId')
      .map((cat) => ({
        id: cat.groupId,
        name: cat.groupName,
        position: cat.groupPosition,
      }))
      .filter((g): g is CategoryGroup => !!g.id)
      .sort((a, b) => a.position - b.position)
  })

  /**
   * Feedback can get filtered
   * 1. for a specific message
   * 2. that only belongs to the logged in user (agent)
   * 3. if in a unbiased grading workspace
   * 4. when calibrating reviews
   * otherwise all feedback for the conversation is displayed
   */
  const isFeedbackFiltered = computed(() => {
    const userHasPostedFeedback = state.feedbackList.some(
      (feedback) => !feedback.deleted && feedback.reviewerId === session.user.id.toString(),
    )
    return state.settings.unbiasedGrading && !userHasPostedFeedback
  })

  const visibleManualFeedback = computed(() => {
    const { feedbackList } = state

    if (isFeedbackFiltered.value) {
      const userId = session.user.id.toString()
      return feedbackList.filter(
        (f) =>
          [f.revieweeId, f.reviewerId, ...f.tags.map((tag) => tag.userId)].includes(userId) &&
          (!messageId.value || messageId.value === f.messageId),
      )
    }

    if (transcriptionMessageId.value) {
      return feedbackList.filter((f) => f.transcriptionMessageId === transcriptionMessageId.value)
    } else if (messageId.value) {
      return feedbackList.filter((f) => f.messageId === messageId.value)
    }

    return feedbackList
  })

  const visibleAutoQaFeedback = computed(() => {
    if (calibrationSessionId.value) return []
    if (isFeedbackFiltered.value || transcriptionMessageId.value || messageId.value) return []

    return orderBy(
      Object.values(state.autoQaReviews?.reviewPerUser ?? {})
        .map((r) => r.feedback)
        .filter((f) => !!f),
      'revieweeName',
    )
  })

  const ticketReviews = computed(() => state?.feedbackList?.filter(_feedbackIsExistingReview) ?? [])
  const reviewsByLoggedInUser = computed(() => ticketReviews.value.filter(_feedbackReviewerIsLoggedInUser))

  /**
   * Users who have received reviews (not deleted feedback that has ratings) from the logged in user.
   * Recipients should be deduced from the reviews of the currently selected target (e.g. a single message),
   * or all reviews if no message is selected.
   */
  const reviewRecipients = computed(() =>
    visibleManualFeedback.value
      .filter(_feedbackIsExistingReview)
      .filter(_feedbackReviewerIsLoggedInUser)
      .filter(_feedbackTargetsSelectedInteraction)
      .map((r) => r.revieweeId),
  )

  function _feedbackIsExistingReview(feedback: Feedback) {
    return !feedback.deleted && !!feedback.ratings?.length
  }

  function _feedbackReviewerIsLoggedInUser(feedback: Feedback) {
    return feedback.reviewerId === session.user.id.toString()
  }

  function _feedbackTargetsSelectedInteraction(feedback: Feedback) {
    if (transcriptionMessageId.value) {
      return feedback.transcriptionMessageId === transcriptionMessageId.value
    }

    if (transcriptionId.value) {
      return !feedback.transcriptionMessageId && feedback.transcriptionId === transcriptionId.value
    }

    if (messageId.value) {
      return feedback.messageId === messageId.value
    }

    return !feedback.messageId
  }

  const reviewSectionTitle = computed(() => {
    if (disputeState.disputing) return disputeTitle.value
    if (state.removingConversation) return i18n.t('tasks.remove.title')
    if (state.replacingConversation) return i18n.t('tasks.replace.title')

    const user = state.selectedUser
    if (
      (!isBot(user) && user.selfDisabled) ||
      (user.id && reviewRecipients.value.includes(String(user.id))) ||
      (hasWorkspaceRole('AGENT') && user.id !== session.user.id)
    ) {
      return i18n.t('conversations.sidebar.review_title_comment')
    }

    if (messageId.value) return i18n.t('conversations.sidebar.review_title_message')

    return i18n.t('conversations.sidebar.review_title_ticket')
  })

  const reviewTarget: ComputedRef<TicketData | ExternalCommentExt | undefined> = computed(() => {
    if (!messageId.value) return state.ticket

    const message = state.ticket?.externalComments?.find((c) => c.externalId === messageId.value)
    return message || state.ticket
  })

  // Load conversation-specific or dynamic data
  watch(() => [conversationId.value, connectionId.value, calibrationSessionId.value], _loadState)

  // Persist sectionStates to user settings
  watch(sectionStates, () => {
    updateUserSetting('reviewSidebar', {
      ...session.user.settings.reviewSidebar,
      sections: sectionStates,
    })
  })

  watch(() => state.feedbackList, updateScore, { deep: true })
  watch(visibleAutoQaFeedback, updateAutoQaFeedbackScore, { deep: true })

  // Load data once that does not change often
  function setup() {
    _resetState()
    if (!state.workspaceUsers.length) initWorkspaceUserList()

    const settings = session.workspace.settings
    state.settings.internalTagsLocked = settings.tagsLocked
    state.settings.threshold = settings.threshold
    state.settings.unbiasedGrading =
      hasAccountRole('USER') && hasWorkspaceRole('REVIEWER', 'AGENT') && settings.calibrated
    state.settings.selfReviews = settings.selfReviews
    state.defaultReviewee = undefined

    _loadRatingCategories()
    loadInternalTags()
    _loadState()

    if (session.user.settings.reviewSidebar && 'sections' in session.user.settings.reviewSidebar) {
      Object.assign(sectionStates, session.user.settings.reviewSidebar.sections)
    }
  }

  function _resetState() {
    state.scorecards = []
    state.feedbackList = []
    state.autoQaReviews = undefined
    state.surveys = []
    disputeState.disputesList = []
  }

  function _loadState() {
    _resetState()
    loadScorecards()
    loadFeedback()
    _loadTicketSurveys()
    _loadAutoQa()
    loadInternalTags()

    if (session.features.disputes) {
      resetDispute()
      if (connectionId.value && conversationId.value) loadDisputes(connectionId.value, conversationId.value)
    }
  }

  function resetMetadata() {
    state.metadata.sort = undefined
    state.metadata.reviewedViaVisualFilters = false
    state.metadata.translatedConversation = false
  }

  function resetUsersList() {
    state.workspaceUsers = []
  }

  async function loadScorecards() {
    if (!conversationId.value || !connectionId.value) return

    const { scorecards } = await getScorecards(conversationId.value, connectionId.value)
    const mapped = scorecards.map((card) => ({
      ...card,
      ratingCategoryIds: (card.ratingCategoryIds as unknown[] as string[]).map(parseFloat),
    }))
    state.scorecards = mapped

    if (mapped[0] && (!state.selectedScorecard || isDynamicScorecard)) {
      _setDefaultScorecard(session.workspace.id)
    }
  }

  async function loadInternalTags() {
    const { data } = await getInternalCommentTags()
    state.internalTags = data
  }

  async function _loadRatingCategories() {
    const { data } = await getCategories(session.workspace.id)

    state.categories = data.map((cat) => ({
      categoryId: cat.id,
      categoryName: cat.name,
      categoryDescription: cat.description,
      groupId: cat.groupId,
      groupName: cat.groupName,
      groupPosition: cat.groupPosition,
      scale: cat.scale,
      requireReason: cat.requireReason,
      multipleRequireReasons: cat.multipleRequireReasons,
      requireReasonVisibility: cat.requireReasonVisibility,
      freeTextAllowed: cat.freeTextAllowed,
      weight: cat.weighting,
      critical: cat.failCategory,
      position: cat.position,
      isArchived: cat.isArchived,
      scorecards: cat.scorecards,
      rootCauses: cat.rootCauses,
      autoQaCategory: cat.autoQaCategory,
      autoQaCustomCategoryTemplateId: cat.autoQaCustomCategoryTemplateId,
    }))
  }

  async function loadFeedback(language?: string) {
    if (!connectionId.value || !conversationId.value) return

    state.loadingFeedback = true

    const settingEnabled = session.workspace.settings.calibrationEnabled
    const isCalibrationEnabled = session.features.calibrationPro && settingEnabled

    let conversationFeedback: Feedback[] = []

    if (calibrationSessionId.value && isCalibrationEnabled) {
      const { feedback } = await getCalibrationFeedback(
        conversationId.value,
        connectionId.value,
        calibrationSessionId.value,
      )
      conversationFeedback = feedback
    } else {
      const { feedback } = await getFeedback({
        connectionId: connectionId.value,
        conversationId: conversationId.value,
        language,
      })
      conversationFeedback = feedback
    }

    state.feedbackList = conversationFeedback
    state.loadingFeedback = false

    return conversationFeedback
  }

  function updateScore() {
    const scores = validReviews.value.map((feedback) => feedback.score)
    const averageScore = scores.reduce((sum, score) => sum + score, 0) / scores.length
    state.feedbackScore = averageScore
  }

  function updateAutoQaFeedbackScore() {
    state.autoQaFeedbackScore = visibleAutoQaFeedback.value.length
      ? visibleAutoQaFeedback.value.reduce((sum, f) => sum + f.score, 0) / visibleAutoQaFeedback.value.length
      : 0
  }

  async function _loadTicketSurveys() {
    if (!connectionId.value || !conversationId.value) return

    const surveys = await getTicketSurveys(conversationId.value, connectionId.value)

    state.surveys = surveys.types.reduce((result, survey) => {
      if (survey.responses.length) {
        survey.responses.forEach((response) => {
          result.push({
            surveyName: survey.name,
            ...response,
          })
        })
      }

      return result
    }, [])

    return surveys
  }

  async function _loadAutoQa() {
    if (!session.features.autoQa || !connectionId.value || !conversationId.value) return

    state.autoQaReviews = await getAutoQaConversationReviews({
      connectionId: connectionId.value.toString(),
      conversationId: conversationId.value,
    })
  }

  async function stopAndSetReviewTime(params: StopParams) {
    const id = params.reviewId ? String(params.reviewId) : params.calibrationReviewId
    const review = state.feedbackList.find((f) => f.id === id)
    if (!review) return

    if (!params.calibrationReviewId) review.reviewTime = 'loading'

    const { reviewTime } = await timeTracking.stop(params)
    if (reviewTime && !params.calibrationReviewId) review.reviewTime = reviewTime
  }

  async function initWorkspaceUserList() {
    state.workspaceUsersLoading = true
    const { users } = await searchUsers({ workspaceIds: [session.workspace.id], limit: 20000 })
    state.workspaceUsers = users
    state.workspaceUsersLoading = false
  }

  return {
    generateScorecardStorageKey,
    state,
    sectionStates,
    allowSkip,
    displayAsEmoji,
    validReviews,
    setScorecard,
    activeScorecardCategories,
    emptyDynamicScorecard,
    categoryOptions,
    categoryGroups,
    isFeedbackFiltered,
    visibleManualFeedback,
    visibleAutoQaFeedback,
    ticketReviews,
    reviewsByLoggedInUser,
    reviewRecipients,
    reviewSectionTitle,
    reviewTarget,
    setup,
    loadInternalTags,
    loadScorecards,
    loadFeedback,
    resetMetadata,
    resetUsersList,
    stopAndSetReviewTime,
  }
})
