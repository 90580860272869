import analytics from '@/utils/analytics'
import { cloneFilter as cloneFilterApi } from '../../api/filters'
import { expandPrivateFilters, getFilters, setActiveFilterId } from '../../composables/useConversationFilters'

export default async (filter) => {
  const newId = await cloneFilterApi(filter.filterId).then(({ data }) => data)
  analytics.filterCloned(filter.filterId, filter.name, filter.isPublic)
  expandPrivateFilters(true)
  setActiveFilterId(newId)
  await getFilters()
  const el = document.querySelector("[data-id='private-filters']")
  el?.scrollIntoView({ behavior: 'smooth' })
}
