import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_data_grid_column = _resolveComponent("data-grid-column")!
  const _component_data_grid_section = _resolveComponent("data-grid-section")!
  const _component_percentage_cell = _resolveComponent("percentage-cell")!
  const _component_number_cell = _resolveComponent("number-cell")!
  const _component_data_grid = _resolveComponent("data-grid")!
  const _component_data_card = _resolveComponent("data-card")!

  return (_openBlock(), _createBlock(_component_data_card, {
    id: "dashboard-workspaces-card",
    "api-method": _ctx.getWorkspacesOverview,
    "csv-api-method": _ctx.getWorkspacesOverviewCsv,
    title: _ctx.$t('dashboard.card_labels.workspaces'),
    params: _ctx.filterParams,
    class: "m-6"
  }, {
    default: _withCtx(({ data, sortBy, setPage }) => [
      _createVNode(_component_data_grid, {
        data: data,
        "sort-by": sortBy,
        "inline-pagination": "",
        "set-page": setPage,
        "hide-total": ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_data_grid_section, null, {
            default: _withCtx(() => [
              _createVNode(_component_data_grid_column, {
                id: "workspace",
                "identity-column": "",
                label: _ctx.$t('dashboard.workspace_name'),
                width: "200px",
                align: "left"
              }, {
                default: _withCtx(({ value: { id, usersCount } }) => [
                  _createVNode(_component_router_link, {
                    class: _normalizeClass(_ctx.$style.workspaceNameContainer),
                    to: { name: 'dashboard', query: _ctx.getQuery(id) }
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", {
                        class: _normalizeClass(_ctx.$style.colorBubble),
                        style: _normalizeStyle({ backgroundColor: _ctx.getWorkspaceById(id).color })
                      }, null, 6 /* CLASS, STYLE */),
                      _createElementVNode("span", {
                        class: _normalizeClass(_ctx.$style.workspaceName),
                        title: _ctx.getWorkspaceById(id).name
                      }, _toDisplayString(_ctx.getWorkspaceById(id).name) + " (" + _toDisplayString(usersCount) + ") ", 11 /* TEXT, CLASS, PROPS */, _hoisted_1)
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["class", "to"])
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["label"])
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_data_grid_section, {
            class: _normalizeClass([_ctx.$style.dataSection]),
            style: _normalizeStyle({ '--columns': `repeat(${_ctx.columnCount}, 1fr)` })
          }, {
            default: _withCtx(() => [
              (!_ctx.specialPassRateEnabled)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createVNode(_component_data_grid_column, {
                      id: "score",
                      label: _ctx.$t('universal.iqs')
                    }, {
                      default: _withCtx(({ value }) => [
                        _createVNode(_component_percentage_cell, { value: value }, null, 8 /* PROPS */, ["value"])
                      ]),
                      _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["label"]),
                    _createVNode(_component_data_grid_column, {
                      id: "change",
                      label: _ctx.changeLabel
                    }, {
                      default: _withCtx(({ value }) => [
                        _createVNode(_component_percentage_cell, {
                          "no-background": "",
                          "show-sign": "",
                          colorful: "",
                          value: value
                        }, null, 8 /* PROPS */, ["value"])
                      ]),
                      _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["label"])
                  ], 64 /* STABLE_FRAGMENT */))
                : _createCommentVNode("v-if", true),
              (_ctx.passRateEnabled || _ctx.specialPassRateEnabled)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _createVNode(_component_data_grid_column, {
                      id: "passRate",
                      label: _ctx.$t('universal.pass_rate')
                    }, {
                      default: _withCtx(({ value }) => [
                        _createVNode(_component_percentage_cell, { value: value }, null, 8 /* PROPS */, ["value"])
                      ]),
                      _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["label"]),
                    _createVNode(_component_data_grid_column, {
                      id: "passRateChange",
                      label: _ctx.changeLabel
                    }, {
                      default: _withCtx(({ value }) => [
                        _createVNode(_component_percentage_cell, {
                          "no-background": "",
                          colorful: "",
                          "show-sign": "",
                          value: value
                        }, null, 8 /* PROPS */, ["value"])
                      ]),
                      _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["label"])
                  ], 64 /* STABLE_FRAGMENT */))
                : _createCommentVNode("v-if", true),
              (_ctx.specialPassRateEnabled)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                    _createVNode(_component_data_grid_column, {
                      id: "criticalErrorFreeRate",
                      label: _ctx.$t('dashboard.card_labels.critical_error_free')
                    }, {
                      default: _withCtx(({ value }) => [
                        _createVNode(_component_percentage_cell, { value: value }, null, 8 /* PROPS */, ["value"])
                      ]),
                      _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["label"]),
                    _createVNode(_component_data_grid_column, {
                      id: "criticalErrorFreeRateChange",
                      label: _ctx.changeLabel
                    }, {
                      default: _withCtx(({ value }) => [
                        _createVNode(_component_percentage_cell, {
                          "no-background": "",
                          colorful: "",
                          "show-sign": "",
                          value: value
                        }, null, 8 /* PROPS */, ["value"])
                      ]),
                      _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["label"])
                  ], 64 /* STABLE_FRAGMENT */))
                : _createCommentVNode("v-if", true),
              _createVNode(_component_data_grid_column, {
                id: "conversations",
                label: _ctx.$t('dashboard.conversations')
              }, {
                default: _withCtx(({ value }) => [
                  _createVNode(_component_number_cell, { value: value }, null, 8 /* PROPS */, ["value"])
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["label"]),
              _createVNode(_component_data_grid_column, {
                id: "comments",
                label: _ctx.$t('dashboard.comments')
              }, {
                default: _withCtx(({ value }) => [
                  _createVNode(_component_number_cell, { value: value }, null, 8 /* PROPS */, ["value"])
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["label"]),
              (_ctx.timeTrackingEnabled && _ctx.filterParams.direction === 'given')
                ? (_openBlock(), _createBlock(_component_data_grid_column, {
                    key: 3,
                    id: "reviewTime",
                    label: _ctx.$t('dashboard.average_review_time'),
                    "not-sortable": ""
                  }, {
                    default: _withCtx(({ value }) => [
                      _createVNode(_component_number_cell, {
                        value: !value ? _ctx.$t('dashboard.average_review_time_value_na') : _ctx.formatDuration(Number(value)),
                        "no-format": "",
                        color: !value ? 'gray' : undefined
                      }, null, 8 /* PROPS */, ["value", "color"])
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["label"]))
                : _createCommentVNode("v-if", true)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["class", "style"])
        ]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["data", "sort-by", "set-page"])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["api-method", "csv-api-method", "title", "params"]))
}