import { isRated, hasRootCauses, type Rating, type Category } from '@/modules/shared/Review/utils'
import type { State as DisputeState } from '@/modules/shared/TicketContent/composables/useDispute'
import type { DisputeCategory } from '@/modules/shared/TicketContent/types/dispute'
import type { Rating as FeedbackRating } from '@/modules/shared/TicketContent/types/feedback'

const getInitialRating = ({
  category,
  disputedCategory,
  originalRating,
}: {
  category: Category
  disputedCategory?: DisputeCategory
  originalRating?: FeedbackRating
}): Rating => {
  const rating = disputedCategory?.expectedRating ?? originalRating?.rating
  const cause = disputedCategory?.rootCause || originalRating?.cause

  return { ...category, rating, cause }
}

const getDisputedRatingsMap = ({
  disputedCategories,
  originalCategories,
}: {
  disputedCategories: DisputeCategory[]
  originalCategories: Category[]
}): Record<number, Rating> => {
  return disputedCategories.reduce((acc, disputedCategory) => {
    const category = originalCategories.find(({ categoryId }) => categoryId.toString() === disputedCategory.id)

    if (!category) return acc

    const rating = getInitialRating({ category, disputedCategory })
    return { ...acc, [category.categoryId]: rating }
  }, {})
}

const getOriginalAndDisputedRatingsMap = ({
  disputedCategories,
  originalCategories,
  originalRatings,
}: {
  disputedCategories: DisputeCategory[]
  originalCategories: Category[]
  originalRatings: FeedbackRating[] | undefined
}): Record<number, Rating> => {
  return originalCategories.reduce((acc, category) => {
    const categoryId = category.categoryId.toString()
    const disputedCategory = disputedCategories.find((c) => c.id === categoryId)
    const originalRating = originalRatings?.find((r) => r.categoryId === categoryId)
    const rating = getInitialRating({ category, disputedCategory, originalRating })

    return { ...acc, [categoryId]: rating }
  }, {})
}

export const getDisputeInitialRatingsMap = ({
  disputeState,
  originalCategories,
}: {
  disputeState: DisputeState
  originalCategories: Category[]
}): Record<number, Rating> => {
  const { dispute, action } = disputeState
  const disputedCategories = dispute?.categories || []

  if (action === 'APPROVE' && !!disputedCategories.length) {
    return getDisputedRatingsMap({ disputedCategories, originalCategories })
  }

  const creatingOrEditingDispute = !['APPROVE', 'REJECT'].includes(action ?? '')
  const approvingWithoutRatings = action === 'APPROVE' && !disputedCategories.length
  const includeOriginalRatings = creatingOrEditingDispute || approvingWithoutRatings
  const originalRatings: any = includeOriginalRatings ? dispute?.originalReview.ratings : []

  return getOriginalAndDisputedRatingsMap({
    disputedCategories,
    originalCategories,
    originalRatings,
  })
}

export const getDisputeChangedRatings = ({
  originalRatings,
  disputeFormRatings,
}: {
  originalRatings: FeedbackRating[]
  disputeFormRatings: Rating[]
}): Rating[] => {
  return disputeFormRatings.filter(({ categoryId, cause, rating }) => {
    const originalRating = originalRatings.find((c) => c.categoryId.toString() === categoryId.toString())

    return (
      (originalRating?.rating !== rating && (isRated({ rating }) || isRated(originalRating))) ||
      (originalRating?.cause?.join() !== cause?.join() &&
        (hasRootCauses(cause) || hasRootCauses(originalRating?.cause)))
    )
  })
}

export const hasSomeCategoriesRated = ({
  categoriesToCheck,
  originalRatings,
  updatedRatings,
}: {
  categoriesToCheck: Category[]
  originalRatings: Record<number, FeedbackRating>
  updatedRatings: Record<number, Rating>
}) => {
  const validateCategoryRating = ({ categoryId }: Category) => {
    const originalRating = originalRatings[categoryId]
    const updatedRating = updatedRatings[categoryId]
    return updatedRating ? isRated(updatedRating) : isRated(originalRating)
  }

  return categoriesToCheck.some(validateCategoryRating)
}

export const toRatingsMap = (ratings: FeedbackRating[] = []): Record<number, FeedbackRating> => {
  return ratings.reduce((acc, rating) => ({ ...acc, [rating.categoryId]: rating }), {})
}
