import { ref } from 'vue'
import { createSharedComposable } from '@vueuse/core'
import type { WorkspaceUser } from '../types'

export default createSharedComposable(() => {
  const users = ref<WorkspaceUser[]>([])
  const userFilterParam = ref('')
  const showAddMembers = ref(false)

  return {
    users,
    userFilterParam,
    showAddMembers,
  }
})
