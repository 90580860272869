import i18n from '@/i18n'
import autoInsightsFilters from '@/components/filters/autoInsightsFilters'
import { session } from '@/composables/useSession'
import { type DeconstructedOption, dateConditions } from '../../utils/filters'

// In addition to AutoQa categories
export const hasIntelligenceIcon = [
  ...autoInsightsFilters,
  'AGENT_MOST_PUBLIC_MESSAGES',
  'AGENT_PUBLIC_MESSAGE_COUNT',
  'END_USER_CHANNEL',
  'END_USER_MESSAGE_COUNT',
  'CONVERSATION_SENTIMENT',
  'TICKET_SENTIMENT',
  'TICKET_ESCALATION',
  'SPOTLIGHT',
  'TRASH',
  'LANGUAGE',
  'PRIVATE_MESSAGE_COUNT',
  'PUBLIC_CHARACTER_COUNT',
  'PUBLIC_WORD_COUNT',
  'PUBLIC_MESSAGE_COUNT',
  'UNIQUE_AGENT_COUNT',
  'IS_COMPLEX',
  'CUSTOM_CATEGORY_CUSTOM_PHRASE_SEARCH',
  'SPOTLIGHT_CUSTOM_PHRASE_SEARCH',
]

export const hasBotIcon = ['BOTS', 'BOT_TYPE', 'BOT_REPLY_COUNT']

export type GroupFilter = (option: DeconstructedOption['option']) => boolean

export const dateGroupFilter = (option: DeconstructedOption['option']) => dateConditions.includes(option.name)
export const helpdeskGroupFilter = (option: DeconstructedOption['option']) => option.isCustom

const definedConversationSubGroupFilters = [
  {
    name: i18n.t('filter.groups.categories'),
    filterFn: (option: DeconstructedOption['option']) => option.isAutoQa || option.name === 'RATING_CATEGORY',
  },
  {
    name: i18n.t('filter.groups.custom_categories'),
    filterFn: (option: DeconstructedOption['option']) => option.isCustomCategory,
  },
  {
    name: i18n.t('filter.groups.information'),
    filterFn: (option: DeconstructedOption['option']) =>
      [
        'COMMENT_BODY',
        'COMMENT_CHANNEL',
        'END_USER_CHANNEL',
        'HAS_TRANSCRIPTION',
        'TRANSCRIPTION_CONTENT',
        'HIGHLIGHT_STATUS',
        'LANGUAGE',
        'TICKET_BRAND',
        'TICKET_CHANNEL',
        'TICKET_DESCRIPTION',
        'TICKET_FOLDER',
        'TICKET_FORM',
        'TICKET_GROUP',
        'TICKET_MAILBOX',
        'TICKET_PRIORITY',
        'TICKET_STATE',
        'TICKET_STATUS',
        'TICKET_SUBJECT',
        'TICKET_TAGS',
        'TICKET_TYPE',
        'TICKET_VIA',
        'VIEWED_STATUS',
      ].includes(option.name),
  },
  {
    name: i18n.t('filter.groups.metrics'),
    filterFn: (option: DeconstructedOption['option']) =>
      [
        'AGENT_MOST_PUBLIC_MESSAGES',
        'AGENT_PUBLIC_MESSAGE_COUNT',
        'CALL_DURATION',
        'END_USER_MESSAGE_COUNT',
        'PRIVATE_MESSAGE_COUNT',
        'PUBLIC_CHARACTER_COUNT',
        'PUBLIC_MESSAGE_COUNT',
        'PUBLIC_WORD_COUNT',
        'SATISFACTION_SCORE',
        'SATISFACTION_SCORE_PER_MESSAGE',
        'TICKET_REPLY_COUNT',
        'UNIQUE_AGENT_COUNT',
        ...(session.features.botQa ? ['BOT_REPLY_COUNT'] : []),
      ].includes(option.name),
  },
  {
    name: i18n.t('filter.groups.participants'),
    filterFn: (option: DeconstructedOption['option']) =>
      [
        'ASSIGNEE',
        'FROM_TYPE',
        'PUBLIC_PARTICIPANT',
        'RELATED',
        'RELATED_NAME_EMAIL',
        'REVIEWED_BY',
        'REVIEWEE',
        'TICKET_EXTERNAL_ID',
        'TICKET_NUMBER',
        ...(session.features.botQa ? ['BOTS', 'BOT_TYPE'] : []),
      ].includes(option.name),
  },
  {
    name: i18n.t('filter.groups.review_and_feedback'),
    filterFn: (option: DeconstructedOption['option']) =>
      [
        'COMMENT_INTERNAL_TAG',
        'COMMENT_STATUS',
        'DISPUTE_STATUS',
        'INTERNAL_COMMENT_INTERNAL_TAG',
        'RATING_STATUS',
        'REVIEW_VIEWED',
        'SATISFACTION_COMMENT',
        'SCORECARD',
        'SOURCE_TYPE',
        'SURVEY_TITLE',
        'SURVEY_QUESTION',
        'TICKET_INTERNAL_TAG',
      ].includes(option.name),
  },
  {
    name: i18n.t('filter.groups.spotlight'),
    filterFn: (option: DeconstructedOption['option']) =>
      [...autoInsightsFilters, 'CONVERSATION_SENTIMENT', 'SPOTLIGHT', 'TRASH'].includes(option.name),
  },
  {
    name: i18n.t('filter.groups.custom_spotlight'),
    filterFn: (option: DeconstructedOption['option']) => option.isSpotlight,
  },
]

export const conversationSubGroupFilters = [...definedConversationSubGroupFilters]

export const conversationGroupFilter = (option: DeconstructedOption['option']) =>
  conversationSubGroupFilters.some(({ filterFn }) => filterFn(option))
