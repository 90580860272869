export interface QuizPayload {
  name: string
  description: string
  status: string
  fields: QuizPayloadField[]
  workspaceIds: string[]
}

interface QuizPayloadField {
  text: string
  images: string[]
  type: string
  position: string
  options: QuizPayloadOption[]
}

interface QuizPayloadOption {
  label: string
  correct: boolean
  position: string
}

export interface Quiz {
  id: string
  name: string
  description: string
  authorId?: string
  authorName?: string
  authorAvatar?: string
  published?: string
  publishedDate: string
  status: string
  fields?: Field[]
  createdBy?: string
  workspaceIds: string[]
  userResponseId?: string | null
  averageScorePercentage?: string | null
  numberOfParticipants?: string | null
}

export interface Field {
  id: string
  text: string
  type: string
  position: string
  images: string[]
  unsavedImages: File[]
  options: Option[]
  properties: FieldProperty[]
}

export interface FieldProperty {
  key: string
  value: string
}
export interface Option {
  id: string
  label: string
  correct: boolean
  position: string
  answered?: boolean
  type?: string
}

export interface Participant {
  id: string
  name: string
  avatar: string
  averageScorePercentage: number
  quizzesTaken: number
  ranking: number
}

export interface QuestionErrors {
  empty_title: boolean
  no_options: boolean
  no_correct_option: boolean
}

export interface Errors {
  no_quiz_title: boolean
  questions: Record<string, QuestionErrors>
}

export interface QuizResponseOverview {
  id: string
  name: string
  description: string
  status: string
  responseCount: string
  fields: QuizResponseOverviewField[]
  averageScorePercentage: string
  workspaceIds: string[]
}

export interface QuizResponseOverviewField {
  id: string
  text: string
  images: string[]
  type: string
  position: string
  options: {
    id: string
    label: string
    correct: boolean
    position: string
    selectedCount: string
    percentageSelected: string
  }[]
}

export interface QuizRespondentResponse {
  quizId: string
  responseId: string
  name: string
  description: string
  userId: string
  userName: string
  userAvatar: string
  workspaceIds: string[]
  fields: {
    id: string
    text: string
    images: string[]
    type: 'MULTIPLE_CHOICE'
    position: string
    options: QuizFormOption[]
    answeredCorrectly: true
    properties: FieldProperty[]
  }[]
}

export interface Respondent {
  label: string
  name: string
  responseId: string
  score: string
}

export interface AgentQuiz {
  id: string
  name: string
  status: string
  authorId: string
  authorName: string
  authorAvatar: string
  completedDate: string
  userResponseId: string
  numberOfParticipants: string
  averageScorePercentage: string
  userScorePercentage: string
  ranking: string
}
export interface QuizRespondents {
  responses: [
    {
      responseId: string
      userId: string
      userName: string
      correctAnswerPercentage: string
    },
  ]
}

export interface QuizForm {
  id: string
  name: string
  description: string
  status: string
  publishedDate: string
  responseId?: string
  fields: {
    id: string
    text: string
    images: string[]
    type: string
    position: string
    options: QuizFormOption[]
    properties: FieldProperty[]
  }[]
}

export interface QuizFormOption {
  id: string
  label: string
  position: string
  correct?: boolean
  selected?: boolean
}

export interface QuizFormPayload {
  responses: {
    fieldId: string
    selectedOptionIds: string[]
  }[]
}

export enum TABS {
  ACTIVE = 'PUBLISHED',
  DRAFT = 'DRAFT',
  ARCHIVED = 'ARCHIVED',
}
