import { computed, type ComputedRef } from 'vue'
import { useQuery } from '@tanstack/vue-query'
import type { CsatFilterParams } from '@/modules/dashboard/csat/types'
import i18n from '@/i18n'
import { getCsatPredictedTags } from '@/modules/dashboard/csat/api'
import { translationExists as te } from '@/i18n/utils'

const PREDICTED_TRANSLATIONS_KEY_BASE = 'csat_dashboard.filter_bar.predicted_tags'

export function useCsatPredictedTags(filterParams: ComputedRef<CsatFilterParams>) {
  const workspaceIds = computed(() => filterParams.value.workspaceIds)

  const { data: predictedTagsData, isPending: isLoading } = useQuery({
    queryKey: ['predicted-tags', workspaceIds],
    queryFn: ({ signal }) => getCsatPredictedTags(filterParams.value, signal),
    select: (data) => ({
      tags: data.values.filter(translationExists).map(toDropdownOption),
      total: Number(data.total),
    }),
  })

  return { predictedTagsData, isLoading }
}

function translationExists(key: string) {
  return te(`${PREDICTED_TRANSLATIONS_KEY_BASE}.${key}`)
}

function toDropdownOption(key: string) {
  return {
    id: key,
    name: i18n.t(`${PREDICTED_TRANSLATIONS_KEY_BASE}.${key}`),
  }
}
